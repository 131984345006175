import React, { Component } from 'react';
//import { Badge } from './Badge';
import {
	Row, Col, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown,
	Modal, ModalHeader, ModalBody, ModalFooter, Input, Button
} from 'reactstrap';
import $ from "jquery";
import { FormateDate } from "../_helpers"
import { GetBearerToken } from "../_helpers"
import { UserLink } from './UserLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

export default class PostHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pinned: this.props.pinned,
			commentsEnabled: this.props.commentsEnabled,
			reportPostOpen: false
		};
		
		this.RemovePost = this.RemovePost.bind(this);
		this.CopyLink = this.CopyLink.bind(this);
		this.TogglePinState = this.TogglePinState.bind(this);
		this.ToggleCommentsEnabled = this.ToggleCommentsEnabled.bind(this);

		this.ToggleReportModal = this.ToggleReportModal.bind(this);
		this.ReportPost = this.ReportPost.bind(this);

		this.EventReminder = this.EventReminder.bind(this);
	}

	componentDidMount() {
		
	}

	async componentDidUpdate(prevProps) {
		if (this.props.pinned != prevProps.pinned) {

			this.render();
		}
	} 

	async RemovePost(postId) {

		if (postId === null)
			return;


		const response = await fetch("/api/v1/Post/" + postId,
			{
				method: 'delete',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (!(await response.ok))
			console.log(response);

		//this.populateNewsfeed();
		this.props.updateNewsfeedCallback(true);
	}

	CopyLink(postId) {
		//let baseUrl = "https://localhost:5001"; //old
		//let baseUrl = "http://gsinqconnect.ca-central-1.elasticbeanstalk.com"
		let baseUrl = "https://gsinqconnect.com"
		let postUrl = baseUrl + "/post/" + postId;
		copy(postUrl);
		//alert(postUrl);
	}

	async TogglePinState() {

		var obj = {
			'postId': this.props.postId,
			'pinState': !this.state.pinned
		};

		await fetch("api/v1/Post",
			{
				method: 'OPTIONS',
				body: JSON.stringify(obj),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		this.setState({
			pinned: !this.state.pinned
		});
	}

	async ToggleCommentsEnabled() {
		var temp = !this.state.commentsEnabled;
		await fetch(`api/v1/Post/${this.props.postId}/Comments/${temp}`,
			{
				method: 'OPTIONS',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		this.setState({
			commentsEnabled: temp
		});
		this.props.updatePostCallback(temp);
	}

	ToggleReportModal() {
		this.setState({
			reportPostOpen: !this.state.reportPostOpen
		});
	}

	async ReportPost() {
		var body = {
			"postId": this.props.postId,
			'comment': $('#reportPostComment__' + this.props.postId).val()
		}

		const response = await fetch("/api/v1/Report",
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			console.log(await response.text);

			this.ToggleReportModal();

		} else {
			console.log(response);

		}

	}

	async EventReminder() {
		const response = await fetch("/api/v1/Event/" + this.props.postId,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			console.log(await response.text);

		} else {
			console.log(response);

		}
	}

	render() {
		const dateTime = this.props.dateTime;
		const user = this.props.user;
		const postId = this.props.postId;
		//const image = toSvg(user.fullName, 70).toString();
		//var tempId = user.id + "_" + dateTime;
		//tempId = tempId.replace(/([-:@/\\\\])/g, "_");

		var usersPost = null
		var editPost = null

		if (this.props.userViewing != null &&
			user.userId == this.props.userViewing.userId) {
			usersPost = <DropdownItem onClick={() => { if (window.confirm('Are you sure you wish to delete this Post?')) this.RemovePost(postId) }} >
							Delete
						</DropdownItem>
			editPost = <DropdownItem onClick={() => { this.props.EditPost(); }} >
							Edit Post
						</DropdownItem>
		}

		var MenuOption = <DropdownMenu>
						<DropdownItem onClick={() => { this.CopyLink(postId) }} style={{ cursor: 'copy' }}>
							Copy Link <FontAwesomeIcon icon="link" color="black" size="xs" />
						</DropdownItem>
						<DropdownItem onClick={this.ToggleReportModal}>
							Report Post
						</DropdownItem>
						{usersPost}
						{editPost}
					</DropdownMenu>;


		if (this.props.userViewing != null &&
			(this.props.userViewing.roles.includes("MODERATOR") || this.props.userViewing.roles.includes("ADMIN"))) {

			var EventEdit = null

			if (this.props.event) {
				EventEdit = <div>
					<DropdownItem onClick={() => { this.props.EditEvent(); }} >
						Edit Event
					</DropdownItem>
					<DropdownItem onClick={this.EventReminder} >
						Send Reminder
					</DropdownItem>
				</div>
			}

			MenuOption = <DropdownMenu>
						<DropdownItem onClick={() => { this.CopyLink(postId) }} style={{ cursor: 'copy' }}>
							Copy Link <FontAwesomeIcon icon="link" color="black" size="xs" />
						</DropdownItem>
						<DropdownItem onClick={this.ToggleReportModal}>
							Report Post
						</DropdownItem>
						{editPost}
						<DropdownItem divider />
						{EventEdit}
						<DropdownItem onClick={() => { if (window.confirm('Are you sure you wish to delete this Post?')) this.RemovePost(postId) }} >
							Delete
						</DropdownItem>
						<DropdownItem onClick={this.TogglePinState}>
							{this.state.pinned ? "Unpin" : "Pin"}
						</DropdownItem>
						<DropdownItem onClick={this.ToggleCommentsEnabled}>
							{this.state.commentsEnabled ? "Disable comments" : "Enable comments"}
						</DropdownItem>
					</DropdownMenu>;
		}

		//console.log(user.badges);
		return (
			<div className="row">
				<div className="col-auto my-auto" id={user.id}>
					<svg id={user.id} width="55" height="55" data-jdenticon-value={user.firstName + " " + user.lastName}></svg>
				</div>
				<div className="col">
					<div className="row">
						<div className="col-auto pr-1 pl-0">
							<h4 className="mt-2 mb-0" ><UserLink history={this.props.history} id={user.userId} name={user.firstName + " " + user.lastName} /></h4>
						</div>
						<div className="col ml-0 mt-auto pl-0">

						</div>

					</div>
					<div className="row mt-0">
						<span className="text-secondary">{FormateDate(dateTime)}</span>
					</div>
				</div>
				<Col xs="auto" className="ml-auto mr-0">
					<Row className="mt-3 ">
						{this.state.pinned &&
							<FontAwesomeIcon icon="thumbtack" color="black" className="my-auto mr-2  text-secondary" />
						}
						<UncontrolledDropdown>
							<DropdownToggle color="white">
								<FontAwesomeIcon icon="ellipsis-v" color="black" className="text-secondary" />
							</DropdownToggle>
							{MenuOption}
						</UncontrolledDropdown>
					</Row>
				</Col>
				<Modal isOpen={this.state.reportPostOpen} toggle={this.ToggleReportModal} className="">
					<ModalHeader toggle={this.ToggleReportModal}>Report Post</ModalHeader>
					<ModalBody>
						<p>		Please provide a reason for reporting this post and it will be reviewed by an Administrator. </p>
						<Input type="textarea" id={"reportPostComment__" + postId} />
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.ToggleReportModal}>Cancel</Button>
						<Button color="primary" onClick={this.ReportPost}>Report Post</Button>
					</ModalFooter>
				</Modal>

			</div>
		);
	}
};

/*
{user.badges === null &&
								{user.badges.map((badge, key) =>
									<Badge icon={badge} id={tempId} key={key} />
								)}
							}
 */