import React, { Component } from 'react';
import {
	Col, Button, Navbar, Container, NavbarBrand, Input, Row, FormGroup,
	Label, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import $ from "jquery";
import '../_components/NavMenu.css';
import gsi_logo from '../_images/gsi_logo_inverse.png';
import sha256 from 'js-sha256'

import { Link } from 'react-router-dom'


export class Register extends Component {
	static displayName = Register.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			user: null,
			message: null,
			modalAbout: false,
			modalRules: false,
			rules: null,
			about: null,
			disable: false,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.Register = this.Register.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);

		this.toggleAbout = this.toggleAbout.bind(this);
		this.toggleRules = this.toggleRules.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {
		//Loaded

		//Retrive Info from API based on key
		//console.log(this.props);
		//console.log(this.props.inviteId);
		var invitation = this.props.inviteId;

		const response = await fetch(`api/v1/invitation/` + invitation,
			{
				method: 'get',
				headers: {
					'Content-Type': 'application/json'
				}
			});


		if (await response.ok) {
			const data = await response.json();

			//console.log(data);
			$('Input[name=Email]').val(data.email);
			$('Input[name=FirstName]').val(data.firstName);
			$('Input[name=LastName]').val(data.lastName);
			
		}
		else {
			console.log(response);
			this.setState({
				message: "Invalid Invitation Key. Double check with GSI to get a new key or try again later.",
				disable: true
			})
			return;
		}



		//Retrive rules content
		const RulesResponse = await fetch("/api/v1/ContentPage/rules",
			{
				method: 'get'
			});

		const RulesData = await RulesResponse.json();

		const AboutResponse = await fetch("/api/v1/ContentPage/about",
			{
				method: 'get'
			});

		const AboutData = await AboutResponse.json();

		this.setState({
			rules: RulesData.content,
			about: AboutData.content
		});
	}


	handleKeyPress(e) {
		if (e.key === "Enter") {
			this.Register();
		}
	}

	async Register(e = null) {
		//alert("Register");
		if (e !== null)
			e.preventDefault();

		if ($('Input[name=Password]').val() !== $('Input[name=ConfirmPassword]').val()) {
			this.setState({
				message: "Passwords do not match"
			});
			return;
		}

		$("#RegisterButton").prop('disabled', true);


		var RegesterObj = {
			Email: $('Input[name=Email]').val(),
			Password: sha256($('Input[name=Password]').val()),
			FirstName: $('Input[name=FirstName]').val(),
			LastName: $('Input[name=LastName]').val(),
			Phone: $('Input[name=Phone]').val(),
			Firm: $('Input[name=Firm]').val(),
			Province: $('#Province option:selected').val(),
			City: $('Input[name=City]').val(),
			Occupation: $('Input[name=Occupation]').val(),
			InvitationKey: this.props.inviteId
		}

		//Call server to Register
		const response = await fetch("/api/v1/user/register",
			{
				method: 'post',
				body: JSON.stringify(RegesterObj),
				headers: {
					'Content-Type': 'application/json'
				}
			});

		//console.log(await response.status);
		if (await response.ok) {
			//console.log(response.statusText);

			let user = await response.json();

			//console.log(user);


			//Store JWT in Cookie
			document.cookie = `currentUser=${JSON.stringify(user)}; path=/;`;

			//Set user in App
			this.props.updateLoginCallback(user, true)

			//Redirect to home
			this.props.history.push('/');

		}
		else {
			console.log(response.status);
			console.log(response.statusText);
			this.setState({
				message: "Invalid Invitation Key. Double check with GSI to get a new key or try again later.",
				modal: false
			});
			this.toggle();
			$("#RegisterButton").prop('disabled', false);
		}
	}


	toggleAbout(e = null) {
		if (e !== null)
			e.preventDefault();

		this.setState({
			modalAbout: !this.state.modalAbout
		});
	}

	toggleRules(e = null) {
		if (e !== null)
			e.preventDefault();

		this.setState({
			modalRules: !this.state.modalRules,
			modalAbout: false
		});
	}

	render() {
		let message = "";
		if (this.state.message != null) {
			message = <div className="alert alert-danger">{this.state.message}</div>
		}
		//console.log(this.state);
		return (
			<div>
				<Col xs={this.state.mobile ? "12" : (this.state.med ? "9" : "7")} className="mx-auto bg-white mt-5 mb-4 py-4 mx-2">
					<Row className="mb-3 px-2">
						<h1 className="mx-auto">
							Welcome to{' '}
							<span className="titleFont" ref={(el) => {
								if (el) {
									el.style.setProperty('color', 'Black', 'important');
									el.style.setProperty('font-size', '100%', 'important');
								}
							}}>
								G<span style={{ fontSize: "70%" }}>SINQ</span>Connect
							</span>
							!
						</h1>
					</Row>
					<form onSubmit={this.toggleAbout} >
						<Row>
							<Col>
								<FormGroup>
									<Label for="Email">Email*</Label>
									<Input type="email" name="Email" id="Email" placeholder="" required={true} disabled={this.state.disable}
										tabIndex="10" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="Phone">Phone Number</Label>
									<Input type="tel" name="Phone" id="Phone" placeholder="" disabled={this.state.disable}
										tabIndex="11" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
						</Row>
						<Row className="BorderBottom mb-2 pb-2">
							<Col>
								<FormGroup>
									<Label for="Password">Password*</Label>
									<Input type="password" name="Password" id="Password" placeholder="" required={true} disabled={this.state.disable}
										tabIndex="12" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="ConfirmPassword">Confirm Password*</Label>
									<Input type="password" name="ConfirmPassword" id="ConfirmPassword" placeholder="" disabled={this.state.disable}
										required={true} tabIndex="13" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label for="FirstName">First Name*</Label>
									<Input type="text" name="FirstName" id="FirstName" placeholder="" required={true} disabled={this.state.disable}
										tabIndex="20" onKeyPress={this.handleKeyPress} />
								</FormGroup>
								<FormGroup>
									<Label for="Occupation">Occupation*</Label>
									<Input type="text" name="Occupation" id="Occupation" placeholder="" required={true} disabled={this.state.disable}
										tabIndex="22" onKeyPress={this.handleKeyPress} />
								</FormGroup>
								<FormGroup>
									<Label for="City">City*</Label>
									<Input type="text" name="City" id="City" placeholder="" required={true} disabled={this.state.disable}
										tabIndex="24" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="LastName">Last Name*</Label>
									<Input type="text" name="LastName" id="LastName" placeholder="" required={true} disabled={this.state.disable}
										tabIndex="21" onKeyPress={this.handleKeyPress} />
								</FormGroup>
								<FormGroup>
									<Label for="Firm">Firm</Label>
									<Input type="text" name="Firm" id="Firm" placeholder="" tabIndex="23" disabled={this.state.disable}
										onKeyPress={this.handleKeyPress} />
								</FormGroup>
								<FormGroup>
									<Label for="Province">Select Province* </Label>
									<Input type="select" name="Province" id="Province" required={true} tabIndex="25" disabled={this.state.disable}
										onKeyPress={this.handleKeyPress} >
										<option selected value="">-- Select Province --</option>
										<option>Alberta</option>
										<option>British Columbia</option>
										<option>Manitoba</option>
										<option>New Brunswick</option>
										<option>Newfoundland and Labrador</option>
										<option>Northwest Territories</option>
										<option>Nova Scotia</option>
										<option>Nunavut</option>
										<option>Ontario</option>
										<option>Prince Edward Island</option>
										<option>Quebec</option>
										<option>Saskatchewan</option>
										<option>Yukon</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>

						<Button type="submit" className="my-2 bg-gsi mx-auto w-25" style={{ display: 'block' }} tabIndex="30" disabled={this.state.disable} >Register</Button>

						{message}

						<Modal isOpen={this.state.modalAbout} toggle={this.toggleAbout} className="modal-lg" style={{ marginTop: '10rem' }}>
							<ModalHeader toggle={this.toggleAbout}>About</ModalHeader>
							<ModalBody>
								<div className="rawHtml" dangerouslySetInnerHTML={{ __html: this.state.about }} />
							</ModalBody>
							<ModalFooter>
								<Button color="danger" onClick={this.toggleAbout}>Cancel</Button>{' '}
								<Button color="primary" onClick={this.toggleRules}>Continue</Button>
							</ModalFooter>
						</Modal>

						<Modal isOpen={this.state.modalRules} toggle={this.toggleRules} className="modal-lg" style={{ marginTop: '10rem' }}>
							<ModalHeader toggle={this.toggleRules}>Rules</ModalHeader>
							<ModalBody>
								<div className="rawHtml" dangerouslySetInnerHTML={{ __html: this.state.rules }} />
							</ModalBody>
							<ModalFooter>
								<Button color="danger" onClick={this.toggleRules}>Cancel</Button>{' '}
								<Button color="primary" id="RegisterButton" onClick={this.Register}>Accept</Button>
							</ModalFooter>
						</Modal>
					</form>

				</Col>
			</div>
		);
	}

}
