export function GetUser() {
	var currentUser = {};

	if (
		document.cookie.split(';').filter(item => {
			return item.includes('currentUser=');
		}).length === 1
	) {
		let cookieList = document.cookie.split(';');
		//console.log(cookieList);
		for (var i = 0; i < cookieList.length; i++) {
			if (cookieList[i].includes('currentUser=')) {
				//currentUserSubject = new BehaviorSubject(JSON.parse(cookieList[i + 1]));
				currentUser = JSON.parse(cookieList[i].substring("currentUser=".length));
			}
		}
	}

	return currentUser;
}

export function GetBearerToken(wBearer = true) {
	var currentUser = {};

	if (
		document.cookie.split(';').filter(item => {
			return item.includes('currentUser=');
		}).length === 1
	) {
		let cookieList = document.cookie.split(';');
		//console.log(cookieList);
		for (var i = 0; i < cookieList.length; i++) {
			if (cookieList[i].includes('currentUser=')) {
				//currentUserSubject = new BehaviorSubject(JSON.parse(cookieList[i + 1]));
				currentUser = JSON.parse(cookieList[i].substring("currentUser=".length));
			}
		}
	}

	if (currentUser === {})
		return "";

	if (!wBearer)
		return currentUser.token;
	
	//console.log("======");
	//console.log(currentUser);
	//console.log(currentUser.result.token);
	//console.log(currentUser.token);

	return 'Bearer ' + currentUser.token;
}

export function UpdateCookie(profile) {

	var currentUser = {};

	if (
		document.cookie.split(';').filter(item => {
			return item.includes('currentUser=');
		}).length === 1
	) {
		let cookieList = document.cookie.split(';');
		//console.log(cookieList);
		for (var i = 0; i < cookieList.length; i++) {
			if (cookieList[i].includes('currentUser=')) {
				//currentUserSubject = new BehaviorSubject(JSON.parse(cookieList[i + 1]));
				currentUser = JSON.parse(cookieList[i].substring("currentUser=".length));
			}
		}
	}

	if (currentUser === {})
		return;

	currentUser.firstName = profile.firstName;
	currentUser.lastName = profile.lastName;
	currentUser.occupation = profile.occupation;
	currentUser.firm = profile.firm;
	currentUser.province = profile.province;
	currentUser.email = profile.email;
	currentUser.phone = profile.phone;
	currentUser.yearStartedOccupation = profile.yearStartedOccupation;
	currentUser.school = profile.school;
	currentUser.description = profile.description;

	document.cookie = `currentUser=${JSON.stringify(currentUser)};`
}