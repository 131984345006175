import React, { Component } from 'react';
import { SidebarProfile, UserLink } from "../_components";
import { Col, Row } from 'reactstrap';
//import $ from "jquery";
//import { FormateDate } from "../_helpers"
//import { GetBearerToken } from "../_helpers"
import './Profile.css'

export class PublicProfileContent extends Component {
	static displayName = PublicProfileContent.name;

	constructor(props) {
		super(props);

	}



	render() {

		if (this.props.user == null)
			return <Col xs="3">
				<SidebarProfile user={this.props.userViewing} />
			</Col>

		var role = null

		if (this.props.user.roles.includes("MODERATOR") || this.props.user.roles.includes("ADMIN")) {
			role = <Row>
				<h6 className="text-secondary ml-3"><i>Administrator</i></h6>
			</Row>
		}
		return (
			<Col className="bg-white pt-2 mb-4 pb-3 w-100" xs={this.props.xs}>
				<Row className="BorderBottom">
					<Col>
						<h2 className="ml-4"><UserLink history={this.props.history} id={this.props.user.userId} name={this.props.user.firstName + " " + this.props.user.lastName} /></h2>
					</Col>
				</Row>
				<Row>
					<Col xs="3" className="mx-3 px-3">
						<Row>
							<svg id={this.props.user.id} width="150" height="150" data-jdenticon-value={this.props.user.firstName + " " + this.props.user.lastName}></svg>
						</Row>
						<Row>
							{role}
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<Row>
									<h5 className="mt-3 mb-0"><strong>School</strong></h5>
								</Row>
								<Row>
									{this.props.user.school && <span className="profileData">{this.props.user.school}</span>}
								</Row>
								<Row>
									{!this.props.user.school && <span className="profileData">No School Supplied</span>}
								</Row>
							</Col>
							<Col xs="8">
								<Row>
									<h5 className="mt-3 mb-0"><strong>In Industry Since</strong></h5>
								</Row>
								<Row>
									{this.props.user.inIndustrySince && <span className="profileData">{this.props.user.inIndustrySince}</span>}
								</Row>
								<Row>
									{!this.props.user.inIndustrySince && <span className="profileData">No Year Supplied</span>}
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<Row>
									<h5 className="mt-3 mb-0"><strong>Occupation</strong></h5>
								</Row>
								<Row>
									{this.props.user.occupation && <span className="profileData">{this.props.user.occupation}</span>}
								</Row>
								<Row>
									{!this.props.user.occupation && <span className="profileData">No Occupation Supplied</span>}
								</Row>
							</Col>
							<Col>
								<Row>
									<h5 className="mt-3 mb-0"><strong>Firm</strong></h5>
								</Row>
								<Row>
									{this.props.user.firm && <span className="profileData">{this.props.user.firm}</span>}
								</Row>
								<Row>
									{!this.props.user.firm && <span className="profileData">No Firm Supplied</span>}
								</Row>
							</Col>
							<Col>
								<Row>
									<h5 className="mt-3 mb-0"><strong>With Firm Since</strong></h5>
								</Row>
								<Row>
									{this.props.user.withFirmSince && <span className="profileData">{this.props.user.withFirmSince}</span>}
								</Row>
								<Row>
									{!this.props.user.withFirmSince && <span className="profileData">No Year Supplied</span>}
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<Row>
									<h5 className="mt-3 mb-0"><strong>City</strong></h5>
								</Row>
								<Row>
									{this.props.user.city && <span className="profileData">{this.props.user.city}</span>}
								</Row>
								<Row>
									{!this.props.user.city && <span className="profileData">No City Supplied</span>}
								</Row>
							</Col>
							<Col xs="8">
								<Row>
									<h5 className="mt-3 mb-0"><strong>Province</strong></h5>
								</Row>
								<Row>
									{this.props.user.province && <span className="profileData">{this.props.user.province}</span>}
								</Row>
								<Row>
									{!this.props.user.province && <span className="profileData">No Province Supplied</span>}
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<Row>
									<h5 className="mt-3 mb-0"><strong>Contact Information</strong></h5>
								</Row>
								<Row>
									Phone Number:<strong className="ml-3">{this.props.user.phone}</strong>
								</Row>
								<Row>
									Email:<strong className="ml-3">{this.props.user.email}</strong>
								</Row>
							</Col>
						</Row>
						{this.props.user.description &&
							<Row>
								<Col>
									<Row>
										<h5 className="mt-3 mb-0"><strong>Bio</strong></h5>
									</Row>
									<Row>
										{this.props.user.description}
									</Row>
								</Col>
							</Row>
						}
					</Col>
				</Row>
			</Col>
		);
	}

}