import React, { Component } from 'react';
import {
	Col, Button, Navbar, Container, NavbarBrand, Input, Row, FormGroup,
	Label, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import $ from "jquery";
import '../_components/NavMenu.css';
import gsi_logo from '../_images/gsi_logo_inverse.png';
import sha256 from 'js-sha256'

import { Link } from 'react-router-dom'


export class Reset extends Component {
	static displayName = Reset.name;

	constructor(props) {
		super(props);

		this.state = {
			user: null,
			message: null,
			modal: false,
			rules: null
		};

		this.Submit = this.Submit.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	async componentDidMount() {
		//Loaded

	}


	handleKeyPress(e) {
		if (e.key === "Enter") {
			this.Submit();
		}
	}

	async Submit(e = null) {
		//alert("Register");

		if ($('Input[name=Password]').val() !== $('Input[name=ConfirmPassword]').val()) {
			this.setState({
				message: "Passwords do not match"
			});
			return;
		}

		var RegesterObj = {
			text: sha256($('Input[name=Password]').val())
		}

		//Call server to Register
		const response = await fetch("/api/v1/user/reset/" + this.props.resetCode,
			{
				method: 'post',
				body: JSON.stringify(RegesterObj),
				headers: {
					'Content-Type': 'application/json'
				}
			});

		//console.log(await response.status);
		if (await response.ok) {
			//console.log(response.statusText);

			let user = await response.json();

			//console.log(user);


			//Store JWT in Cookie
			document.cookie = `currentUser=${JSON.stringify(user)}; path=/;`;

			//Set user in App
			this.props.updateLoginCallback(user, true)

			//Redirect to home
			this.props.history.push('/');

		}
		else {
			console.log(response.status);
			console.log(response.statusText);
			this.setState({
				message: "Invalid Reset Key. Please contact Granville Software Inc."
			});
			this.toggle();
		}
	}


	render() {
		let message = "";
		if (this.state.message != null) {
			message = <div className="alert alert-danger">{this.state.message}</div>
		}
		//console.log(this.state);
		return (
			<div>
				<Col xs="7" className="mx-auto bg-white mt-5 py-4 mx-2">
					<Row className="mb-3">
						<h4 className="mx-auto">
							Please enter your new password
						</h4>
					</Row>
					<form >
						<Row className="mb-2 pb-2">
							<Col>
								<FormGroup>
									<Label for="Password">Password*</Label>
									<Input type="password" name="Password" id="Password" placeholder="" required={true}
										tabIndex="12" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label for="ConfirmPassword">Confirm Password*</Label>
									<Input type="password" name="ConfirmPassword" id="ConfirmPassword" placeholder=""
										required={true} tabIndex="13" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Col>
						</Row>

						<Button type="button" className="my-2 bg-gsi mx-auto w-25" style={{ display: 'block' }} tabIndex="30" onClick={this.Submit} >Reset Password</Button>

						{message}
					</form>

				</Col>
			</div>
		);
	}

}
