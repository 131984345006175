import React, { Component } from 'react';
//import { Badge } from './Badge';
import { Row, Col, DropdownMenu, DropdownToggle, DropdownItem, Dropdown, UncontrolledDropdown, Button, ListGroupItem } from 'reactstrap';
import { FormateDate } from "../_helpers";
import { GetBearerToken } from "../_helpers";
import * as signalR from "@microsoft/signalr";
import './NotificationCenter.css'
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

export class NotificationCenter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: props.user,
			hubConnection: null,
			notifications: [],
			count: 0,
			open: false
		};

		//console.log(props.data);

		this.MarkAsRead = this.MarkAsRead.bind(this);
		this.toggle = this.toggle.bind(this);
		this.ClearAll = this.ClearAll.bind(this);
	}

	async componentDidMount() {
		const hubConnection = new signalR.HubConnectionBuilder()
			.withUrl("/notificationHub", {
				accessTokenFactory: () => GetBearerToken(false)
			})
			.withAutomaticReconnect()
			.build();

		await this.setState({
			hubConnection: hubConnection
		});
		

		this.state.hubConnection.on('ReceiveMessage', (data) => {
			//console.log("Got anything");
			//console.log(data);


			for (var i = 0; i < this.state.notifications.length; i++) {
				if (data.notification_id == this.state.notifications[i].notification_id)
					return;
			}

			var temp = [data];
			temp = temp.concat(this.state.notifications);

			var count = 0;
			for (var i = 0; i < temp.length; ++i) {
				if (!temp[i].read)
					count++;
			}

			this.setState({
				notifications: temp,
				count: count
			});
			//console.log(temp);
		});
		
		this.state.hubConnection
			.start()
			.then(() => console.log('Connection started!'))
			.catch(err => console.log('Error while establishing connection :('));
		

		//let connection = new signalR.HubConnectionBuilder()
		//	.withUrl("/notificationHub", {
		//		accessTokenFactory: () => GetBearerToken(false)
		//	})
		//	.build();


		//connection.on("receiveMessage", data => {
		//	console.log(data);
		//});

		//connection.start();
		//	.then(() => connection.invoke("send", "Hello"));
	}

	async MarkAsRead(notificationId, link = '/', redirect = true) {
		//this.state.hubConnection.invoke("MarkAsRead", notificationId)
		//	.catch(err => console.error(err));
		//console.log({ notificationId, link });

		const response = await fetch("/api/v1/Notification/" + notificationId,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			var notifications = this.state.notifications;
			var count = 0;

			for (var i = 0; i < notifications.length; i++) {

				if (notifications[i].notification_id === notificationId) {
					notifications[i].read = true;
				}

				if (!notifications[i].read)
					count++;
			}

			await this.setState({
				notifications: notifications,
				count: count,
				open: false
			});

			if (redirect)
				this.props.history.push(link);
		}

		//window.location.assign(link); 
	}

	ClearAll() {
		for (var i = 0; i < this.state.notifications.length; i++) {
			this.MarkAsRead(this.state.notifications[i].notification_id, '', false);
		}
	}

	toggle() {
		this.setState({
			open: !this.state.open
		});
	}

	render() {

		//console.log(this.state.notifications);
		//key={key} tag={Link} to={n.link} 

		if (this.props.mobile) {
			return (
				<Col style={{ cursor: 'pointer' }} tag={Link} to="/Notifications" onClick={this.toggle} className="px-0" >
					<FontAwesomeIcon icon="bell" color='black' className="mt-2" style={{
						position: "relative",
						display: "block",
						fontSize: "130%"
					}} />
					{this.state.count > 0 &&
						<span className="badge badge-pill badge-danger mt-2 mb-0" style={{
							position: "absolute",
							right: "-31px",
							top: "-17px"
						}}>{this.state.count + (this.state.count == 20 ? "+" : "")}</span>
					}
				</Col>
			)
		}

		return (
			<Dropdown isOpen={this.state.open} toggle={this.toggle} >
				<DropdownToggle color="white">
					<FontAwesomeIcon icon="bell" color="white" className="mt-0" style={{
						position: "relative",
						display: "block"
					}} />
					{this.state.count > 0 &&
						<span className="badge badge-pill badge-danger mt-2 mb-0" style={{
							position: "absolute",
							right: "-5px",
							top: "-10px"
						}}>{this.state.count + (this.state.count == 20? "+" : "")}</span>
					}
				</DropdownToggle>
				<DropdownMenu style={{
					width: "max-content",
					padding: "10px 20px",
					borderRadius: "5px",
					border: "1px solid grey"
				}}>
					<Row>
						<Col style={{ cursor: 'pointer' }} tag={Link} to="/Notifications" onClick={this.toggle} className="px-0" >
							<h4 className="ml-3 mt-1 pr-3" >Notification Center</h4>
						</Col>
						<Button outline color="secondary" className="ml-auto mr-0" onClick={this.ClearAll}>Mark all as read</Button>
					</Row>
					{this.state.notifications.map((n, key) =>
						<Row onClick={() => this.MarkAsRead(n.notification_id, n.link)}
							className={"notification my-2 " + (n.read === true ? "notification-read" : "notification-unread")}
							style={{
								borderRadius: "5px",
								cursor: 'pointer'
							}} key={key}
						>
							<Col>
								{n.text}
							</Col>
							<Col xs="auto">
								<span style={{
									fontSize: "70%",
									color: "grey"
								}}>
									{FormateDate(n.timeStamp)}
								</span>
							</Col>
						</Row>
					)}
					{this.state.notifications.length < 1 &&
						<p>No new notifications</p>
					}
				</DropdownMenu>
			</Dropdown>
		);
	}
};
//minWidth: "200px !important",