import React, { Component } from 'react';
import { SidebarProfile } from "../_components";
//import { Dropzone } from "../_components";
import { Col, Row, Button, FormGroup, Label, Input, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from "jquery";
//import { FormateDate } from "../_helpers"
import { GetBearerToken, GetUser } from "../_helpers"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

export class Profile extends Component {
	static displayName = Profile.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			error: false,
			user: null,
			profile: null,
			BioCount: null,
			BioClass: 'text-secondary',
			RequestRemovalOpen: false,
			Message: null,
			EmailMessage: false,
			med: size == "med",
			mobile: size == "mobile"
		};


		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleKeyPressTextArea = this.handleKeyPressTextArea.bind(this);
		this.Update = this.Update.bind(this);
		this.RetrieveProfile = this.RetrieveProfile.bind(this);
		this.RequestRemoval = this.RequestRemoval.bind(this);
		this.ToggleRequestRemoval = this.ToggleRequestRemoval.bind(this);
		this.populateTags = this.populateTags.bind(this);

		this.setAllNotif = this.setAllNotif.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile",
					wide: size == "wide"
				});
			}, 100);
		});
	}

	async componentDidMount() {

		await this.setState({
			user: GetUser()
		});

		await this.populateTags();

		if (this.state.user != null)
			this.RetrieveProfile();
	}

	handleKeyPress(e) {
		if (e.key === "Enter") {
			this.Update();
		}
	}

	handleKeyPressTextArea(e) {
		var self = this;
		setTimeout(function (e) {

			var text = " / 500";
			var count = $("#Bio").val().length;
			var bioClass = "text-secondary";

			if (count >= 500)
				bioClass = "text-danger";

			text = "(" + count + text + ")";

			console.log(text);

			this.setState({
				BioCount: text,
				BioClass: bioClass
			});

		}.bind(this), 0);
	}

	async RetrieveProfile() {
		//console.log("/api/v1/user/" + this.state.user.userId);
		if (this.state.user === null)
			return;

		const response = await fetch(`/api/v1/user/${this.state.user.userId}/edit`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			var profile = await response.json();
			//console.log(profile);

			this.setState({
				profile: profile
			});

			$("#Province").val(profile.province);
			$('Input[name=City]').val(profile.city);
			$('Input[name=FirstName]').val(profile.firstName);
			$('Input[name=LastName]').val(profile.lastName);
			$('Input[name=Occupation]').val(profile.occupation);
			$('Input[name=Firm]').val(profile.firm);
			$('Input[name=Email]').val(profile.email);
			$('#hideEmail').prop('checked', !profile.showEmail)
			$('Input[name=Phone]').val(profile.phone);
			$('Input[name=FirmSince]').val(profile.withFirmSince);
			$('Input[name=IndustrySince]').val(profile.inIndustrySince);
			$('Input[name=School]').val(profile.school);
			$('#Bio').val(profile.description);
			
			$(`#admin_notif > option[value=${profile.adminNotif}]`).attr('selected', 'selected');
			$(`#post_comment > option[value=${profile.notifPostReply}]`).attr('selected', 'selected');
			$(`#comment_reply > option[value=${profile.notifCommentReply}]`).attr('selected', 'selected');

			for (var tag in this.state.possibleTags) {
				let id = this.state.possibleTags[tag]['tag_Id']
				$(`#tag_${id} > option[value=${profile.notifTags[id] || "None"}]`).attr('selected', 'selected');
			}
		}
		else {
			console.log(response);
		}
	}

	async Update() {
		console.log("Update");

		if (this.state.user === null)
			return;

		if ($("#Bio").val().length > 500) {
			$("#Bio").focus();
			return;
		}

		if ($('Input[name=Email]').val() == "") {
			$('Input[name=Email]').focus();

			await this.setState({
				Message: "Invalid",
				EmailMessage: true
			});
			return;
		}

		var profile = {
			"userId": this.state.user.userId,
			"firstName": $('Input[name=FirstName]').val(),
			"lastName": $('Input[name=LastName]').val(),
			"occupation": $('Input[name=Occupation]').val(),
			"firm": $('Input[name=Firm]').val(),
			"province": $('#Province option:selected').val(),
			"city": $('Input[name=City]').val(),
			"email": $('Input[name=Email]').val(),
			"phone": $('Input[name=Phone]').val(),
			"withFirmSince": $('Input[name=FirmSince]').val(),
			"inIndustrySince": $('Input[name=IndustrySince]').val(),
			"school": $('Input[name=School]').val(),
			"description": $('#Bio').val(),
			"showEmail": !$('#hideEmail').prop('checked'),
			"adminNotif": $('#admin_notif option:selected').val(),
			"notifPostReply": $('#post_comment option:selected').val(),
			"notifCommentReply": $('#comment_reply option:selected').val(),
			"notifTags": {}
		}
		for (var tag in this.state.possibleTags) {
			let id = this.state.possibleTags[tag]['tag_Id']
			profile.notifTags[id] = $("#tag_" + id + " option:selected").val();
		}
		//console.log($('#Bio').val());
		console.log(profile);
		const response = await fetch("/api/v1/user/update",
			{
				method: 'POST',
				body: JSON.stringify(profile),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		console.log(response.statusText);
		if (await response.ok) {
			console.log(response.statusText);
			await this.RetrieveProfile();

			$("#UpdateButton").blur();
			await this.setState({
				Message: "Profile Saved.",
				EmailMessage: false
			});
			await new Promise(r => setTimeout(r, 10000));
			this.setState({
				Message: null
			});

		}
		else {
			console.log(response);
			$("#UpdateButton").blur();
			await this.setState({
				Message: "Profile Unsuccessfully Saved."
			});
			await new Promise(r => setTimeout(r, 10000));
			this.setState({
				Message: null
			});
		}
	}

	async RequestRemoval() {
		//Send request
		if (this.state.user === null)
			return;

		var data = {
			userId: this.state.user.userId,
			bool: $("[name=RemoveName]:checked").val()
		}

		const response = await fetch("/api/v1/user/RequestRemoval",
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			this.setState({
				RequestRemovalOpen: false
			});
		}
		else {
			console.log(response);
		}
	}

	ToggleRequestRemoval() {
		this.setState({
			RequestRemovalOpen: !this.state.RequestRemovalOpen
		});
	}

	async populateTags() {
		const response = await fetch('/api/v1/Tag',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (response.ok) {
			const data = await response.json();

			this.setState({
				possibleTags: data
			});
		}
	}

	setAllNotif() {
		let target = $("#all_notif").val();

		$(".notif_pref_select").val(target);

		$("#all_notif").val("- Set -")
	}

	render() {
		if (this.state.user === null || this.state.profile === null)
			return "";

		var role = null

		var admin = this.state.user.roles.includes("MODERATOR") || this.state.user.roles.includes("ADMIN")
		if (admin) {
			role = <Row>
				<h6 className="text-secondary ml-3"><i>Administrator</i></h6>
			</Row>
		}

		//Some changes will not propegate until you logout and log back in.
		return (
			<Row className="w-100 mx-auto">
				{!this.state.mobile &&
					<Col xs="3">
						<SidebarProfile user={this.state.user} history={this.props.history} />
					</Col>
				}
				<Col className="bg-white pt-2 mb-4 pb-4" xs={this.state.mobile ? "12" : "8"}>
					<Row className="BorderBottom">
						<Col>
							<h2 className="ml-4">Edit Profile</h2>
						</Col>
						<Col xs="auto">
							{this.state.Message &&
								<p className="text-secondary mt-2 mb-2">{this.state.Message}</p>
							}
						</Col>
						<Col xs="auto">
							<Button className="mx-auto" id="UpdateButton" color="success" onClick={e => this.Update(e)} tabIndex="30">Update</Button>
						</Col>
					</Row>
					<Row className="mt-2">
						{this.state.wide &&
							<Col className="mx-auto px-4" xs="3">
								<Row>
									<svg id={this.state.user.id} width="150" height="150" data-jdenticon-value={this.state.user.firstName + " " + this.state.user.lastName}></svg>
								</Row>
								<Row>
									{role}
								</Row>

							</Col>
						}
						<Col>
							<Row>
								<Col xs="4">
									<FormGroup>
										<Label for="FirstName">First Name</Label>
										<Input type="text" name="FirstName" id="FirstName" placeholder="" required={true}
											tabIndex="1" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<Label for="LastName">Last Name</Label>
										<Input type="text" name="LastName" id="LastName" placeholder="" required={true}
											tabIndex="2" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<Label for="IndustrySince">In Industry Since</Label>
										<Input type="number" name="IndustrySince" id="IndustrySince" placeholder=""
											tabIndex="6" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="8">
									<FormGroup>
										<Label for="School">School</Label>
										<Input type="text" name="School" id="School" placeholder=""
											tabIndex="4" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="8">
									<FormGroup>
										<Label for="Firm">Firm</Label>
										<Input type="text" name="Firm" id="Firm" placeholder=""
											tabIndex="5" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<Label for="FirmSince">With Firm Since</Label>
										<Input type="text" name="FirmSince" id="FirmSince" placeholder=""
											tabIndex="3" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="8">
									<FormGroup>
										<Label for="Occupation">Occupation</Label>
										<Input type="text" name="Occupation" id="Occupation" placeholder="" required={true}
											tabIndex="7" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="8">
									<FormGroup>
										<Label for="Email">Email <span className={(this.state.EmailMessage? "" : "d-none") + " text-danger ml-5"} >Email is required but can be hidden.</span></Label>
										<Input type="email" name="Email" id="Email" placeholder="" required={true}
											tabIndex="8" onKeyPress={this.handleKeyPress} />
										<FormGroup check className="mt-1 ml-3">
											<Label check>
												<Input type="checkbox" id="hideEmail" />{' '}
												Hide email from other users
											</Label>
										</FormGroup>
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<Label for="Phone">Phone</Label>
										<Input type="tel" name="Phone" id="Phone" placeholder=""
											tabIndex="9" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="4">
									<FormGroup>
										<Label for="City">City</Label>
										<Input type="text" name="City" id="City" placeholder=""
											tabIndex="10" onKeyPress={this.handleKeyPress} />
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<Label for="Province">Province</Label>
										<Input type="select" name="Province" id="Province" required={true} tabIndex="11" required={true}
											onKeyPress={this.handleKeyPress} style={{ width: "211px" }} >
											<option selected value="">-- Select Province --</option>
											<option>Alberta</option>
											<option>British Columbia</option>
											<option>Manitoba</option>
											<option>New Brunswick</option>
											<option>Newfoundland and Labrador</option>
											<option>Northwest Territories</option>
											<option>Nova Scotia</option>
											<option>Nunavut</option>
											<option>Ontario</option>
											<option>Prince Edward Island</option>
											<option>Quebec</option>
											<option>Saskatchewan</option>
											<option>Yukon</option>
										</Input>
									</FormGroup>
								</Col>
							</Row>

							<Row className="mx-0">
								<Col style={{ maxWidth: '750px' }}>
									<Row>
										<Col className="pl-0">
											<Label for="Bio">Bio</Label>
										</Col>
										<Col xs="auto">
											<span className={this.state.BioClass}>{this.state.BioCount}</span>
										</Col>
									</Row>
									<Row>
										<Input type="textarea" name="Bio" id="Bio" placeholder=""
											tabIndex="10" onKeyDown={this.handleKeyPressTextArea}
											onPaste={this.handleKeyPressTextArea} />
									</Row>
								</Col>
							</Row>
							<Row className="mx-0">
								<h5 className="mt-4">Notification Preferences</h5>
							</Row>
							{this.state.possibleTags != null &&
							<Row className="mx-0 notif_pref">
								<Col>
									{/*Admin Notif*/}
									{admin &&
									<Row className="my-1 alt-row">
										<Col xs="4">
											<p>All Activity (Admin Only)</p>
										</Col>
										<Col xs="auto">
											<Input
												name="admin_notif"
												type="select"
												id="admin_notif"
												bsSize="sm"
											>
												<option
													value="None"
													selected={this.state.profile.adminNotif == "None" || !this.state.profile.adminNotif}
												>
													None
												</option>
												<option
													value="GCon"
												>
													GsinqConnect
												</option>
												<option
													value="Email"
												>
													Email & GsinqConnect
												</option>
											</Input>
										</Col>
									</Row>
									}
									{/*All*/}
									<Row className="my-1">
										<Col xs="4">
											<p style={{ textAlign: "right"}}>Set All</p>
										</Col>
										<Col xs="auto">
											<Input
												name="all_notif"
												type="select"
												id="all_notif"
												bsSize="sm"
												onChange={this.setAllNotif}
											>
												<option>
													- Set -
												</option>
												<option
													value="None"
												>
													None
												</option>
												<option
													value="GCon"
												>
													GsinqConnect
												</option>
												<option
													value="Email"
												>
													Email & GsinqConnect
												</option>
											</Input>
										</Col>
									</Row>
									{/*Post Reply*/}
									<Row className="my-1 alt-row">
										<Col xs="4">
											<p>My post has comments</p>
										</Col>
										<Col xs="auto">
											<Input
												name="post_comment"
												type="select"
												id="post_comment"
												bsSize="sm"
												className="notif_pref_select"
											>
												<option
													value="None"
													selected={this.state.profile.notifPostReply == "None" || !this.state.profile.notifPostReply}
												>
													None
												</option>
												<option
													value="GCon"
												>
													GsinqConnect
												</option>
												<option
													value="Email"
												>
													Email & GsinqConnect
												</option>
											</Input>
										</Col>
									</Row>
									{/*Comment Reply*/}
									<Row className="my-1">
										<Col xs="4">
											<p>My comment has reply</p>
										</Col>
										<Col xs="auto">
											<Input
												name="comment_reply"
												type="select"
												id="comment_reply"
												bsSize="sm"
												className="notif_pref_select"
											>
												<option
													value="None"
													selected={this.state.profile.notifCommentReply == "None" || !this.state.profile.notifCommentReply}
												>
													None
												</option>
												<option
													value="GCon"
												>
													GsinqConnect
												</option>
												<option
													value="Email"
												>
													Email & GsinqConnect
												</option>
											</Input>
										</Col>
									</Row>
									{/*Tags*/}
									<Row className="my-1 alt-row">
										<Col xs="4">
											<p style={{ textDecoration: 'underline' }}>New post with tags</p>
										</Col>
									</Row>
									{this.state.possibleTags.map((tag, index) =>
										<Row key={index} className={"my-1 " + (index % 2 ? "alt-row" : "")} >
											<Col xs="4">
												<p className="ml-2" key={index}>{tag.label}</p>
											</Col>
											<Col xs="auto">
												<Input
													name={"tag_" + tag.tag_Id}
													type="select"
													id={"tag_" + tag.tag_Id}
													bsSize="sm"
													className="notif_pref_select"
												>
													<option
														value="None"
													>
														None
													</option>
													<option
														value="GCon"
													>
														GsinqConnect
													</option>
													<option
														value="Email"
													>
														Email & GsinqConnect
													</option>
												</Input>
											</Col>
										</Row>

									)}
								</Col>
							</Row>
							}
							<Row>
								<Col>
									<Button color="danger" onClick={this.ToggleRequestRemoval} className="ml-auto mr-4 mt-2">Request Account Removal</Button>
								</Col>
								<Col xs="auto">
									{this.state.Message &&
										<p className="text-secondary mt-2 mb-2">{this.state.Message}</p>
									}
								</Col>
								<Col xs="auto">
									<Button className="mx-auto" id="UpdateButton" color="success" onClick={e => this.Update(e)} tabIndex="30">Update</Button>
								</Col>
							</Row>
						</Col>
					</Row>

					<Modal isOpen={this.state.RequestRemovalOpen} toggle={this.ToggleRequestRemoval} backdrop={true}>
						<ModalHeader toggle={this.ToggleRequestRemoval}>Request Removal</ModalHeader>
						<ModalBody>
							<Row className="mx-1">
								<p>This request will be sent to an Administrator for approval.</p>
							</Row>
							<FormGroup tag="fieldset" className="mx-1">
								<p className="mb-0">Remove your name from historic posts and comments in GsinqConnect? </p>
								<p className="mb-0">If yes, anything with your name will be changed to "Former User"</p>
								<FormGroup check className="ml-1">
									<Label check style={{ width: "60px" }}>
										<Input type="radio" name="RemoveName" value={true} />{' '}
										Yes
									</Label>
									<Label check>
										<Input type="radio" name="RemoveName" value={false} checked />{' '}
										No
									</Label>
								</FormGroup>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" onClick={this.ToggleRequestRemoval}>Cancel</Button>{' '}
							<Button color="danger" onClick={this.RequestRemoval}>Send Request</Button>
						</ModalFooter>
					</Modal>

				</Col>
			</Row>
		);
	}

}
//maxWidth: '93.5% !important'
//marginRight: '6.5%'