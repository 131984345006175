import React, { Component } from 'react';
import PostHeader from './PostHeader';
import Reactions from './Reactions';
import { Comment } from './Comment';
import Poll from './Poll';
import {
	Row, Col, Button, UncontrolledCarousel, Modal, ModalHeader, ModalBody,
	Input, FormGroup, Label, ModalFooter, Carousel, CarouselItem, CarouselCaption,
	CarouselControl
} from 'reactstrap';
import $ from "jquery";
import ImageViewer from 'react-simple-image-viewer';
import { Link } from 'react-router-dom';
import { GetBearerToken, GetUser, FullDate, FullTime } from "../_helpers"
import './Post.css'
import { UserLink } from './UserLink';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { actions } from 'griddle-react';

library.add(fas)

export class Post extends Component {
	constructor(props) {
		super(props);
		const { post, user, allComments } = this.props;
		this.lastType = new Date(0);
		this.state = {
			post: post,
			user: user,
			userViewing: null,
			replyTo: {
				Number: null,
				label: null,
				name: null
			},
			AllComments: allComments || false,
			EventResponses: {
				going: [],
				not_going: [],
				unsure: []
			},
			EventToolTip: false,
			EventToolTipPage: 0,
			EditEventModal: false,
			EditPostModal: false,
			eventDesc: null,
			postText: null,
			typing: false,
			isViewerOpen: false,
			imageIndex: 0,
			imageAnimating: false
		};

		//this.setState({
		//	post: post,
		//	user: user
		//});
		//console.log(this.props);
		//console.log(this.state);
		this.AddComment = this.AddComment.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);

		this.ReplyTo = this.ReplyTo.bind(this);
		this.RemoveReplyTo = this.RemoveReplyTo.bind(this);
		this.Highlight = this.Highlight.bind(this);

		this.ToggleAllComment = this.ToggleAllComment.bind(this);

		this.ToggleEventToolTip = this.ToggleEventToolTip.bind(this);
		this.EventTabClick = this.EventTabClick.bind(this);
		this.CheckForResponse = this.CheckForResponse.bind(this);
		this.AddResponse = this.AddResponse.bind(this);
		this.UpdateResponses = this.UpdateResponses.bind(this);

		this.EditEvent = this.EditEvent.bind(this);
		this.EditPost = this.EditPost.bind(this);
		this.SaveEvent = this.SaveEvent.bind(this);
		this.SavePost = this.SavePost.bind(this);
		this.handleChangeEvent = this.handleChangeEvent.bind(this);
		this.handlePostEditChangeEvent = this.handlePostEditChangeEvent.bind(this);
		this.EventQuillRefEvent = React.createRef();
		this.EventQuillRefPost = React.createRef();

		this.updatePostCallback = this.updatePostCallback.bind(this);

		this.ToggleImageViewer = this.ToggleImageViewer.bind(this);
		this.Next = this.Next.bind(this);
		this.Previous = this.Previous.bind(this);
		this.setAnimating = this.setAnimating.bind(this);
	}

	modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
			['link'],
			['clean']
		],
	}

	formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link'
	]

	// This allows the child commonent to update when the parent state changes https://stackoverflow.com/a/41233828
	async componentWillReceiveProps(nextProps) {
		await this.setState({
			post: nextProps.post,
			user: nextProps.user
		});

		this.UpdateResponses();
	}

	componentDidMount() {
		this.setState({
			userViewing: GetUser()
		});

		this.UpdateResponses();

		$("textarea").each(function () {
			this.setAttribute("style", "height:60px;overflow-y:hidden;resize:none;");
		}).on("input", function () {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight) + "px";
		});
	}

	handleKeyPress(e) {
		if (e.key === "Enter" && !e.shiftKey) {
			this.AddComment(this.state.post.postId);
			e.preventDefault();
		}
	}

	handleKeyUp(e) {
		console.log($("#commentText" + this.state.post.postId).val());
		var temp = $("#commentText" + this.state.post.postId).val() != "";
		this.setState({
			typing: temp
		});
	}

	async AddComment(postId) {
		var today = new Date();
		var date = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
		var time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
		var dateTime = date + 'T' + time;

		$("#AddCommentBtn_" + postId).prop('disabled', true);

		var body = $("#commentText" + postId).val();
		//console.log(body);
		//console.log(this.state);
		if (body !== undefined && $("#commentText" + postId).val() !== "") {
			var newComment = {
				PostId: this.state.post.postId,
				TextContent: body,
				UserId: this.state.userViewing.userId,
				PostedTime: dateTime,
				ParentCommentNumber: null
			}

			if (this.state.replyTo.Number !== null) {
				console.log(this.state.replyTo);
				newComment.ParentCommentNumber = this.state.replyTo.Number;
				newComment.ParentCommentName = this.state.replyTo.name;
			}

			//const response =
			var response = await fetch("api/v1/comment",
				{
					method: 'post',
					body: JSON.stringify(newComment),
					headers: {
						'Content-Type': 'application/json',
						'Authorization': GetBearerToken()
					}
				});
			//var comments = [newComment];
			//comments = comments.concat(this.state.post.comment);

			if (await response.ok) {
				var data = await response.json();
				//console.log(data);

				var post = this.state.post;
				post.comments.push(data);

				this.setState({
					post: post,
					replyTo: {
						Number: null,
						label: null,
						name: null
					},
					typing: false
				});

				$("#commentText" + postId).val("");
			}
			else {
				console.log(response);
			}

			$("#AddCommentBtn_" + postId).prop('disabled', false);
			//console.log(this.state);
			//console.log(newComment);

			//var newPost = this.state.post;
			//newPost.comment = newComment;

			//this.setState({
			//	post: newPost
			//});

			
			//console.log(this.state.post.postId);
			//this.props.updateNewsfeedCallback(this.props.history, false, this.state.post.postId);
		}
	}

	updateNewsfeedCallback = () => {
		this.props.updateNewsfeedCallback(this.props.history, true)
	}

	updatePostCallback = (commentsEnabled) => {
		var temp = this.state.post

		temp.commentsEnabled = commentsEnabled;

		this.setState({
			post: temp
		});
	}

	ReplyTo(CommentNumber, CommenterName) {
		this.setState({
			replyTo: {
				Number: CommentNumber,
				label: 'Replying to ' + CommenterName,
				name: CommenterName
			}
		});
		$("#commentText" + this.state.post.postId).focus();
	}

	RemoveReplyTo() {
		this.setState({
			replyTo: {
				Number: null,
				label: null
			}
		});
	}

	async Highlight(parentNumber) {
		console.log('Highlight : ' + parentNumber);

		//var $select = $(`.comment${parentNumber}`);
		var $select = $(`.comment${parentNumber} > div >div > .commentText`);


		$select.addClass('highlight');
		await new Promise(r => setTimeout(r, 2000));
		$select.removeClass('highlight');
	}

	ToggleAllComment() {
		this.setState({
			AllComments: !this.state.AllComments
		});
	}

	ToggleEventToolTip(index = null) {

		var current = this.state.EventToolTipPage

		if (index != null) {
			current = index;
		}

		this.setState({
			EventToolTip: !this.state.EventToolTip,
			EventToolTipPage: current
		});
	}

	EventTabClick(index) {
		//console.log(index);
		this.setState({
			EventToolTipPage: index
		});
	}

	CheckForResponse(index) {
		var Responses = [];

		if (index == 0)
			Responses = this.state.EventResponses.going;

		else if (index == 1)
			Responses = this.state.EventResponses.not_going;

		else if (index == 2)
			Responses = this.state.EventResponses.unsure;

		for (var i = 0; i < Responses.length; ++i) {
			if (this.state.userViewing.userId == Responses[i].user.userId)
				return true;
		}

		return false;
	}

	UpdateResponses() {
		if (this.state.post == null || this.state.post.event == null) {
			return;
		}

		var EventResponses = {
			going: [],
			not_going: [],
			unsure: []
		}

		if (this.state.post.event != null) {
			for (var i = 0; i < this.state.post.event.resonses.length; i++) {
				var temp = this.state.post.event.resonses[i];

				if (temp.responsesText == "Going") {
					EventResponses.going.push(temp);
				}

				if (temp.responsesText == "Not Going") {
					EventResponses.not_going.push(temp);
				}

				if (temp.responsesText == "Unsure") {
					EventResponses.unsure.push(temp);
				}
			}
		}

		this.setState({
			EventResponses: EventResponses
		});
	}

	async AddResponse(index) {
		var body = {
			'PostId': this.state.post.postId,
			'UserId': this.state.userViewing.userId,
			'Response': index == 0 ? "Going" : (index == 1 ? "Not Going" : "Unsure")
		}
		
		var response = await fetch("api/v1/Event",
			{
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			var data = await response.json();
			//console.log(data);

			var newPost = this.state.post;

			newPost.event = data;

			await this.setState({
				post: newPost
			});
			this.UpdateResponses();
			//console.log(this.state.post)
		}
		else {
			console.log(response);
		}
	}

	EditEvent() {

		if (!this.state.EditEventModal) {
			this.setState({
				eventDesc: this.state.post.event.description
			});
		}

		this.setState({
			EditEventModal: !this.state.EditEventModal
		});
	}

	EditPost() {
		if (!this.state.EditPostModal) {
			this.setState({
				postText: this.state.post.textContent
			});
		}

		this.setState({
			EditPostModal: !this.state.EditPostModal
		});
	}

	async SaveEvent() {
		var newEvent = {
			Title: null,
			Description: this.state.eventDesc,
			Location: null,
			DateTime: null,
			DateEndTime: null,
			PostId: this.state.post.postId,
			EventId: this.state.post.event.eventId
		}

		if ($('#eventTitle').val() == "") {
			$('#eventTitle').addClass('is-invalid');
			return;
		} else {
			newEvent.Title = $('#eventTitle').val()
		}

		if (!$('#eventLocation').val() == "") {
			newEvent.Location = $('#eventLocation').val()
		}

		if ($('#eventDate').val() == "") {
			$('#eventDate').addClass('is-invalid');
			return;
		} else if ($('#eventTime').val() == "") {
			$('#eventTime').addClass('is-invalid');
			return;
		} else {
			newEvent.DateTime = $('#eventDate').val() + "T" + $('#eventTime').val();
		}


		if ($('#eventDate').val() == "") {
			$('#eventDate').addClass('is-invalid');
			return;
		} else if ($('#eventEndTime').val() !== "") {
			newEvent.EndDateTime = $('#eventDate').val() + "T" + $('#eventEndTime').val()
		}
		const response = await fetch("/api/v1/Event",
			{
				method: 'PUT',
				body: JSON.stringify(newEvent),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			var data = await response.json();

			var temp = this.state.post

			temp.event = data;

			console.log(temp);

			//this.setState({
			//	post: temp
			//});

		} else {
			console.log(response);

		}

		this.setState({
			EditEventModal: false
		});
	}

	async SavePost() {
		var content = "";

		if (this.state.postText == "") {
			alert("Post must contain content.")
			return;
		} 

		content = this.state.postText;

		const response = await fetch(`/api/v1/Post/${this.state.post.postId}`,
			{
				method: 'PUT',
				body: JSON.stringify(content),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			var temp = this.state.post;

			temp.textContent = content;
			temp.edited = true;

			this.setState({
				post: temp
			});

		} else {
			console.log(response);

		}

		this.setState({
			EditPostModal: false
		});
	}

	handleChangeEvent(value) {
		this.setState({
			eventDesc: value
		});
	}

	handlePostEditChangeEvent(value) {
		this.setState({
			postText: value
		});
	}

	ToggleImageViewer() {
		this.setState({
			isViewerOpen: !this.state.isViewerOpen
		});
	}

	Next() {
		if (this.state.imageAnimating)
			return;

		this.setState({
			imageIndex: this.state.imageIndex === this.state.post.images.length - 1 ? 0 : this.state.imageIndex + 1
		});
	}

	Previous() {
		if (this.state.imageAnimating)
			return;

		this.setState({
			imageIndex: this.state.imageIndex === 0 ? this.state.post.images.length - 1 : this.state.imageIndex - 1
		});
	}

	setAnimating(value) {
		this.setState({
			imageAnimating: value
		});
	}

	render() {
		var comments;
		var line;
		var commentCount;
		var images;
		var files;
		var tags;
		var event;
		var poll;

		//console.log(this.state.post);

		if (this.state.post === null || this.state.user === null) {
			return (
				<Row className="mx-auto" xs="auto">
					<span className="spinner-grow mx-auto" style={{ width: "3rem", height: "3rem" }} role="status" aria-hidden="true"></span>
				</Row>);
		}

		if (this.state.post.commentsEnabled) {
			var number = this.state.post.comments.length;
			if (!(number === undefined)) {
				line = <hr className="my-0" />;
				//console.log(this.state.post.comments);
				//comments = <Comment data={this.state.post.comment } />
				if (number > 3 && this.state.AllComments) {
					commentCount = <Row className="mb-2 ml-2">
						<Button color="link" onClick={this.ToggleAllComment}>Show less comments</Button>
					</Row>
					comments = this.state.post.comments.map((comment, key) =>
						<Comment data={comment} key={comment.commentNumber}
							userViewing={this.state.userViewing} ReplyCallback={this.ReplyTo}
							HighlightCallback={this.Highlight} history={this.props.history} />
					);
				}
				else if (number > 3 && !this.state.AllComments) {
					commentCount = <Row className="mb-2 ml-2">
						<Button color="link" onClick={this.ToggleAllComment}>Show {number - 3} more comments</Button>
					</Row>
					var comments_sub = this.state.post.comments.slice(number - 3, number)
					comments = comments_sub.map((comment, key) =>
						<Comment data={comment} key={comment.commentNumber}
							userViewing={this.state.userViewing} ReplyCallback={this.ReplyTo}
							HighlightCallback={this.Highlight} history={this.props.history} />
					);
				}
				else {
					commentCount = null;
					comments = this.state.post.comments.map((comment, key) =>
						<Comment data={comment} key={key}
							userViewing={this.state.userViewing} ReplyCallback={this.ReplyTo}
							HighlightCallback={this.Highlight} history={this.props.history} />
					);
				}
			}
		}

		//console.log(this.state.post);
		//console.log(this.state.post.images.length);
		if (this.state.post.images.length > 0) {
			var imageList = [];
			var maxHeight = 10;
			var slides = [];

			for (var i = 0; i < this.state.post.images.length; i++) {
				var img = this.state.post.images[i];
				// img.originalFileName

				slides.push(
					<CarouselItem
						onExiting={() => this.setAnimating(true)}
						onExited={() => this.setAnimating(false)}
						
						key={i}
					>
						<img
							src={img.imageBase64}
							onClick={this.ToggleImageViewer}
							style={{ display: "block" }}
						/>
						<CarouselCaption captionText="" captionHeader="" />
					</CarouselItem>)
				imageList.push(img.imageBase64)

				var image = new Image();
				image.src = img.imageBase64;

				var renderWidth = window.innerWidth * 0.45;

				renderWidth = renderWidth > image.width ? image.width : renderWidth;

				var renderHeight = image.height * renderWidth / image.width;

				maxHeight = maxHeight < renderHeight ? renderHeight : maxHeight;
			}
			var multiple = slides.length > 1;
			images = <div className="mb-1" >
				<div style={{ minHeight: maxHeight + "px" }}  >
					<Carousel
						activeIndex={this.state.imageIndex}
						next={this.Next}
						previous={this.Previous}
					>
						{slides}
						{multiple &&
							<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.Previous} />
						}
						{multiple &&
							<CarouselControl direction="next" directionText="Next" onClickHandler={this.Next} />
						}
					</Carousel>
				</div>
				{this.state.isViewerOpen && (
					<ImageViewer
						src={imageList}
						currentIndex={this.state.imageIndex}
						onClose={this.ToggleImageViewer}
						style={{ zIndex: 1050 }}
						backdropCloseable
						backgroundStyle={{
							backgroundColor: "rgba(0,0,0,0.7)"
						}}
					/>
				)}
			</div>
		}

		if (this.state.post.files.length > 0) {
			var items = [];
			for (var i = 0; i < this.state.post.files.length; i++) {
				var file = this.state.post.files[i];

				items.push(
					<a download={file.originalFileName} href={file.fileBase64}>
						<Row>
							<Col xs="auto">
								<FontAwesomeIcon icon="file-alt" color="lightgrey" />
							</Col>
							<Col>
								<p className="mb-1">{file.originalFileName}</p>
							</Col>
						</Row>
					</a>);
			}
			files = <div className="my-1 mx-3">
				{items.map((file, key) =>
					<div key={key}>
						{file}
					</div>)}
			</div>
		}

		if (this.state.post.tags.length > 0) {
			tags = <Row className="text-secondary ml-1" style={{ fontSize: "85%" }}  >
				<span className="mr-1">Topic Tags:</span>{this.state.post.tags.map((tag, key) =>
					<span className="mr-1" key={key}>{tag}</span>	
				)}
			</Row>
		}

		if (this.state.post.event != null) {
			var EventResponses = <div>
				<Modal isOpen={this.state.EventToolTip} toggle={this.ToggleEventToolTip}>
					<ModalHeader toggle={this.ToggleEventToolTip}>{this.state.post.event.title}</ModalHeader>
					<ModalBody>
						<Row style={{
							"borderBottom": "Solid black 1px"
						}}>
							<Col className={` ${this.state.EventToolTipPage == 0 ? 'tabSelect' : ''} pt-2 responseTab`}
								onClick={() => { this.EventTabClick(0) }}>
								Going
								<FontAwesomeIcon icon="check-circle" className="mx-2 text-success" />
								{this.state.EventResponses.going.length}
							</Col>
							<Col className={` ${this.state.EventToolTipPage == 1 ? 'tabSelect' : ''} pt-2 responseTab`}
								onClick={() => { this.EventTabClick(1) }}>
								Not Going
								<FontAwesomeIcon icon="times-circle" className="mx-2 text-danger" />
								{this.state.EventResponses.not_going.length}
							</Col>
							<Col className={` ${this.state.EventToolTipPage == 2 ? 'tabSelect' : ''} pt-2 responseTab`}
								onClick={() => { this.EventTabClick(2) }}>
								Unsure
								<FontAwesomeIcon icon="question-circle" className="mx-2 text-warning" />
								{this.state.EventResponses.unsure.length}
							</Col>
						</Row>
						<Row>
							{this.state.EventToolTipPage == 0 &&
								<Col className="m-3">
									{this.state.EventResponses.going.map((response, key) =>
										<Row key={key}>
											<UserLink history={this.props.history} id={response.user.userId} underline={true}
												name={response.user.firstName + " " + response.user.lastName} />
										</Row>)}
								</Col>

							}
							{this.state.EventToolTipPage == 1 &&
								<Col className="m-3">
									{this.state.EventResponses.not_going.map((response, key) =>
										<Row key={key}>
											<UserLink history={this.props.history} id={response.user.userId} underline={true}
												name={response.user.firstName + " " + response.user.lastName} />
										</Row>)}
								</Col>

							}
							{this.state.EventToolTipPage == 2 &&
								<Col className="m-3">
									{this.state.EventResponses.unsure.map((response, key) =>
										<Row key={key}>
											<UserLink history={this.props.history} id={response.user.userId} underline={true}
												name={response.user.firstName + " " + response.user.lastName} />
										</Row>)}
								</Col>

							}
						</Row>
						
					</ModalBody>
				</Modal>
			</div>

			event = <Row className="my-2 p-2" style={{ backgroundColor: (this.state.post.event.past ? "#E9EBEE" : "#D2E0F0") }}>
				<Col> 
					<Row>
						<h5>{this.state.post.event.title}</h5>
					</Row>
					<Row className="ml-2">
						{"Description: "}
						<div className="rawHtml ml-1" dangerouslySetInnerHTML={{ __html: (this.state.post.event.description == 'null' ? "" : this.state.post.event.description) }}></div>
					</Row>
					<Row className="ml-2">
						{"Date: " + FullDate(this.state.post.event.dateTime, true)}
					</Row>
					<Row className="ml-2">
						{"Time: " + FullTime(this.state.post.event.dateTime)}
						{this.state.post.event.endDateTime && <span className="ml-1">{" to " + FullTime(this.state.post.event.endDateTime)}</span>}
					</Row>
					<Row className="ml-2">
						{"Location: " + (this.state.post.event.location == null || this.state.post.event.location == 'null' ? "" : this.state.post.event.location) }
					</Row>
					{this.state.post.event.edited &&
						<p className="text-secondary ml-2" style={{
							'fontSize': "90%"
						}}>(Edited)</p>
					}
				</Col>
				<Col xs="3">
					<Row className="m-2">
						<Button outline={!this.CheckForResponse(0)} color="secondary" size="sm" onClick={() => { this.AddResponse(0) }}
							style={{ width: "100px" }} disabled={this.state.post.event.past} >
							Going
							<FontAwesomeIcon icon="check-circle" className="ml-2 text-success" />
						</Button>
						<p className="my-auto pl-3 responseLink"
							onClick={() => { this.ToggleEventToolTip(0) }} >{this.state.EventResponses.going.length}</p>
					</Row>
					<Row className="m-2">
						<Button outline={!this.CheckForResponse(1)} color="secondary" size="sm" onClick={() => { this.AddResponse(1) }}
							style={{ width: "100px" }} disabled={this.state.post.event.past} >
							Not Going
							<FontAwesomeIcon icon="times-circle" className="ml-2 text-danger" />
						</Button>
						<p className="my-auto pl-3 responseLink"
							onClick={() => { this.ToggleEventToolTip(1) }}>{this.state.EventResponses.not_going.length}</p>
					</Row>
					<Row className="m-2">
						<Button outline={!this.CheckForResponse(2)} color="secondary" size="sm" onClick={() => { this.AddResponse(2) }}
							style={{ width: "100px" }} disabled={this.state.post.event.past} >
							Unsure
							<FontAwesomeIcon icon="question-circle" className="ml-2 text-warning" />
						</Button>
						<p className="my-auto pl-3 responseLink"
							onClick={() => { this.ToggleEventToolTip(2) }}>{this.state.EventResponses.unsure.length}</p>
					</Row>
				</Col>
				{EventResponses}
			</Row>

		}

		if (this.state.post.poll != null) {
			poll = <Poll data={this.state.post.poll} history={this.props.history} postId={this.state.post.postId} />
		}

		//onClick={() => { this.AddComment(this.state.post.key) }} 
		return (
			<div className="bg-white mb-4 py-2 px-4 w-100">
				<PostHeader
					user={this.state.post.user}
					userViewing={this.state.userViewing}
					dateTime={this.state.post.postedTime}
					postId={this.state.post.postId}
					pinned={this.state.post.pinned}
					commentsEnabled={this.state.post.commentsEnabled}
					history={this.props.history}
					event={this.state.post.event !== null}
					EditEvent={this.EditEvent}
					EditPost={this.EditPost}
					updatePostCallback={this.updatePostCallback}
					updateNewsfeedCallback={this.updateNewsfeedCallback}
				/>
				{this.state.post.textContent != null &&
						<div className="rawHtml p-1" dangerouslySetInnerHTML={{
							__html: this.state.post.textContent + (this.state.post.edited ? "<span style='font-size: 90%; color: grey;'>(edited)</span>" : "")
						}} />
				}
				{poll}
				{event}
				{images}
				{files}
				{tags}
				{line}
				<Row>
					<Col>
						<Reactions
							data={this.state.post.reactions}
							postId={this.state.post.postId}
							use="post"
							history={this.props.history} />
					</Col>
					<Col xs="auto" className="ml-auto mr-2">
						{commentCount}
					</Col>
				</Row>
				{comments}
				{this.state.replyTo.label &&
					<Row>
						<span style={{ fontSize: "95%", color: "grey" }}
							className="ml-2 ReplyToLabel"
							onClick={() => { this.RemoveReplyTo() }} >{this.state.replyTo.label}<span className="text-danger ml-1">x</span></span>
					</Row>
				}
				{this.state.post.commentsEnabled &&
					<Row className="mb-4" style={{ position: "relative", minWidth: "100%" }}>
						<textarea className="form-control" size="sm" style={{ display: "Block", minWidth: "100%", resize: "none" }}
							placeholder="Write a comment…"
							id={"commentText" + this.state.post.postId}
							onKeyPress={this.handleKeyPress} onKeyUp={this.handleKeyUp}
						>
						</textarea>
						{this.state.typing &&
							<span
								className=""
								style={{ fontSize: "95%", color: "grey", position: "absolute", bottom: "-22px", right: "4px" }}
							>
							Press <b>Shift + Enter</b> to add a new paragraph 
							<span style={{ width: "30px", display: "inline-block" }} />
							Press <b>Enter</b> to post your comment
						</span>
						}

					</Row>
				}

				{this.state.post.event != null &&
					<Modal isOpen={this.state.EditEventModal} toggle={this.EditEvent} className="modal-lg" backdrop={true}>
						<ModalHeader toggle={this.EditEvent}>Edit Event</ModalHeader>
						<ModalBody>
							<Row className="mx-2 mt-1 mb-4">
								<Input type="text" placeholder="Event Title" id="eventTitle"
									onChange={() => $('#eventTitle').removeClass('is-invalid')}
									defaultValue={this.state.post.event.title}
								/>
							</Row>
							<Row className="mx-2 my-1">
								<ReactQuill value={this.state.eventDesc}
									onChange={this.handleChangeEvent}
									modules={this.modules}
									formats={this.formats}
									placeholder="Event Description"
									id="eventDescription"
									className=""
									style={{ display: "Block", minWidth: "100%", marginBottom: "50px" }}
									ref={this.EventQuillRefEvent}
								/>
							</Row>
							<Row className="mx-2 my-1">
								<Col className="p-0 pr-3" >
									<FormGroup>
										<Label for="eventDate">Date</Label>
										<Input
											type="date"
											name="eventDate"
											id="eventDate"
											placeholder=""
											onChange={() => $('#eventDate').removeClass('is-invalid')}
											defaultValue={this.state.post.event.dateTime.substring(0, 10)}
										/>
									</FormGroup>
								</Col>
								<Col className="p-0 pl-3" >
									<FormGroup>
										<Label for="eventTime">Time</Label>
										<Input
											type="time"
											name="eventTime"
											id="eventTime"
											placeholder=""
											onChange={() => $('#eventTime').removeClass('is-invalid')}
											defaultValue={this.state.post.event.dateTime.substring(11)}
										/>
									</FormGroup>
								</Col>
						</Row>
						<Row className="mx-2 my-1">
							<Col className="p-0 pl-3 ml-auto mr-0" xs="6">
								<FormGroup>
									<Label for="eventEndTime">End Time</Label>
									<Input
										type="time"
										name="eventEndTime"
										id="eventEndTime"
										placeholder=""
										onChange={() => $('#eventEndTime').removeClass('is-invalid')}
										defaultValue={this.state.post.event.endDateTime == null ? "" : this.state.post.event.endDateTime.substring(11)}
									/>
								</FormGroup>
							</Col>
						</Row>
							<Row className="mx-2 mt-1 mb-4">
								<Input type="text" placeholder="Event Location" id="eventLocation"
									onChange={() => $('#eventLocation').removeClass('is-invalid')}
									defaultValue={this.state.post.event.location}
								/>
							</Row>

						</ModalBody>
						<ModalFooter>
						<Button color="danger" onClick={this.EditEvent}>Cancel</Button>{' '}
							<Button color="primary" onClick={this.SaveEvent}>Save</Button>
						</ModalFooter>
					</Modal>
				}
				<Modal isOpen={this.state.EditPostModal} toggle={this.EditPost} className="modal-lg" backdrop={true}>
					<ModalHeader toggle={this.EditPost}>Edit Post</ModalHeader>
					<ModalBody className="pt-0 pb-0">
						<Row className="">
							<ReactQuill value={this.state.postText}
								onChange={this.handlePostEditChangeEvent}
								modules={this.modules}
								formats={this.formats}
								placeholder="Post content"
								id="PostContent"
								className=""
								style={{ display: "Block", minWidth: "100%", marginBottom: "42px" }}
								ref={this.EventQuillRefPost}
							/>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.EditPost}>Cancel</Button>{' '}
						<Button color="primary" onClick={this.SavePost}>Save</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
};

/*
<div className="row p-3">
					{this.state.post.textContent}
				</div>
 */