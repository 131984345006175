import React, { Component } from 'react';
import { Col, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { GetBearerToken } from "../_helpers"
import $ from "jquery";


export class Requests extends Component {
	static displayName = Requests.name;

	constructor(props) {
		super(props);

		this.state = {
			Requests: [],
			ApproveModal: false,
			DenyModal: false,
			SuspendedAccounts: []
		};


		this.Respond = this.Respond.bind(this);

		this.ToggleApprove = this.ToggleApprove.bind(this);
		this.ToggleDeny = this.ToggleDeny.bind(this)

		this.UnSuspend = this.UnSuspend.bind(this);
	}

	async componentDidMount() {
		//Loaded

		this.RetriveRequestList();
		this.GetSuspendedAccounts();
	}

	async RetriveRequestList() {
		const response = await fetch("/api/v1/user/GetRemovalRequest",
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const Requests = await response.json();


			this.setState({
				Requests: Requests
			});
		}
		else {
			console.log(response);
			return;
		}
	}

	async Respond(userId, Approve) {
		//console.log("Make Administrator");

		var body = {
			userId: userId,
			bool: Approve 
		}
		console.log(body);
		const response = await fetch("/api/v1/user/ApproveRemoval",
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {

			this.RetriveRequestList();
			this.setState({
				ApproveModal: false,
				DenyModal: false
			});
		}
		else {
			console.log(response);
			return;
		}
	}

	ToggleApprove() {
		this.setState({
			ApproveModal: !this.state.ApproveModal
		});
	}

	ToggleDeny() {
		this.setState({
			DenyModal: !this.state.DenyModal
		});
	}

	async GetSuspendedAccounts() {
		const response = await fetch("/api/v1/user/Suspend",
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const Accounts = await response.json();


			this.setState({
				SuspendedAccounts: Accounts
			});
		}
		else {
			console.log(response);
			return;
		}
	}

	async UnSuspend(userId) {
		const response = await fetch("/api/v1/user/Suspend/" + userId,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const Accounts = await response.json();


			this.setState({
				SuspendedAccounts: Accounts
			});
		}
		else {
			console.log(response);
			return;
		}
	}

	render() {

		return (
			<Row>
				<Col>
					<Row>
						<h2>Account Removal</h2>
					</Row>
					<Row>
						<Col xs='12' className="my-2">
							<h3>Removal Requests</h3>
							<Table className="my-2">
								<thead>
									<tr>
										<th>Name</th>
										<th>Remove Name</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.state.Requests.map((request, key) =>
										<tr key={key}>
											<td scope="row">{request.firstName + " " + request.lastName}</td>
											<td>{request.removeName ? "Yes" : "No"}</td>
											<td>
												<Button color="danger" size="sm" className="mx-2"
													onClick={this.ToggleApprove}>
													Approve Request (Remove)
												</Button>
												<Button color="primary" size="sm" className="mx-2"
													onClick={this.ToggleDeny}>
													Deny Request (Keep)
												</Button>

												<Modal isOpen={this.state.ApproveModal} toggle={this.ToggleApprove} size="sm">
													<ModalHeader toggle={this.ToggleApprove}>Approve Request</ModalHeader>
													<ModalBody>
														Are you sure you wish to approve this request (Remove the user)?
													</ModalBody>
													<ModalFooter>
														<Button color="danger" onClick={() => this.Respond(request.userId, true)}>Delete User</Button>{' '}
														<Button color="secondary" onClick={this.ToggleApprove}>Cancel</Button>
													</ModalFooter>
												</Modal>

												<Modal isOpen={this.state.DenyModal} toggle={this.ToggleDeny} size="sm">
													<ModalHeader toggle={this.ToggleDeny}>Deny Request</ModalHeader>
													<ModalBody>
														Are you sure you wish to deny this request (Keep the user)?
													</ModalBody>
													<ModalFooter>
														<Button color="primary" onClick={() => this.Respond(request.userId, false)}>Keep User</Button>{' '}
														<Button color="secondary" onClick={this.ToggleDeny}>Cancel</Button>
													</ModalFooter>
												</Modal>
											</td>
										</tr>
									)}
									{this.state.Requests.length == 0 &&
									<tr>
										<td>No Requests</td>
									</tr>
									}
								</tbody>
							</Table >


							
						</Col>
					</Row>

					<Row>
						<Col xs='12' className="my-2 mt-5">
							<h3>Removed Users</h3>
							<Table className="my-2">
								<thead>
									<tr>
										<th>Name</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.state.SuspendedAccounts.map((account, key) =>
										<tr key={key}>
											<td scope="row">{account.firstName + " " + account.lastName}</td>
											<td>
												<Button color="success" size="sm" className="mx-2"
													onClick={() => { if (window.confirm('Are you sure you wish to restore this account?')) this.UnSuspend(account.userId) }}>
													Restore
												</Button>
											</td>
										</tr>
									)}
									{this.state.SuspendedAccounts.length == 0 &&
										<tr>
											<td>No Suspended Accounts</td>
										</tr>
									}
								</tbody>
							</Table >
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

}
