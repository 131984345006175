import React, { Component } from 'react';
//import { Badge } from './Badge';
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle, UncontrolledTooltip, Button } from 'reactstrap';
import { FormateDate } from "../_helpers"
import { GetBearerToken, GetUser } from "../_helpers"
//import Emoji from './Emoji';
import _waving_hand from '../_images/Emojis/waving-hand.png';
import _party_popper from '../_images/Emojis/party-popper.png';
import _thumbs_up from '../_images/Emojis/thumbs-up.png';
import _astonished_face from '../_images/Emojis/astonished-face.png'
import _laughing_face from '../_images/Emojis/smiling-face-with-open-mouth-and-tightly-closed-eyes.png'
import _grinning_face from '../_images/Emojis/grinning-face.png'
import _thinking_face from '../_images/Emojis/thinking-face.png'
import './Reactions.css';
import { UserLink } from './UserLink';


export default class Reactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			postId: props.postId,
			data: props.data,
			user: null,
			userReact: false,
			allReact: false
		};

		var temp_emojis = {};

		temp_emojis['waving-hand'] = {
			label: 'Wave',
			img: _waving_hand,
			under: "waving_hand"
		}
		temp_emojis['thumbs-up'] = {
			label: 'Like',
			img: _thumbs_up,
			under: "thumbs_up"
		}
		temp_emojis['party-popper'] = {
			label: 'Celebrate',
			img: _party_popper,
			under: "party_popper"
		}
		temp_emojis['astonished-face'] = {
			label: 'Wow',
			img: _astonished_face,
			under: "astonished_face"
		}
		temp_emojis['laughing-face'] = {
			label: 'Haha',
			img: _laughing_face,
			under: "laughing_face"
		}
		temp_emojis['grinning-face'] = {
			label: 'Smile',
			img: _grinning_face,
			under: "grinning_face"
		}
		temp_emojis['thinking-face'] = {
			label: 'Thinking',
			img: _thinking_face,
			under: "thinking_face"
		}

		this.state.emojis = temp_emojis;


		//console.log(props.data);

		this.AddReaction = this.AddReaction.bind(this);
		this.RemoveReaction = this.RemoveReaction.bind(this);

		this.clickReaction = this.clickReaction.bind(this);

		this.onMouseEnterAdd = this.onMouseEnterAdd.bind(this);
		this.onMouseLeaveAdd = this.onMouseLeaveAdd.bind(this);

		this.onMouseEnterAll = this.onMouseEnterAll.bind(this);
		this.onMouseLeaveAll = this.onMouseLeaveAll.bind(this);
	}

	componentDidMount() {
		this.setState({
			user: GetUser()
		});
	}

	async clickReaction(reaction) {

		if (reaction === null)
			return;

		this.setState({
			userReact: false
		});

		var userReaction = null

		for (var i = 0; i < this.state.data.length; i++) {
			if (this.state.user.userId === this.state.data[i].user.userId)
				userReaction = this.state.data[i].reactionType;
		}

		//console.log(this.state.user.userId);
		//alert(reaction + " : " + userReaction);


		//Add new reaction
		if (userReaction === null) {
			this.AddReaction(reaction);
		}
		//Remove existing reaction
		else if (userReaction === reaction) {
			this.RemoveReaction();
		}
		//Replace existing reaction with new reaction
		else {
			await this.RemoveReaction();
			await this.AddReaction(reaction);
		}
	}

	async AddReaction(reactionType) {

		if (reactionType === null || this.state.user === null)
			return;

		var data = {
			PostId: this.state.postId,
			CommentNumber: this.props.commentNumber,
			UserId: this.state.user.userId,
			ReactionType: reactionType
		}

		console.log('add');
		console.log(data);

		const response = await fetch(`/api/v1/Reaction/${this.props.use}`,
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			var data = await response.json();

			console.log(data);

			this.setState({
				data: data.reactions
			});
		}
		else {
			console.log(await response);
		}
	}

	async RemoveReaction() {

		if (this.state.user === null)
			return;

		var data = {
			PostId: this.state.postId,
			CommentNumber: this.props.commentNumber,
			UserId: this.state.user.userId
		}

		console.log('remove');
		console.log(data);

		const response = await fetch(`/api/v1/Reaction/${this.props.use}`,
			{
				method: 'DELETE',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			var data = await response.json();

			console.log(data);

			this.setState({
				data: data.reactions
			});
		}
		else {
			console.log(await response);
		}
	}

	onMouseEnterAdd() {
		this.setState({ userReact: true });

		setTimeout(function () {
			this.setState({ userReact: false });

		}.bind(this), 5000);
	}
	onMouseLeaveAdd() {
		
		setTimeout(function () {
			this.setState({ userReact: false });

		}.bind(this), 2000);
	}

	onMouseEnterAll() {
		this.setState({ allReact: true });

		setTimeout(function () {
			this.setState({ allReact: false });

		}.bind(this), 5000);
	}
	onMouseLeaveAll() {

		setTimeout(function () {
			this.setState({ allReact: false });

		}.bind(this), 2000);
	}

	render() {
		var current = null;
		var userReaction = null;

		if (this.state.user == null)
			return (<div></div>);

		if (this.state.data !== null && this.state.data !== [] && this.state.data.length > 0) {
			var items = []
			var keys = []

			for (var i = 0; i < this.state.data.length; i++) {

				for (const [key, value] of Object.entries(this.state.emojis)) {
					//console.log(key, value);
					//console.log([this.state.data[i].reactionType === key, keys.includes(this.state.data[i].reactionType)])
					if (this.state.data[i].reactionType === key && !keys.includes(this.state.data[i].reactionType)) {
						items.push(<img src={value.img} alt="" height="18" width="18" key={key} style={{verticalAlign: "sub" }} />)
						keys.push(key);
						break;
					}
				}

				if (this.state.user.userId === this.state.data[i].user.userId)
					userReaction = this.state.data[i].reactionType;
			}
			current = <div>
				<span className="mr-2">{this.state.data.length}</span>
				{items}
				
			</div>
		}
		if (this.state.data.length === 0 && this.props.use == "post") {
			//current = <div style={{
			//	_webkit_filter: "grayscale(100%)",
			//	filter: "grayscale(100%)",
			//	font_size: "5em"
			//}}>

			//	<Emoji label="thumbs-up" symbol="👍" 
			//	/>
			//</div>
			current = <img src={_thumbs_up} alt="" height="18" width="18" className="" style={{ filter: "grayscale(100%)" }} />
		}

		var responses = <Col xs="auto">
							<Dropdown onMouseOver={this.onMouseEnterAll} onMouseLeave={this.onMouseLeaveAll} onClick={this.onMouseEnterAll} isOpen={this.state.allReact}
								toggle={console.log} direction="down" className="mt-1" style={{ cursor: 'pointer' }} >
								<DropdownToggle
									tag="span"
									data-toggle="dropdown"
									aria-expanded={this.state.allReact}
								>
									{current}
								</DropdownToggle>
								<DropdownMenu className="AllReactionDropdown" style={{
										overflowX: "hidden",
										overflowY: 'auto',
										maxHeight: "300px"
									}}
								>
									{this.state.data.map((r, key) =>
										<Row key={key}>
											<Col fontSize="70%" className="ml-4 pl-0 pr-0">
												<UserLink history={this.props.history} id={r.user.userId} underline={true}
													name={r.user.firstName + " " + r.user.lastName} />
											</Col>
											<Col xs="auto" className="pl-1 pr-0 mr-4">
												<img src={this.state.emojis[r.reactionType].img} alt="" height="21" width="21"
													id={'_' + this.state.postId + '_' + r.user.userId + "_" + (this.props.use == "comment" ? this.props.commentNumber : "")}
												/>
												<UncontrolledTooltip target={'_' + this.state.postId + '_' + r.user.userId + "_" + (this.props.use == "comment" ? this.props.commentNumber : "")}
													placement="right" >
													{this.state.emojis[r.reactionType].label}
												</UncontrolledTooltip>
											</Col>
										</Row>
									)}
								</DropdownMenu>
							</Dropdown>
						</Col>
		return (
			<Row className="ml-2 my-0">
				<Col xs="auto" className="px-0" >
					<Dropdown onMouseOver={this.onMouseEnterAdd} onMouseLeave={this.onMouseLeaveAdd} onClick={this.onMouseEnterAdd} isOpen={this.state.userReact}
						toggle={console.log} direction="up" className="my-1" style={{ cursor: 'pointer' }} >
						<DropdownToggle
							tag="span"
							data-toggle="dropdown"
							aria-expanded={this.state.allReact}
						>
							<Button className="py-0" outline color="secondary" size="sm">+</Button>
						</DropdownToggle>
						<DropdownMenu style={{
								minWidth: "40px !important",
								backgroundColor: "transparent",
								border: "0px"
							}}
							tag="div"
						>
							<div style={{
								fontSize: "125%",
								backgroundColor: "white",
								width: "max-content",
								padding: "5px",
								borderRadius: "15px",
								border: "1px solid black"
							}}>
								{Object.keys(this.state.emojis).map((key, i) => <div style={{ display: "inline" }} key={key}>
									<img src={this.state.emojis[key].img} alt="" height="24" width="24"
										className={"ReactionAdd mx-1 " + ((key === userReaction) ? 'SelectedReaction' : '')}
										onClick={() => this.clickReaction(key)} label={key}
										id={'_' + this.state.postId + "_" + this.state.emojis[key].under + "_" + (this.props.use == "comment" ? this.props.commentNumber : "")}
									/>
									<UncontrolledTooltip target={'_' + this.state.postId + "_" + this.state.emojis[key].under + "_" + (this.props.use == "comment" ? this.props.commentNumber : "")} >
										{this.state.emojis[key].label}
									</UncontrolledTooltip>
								</div>)}
								
							</div>
						</DropdownMenu>
					</Dropdown>
				</Col>
				{responses}				
			</Row>
		);
	}
};