import React, { Component } from 'react';
import { SidebarProfile } from "../_components";
import {
	Col, Row, Button, FormGroup, Label, Input, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle,
	DropdownMenu, DropdownItem
} from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, GetUser, FullDate } from "../_helpers"
import { UserListLink } from "./UserListLink"

import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export class UserList extends Component {
	static displayName = UserList.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			users: [],
			data: [],
			fullData: [],
			userViewing: null,
			userListLength: 0,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.SetAll = this.SetAll.bind(this);
		this.SetAdmin = this.SetAdmin.bind(this);
		this.FilterEvent = this.FilterEvent.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {
		await this.setState({
			userViewing: GetUser()
		});

		this.UpdateUserList();
	}

	async UpdateUserList() {
		const response = await fetch("/api/v1/user",
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			//console.log(response.statusText);
			const users = await response.json();
			var data = []

			for (var i = 0; i < users.length; i++) {
				var temp = {
					Name: [ users[i].firstName + ' ' + users[i].lastName, users[i].userId],
					Occupation: users[i].occupation,
					Firm: users[i].firm,
					City: users[i].city,
					Province: users[i].province,
					user_id: users[i].userId,
					roles: users[i].roles
				}

				let active = users[i].lastActive;

				if (active == null || active == "0001-01-01T00:00:00")
					temp['LastActive'] = "";
				else
					temp['LastActive'] = FullDate(active);

				data.push(temp);
			}

			//console.log(data);
			this.setState({
				user: users,
				data: data,
				fullData: data,
				userListLength: data.length
			});

		}
		else {
			console.log(response);
		}
	}

	SetAll() {
		this.setState({
			data: this.state.fullData
		});
	}

	SetAdmin() {
		var temp = this.state.fullData;
		var tempOut = [];

		for (var i = 0; i < temp.length; i++) {
			var user = temp[i];
			if (user.roles.indexOf("MODERATOR") >= 0) {
				//console.log(user);

				tempOut.push(user);
			}
		}

		this.setState({
			data: tempOut
		});
	}

	FilterEvent(e) {
		setTimeout(() => {
			this.setState({
				userListLength: $(".griddle-table-body tr").length
			});
		}, 200);
	}

	sortByDate(data, column, sortAscending) {

		var sort = data.sort(function (a, b) {
			if (new Date(b.get('LastActive')) === new Date(a.get('LastActive'))) {
				return 0;
			} else if (new Date(a.get('LastActive')) > new Date(b.get('LastActive')) || b.get('LastActive') == "") {
				return sortAscending ? 1 : -1;
			}
			else {
				return sortAscending ? -1 : 1;
			}
		});
		return sort;
	}

	render() {

		var userList = <Row className="w-100">
							<span className="spinner-grow mx-auto" style={{
									width: "3rem",
									height: "3rem"
								}} role="status" aria-hidden="true"> </span>
						</Row>
		/* Pagination
			<Col xs="auto">
				<Pagination />
			</Col>
		 */
		const NewLayout = ({ Table, Filter }) => (
			<div className="w-100">
				<Row>
					<Col>
						<Filter />
					</Col>
					<Col xs="auto" className="mr-1">
						<UncontrolledDropdown>
							<DropdownToggle color="white">
								<FontAwesomeIcon icon="cog" color="black" className="text-secondary" />
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={this.SetAll}>
									All Users
									</DropdownItem>
								<DropdownItem onClick={this.SetAdmin} >
									Administrators
									</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table />
					</Col>
				</Row>
			</div>
		);

		const styleConfig = {
			classNames: {
				TableHeadingCell: 'col',
				Table: 'table table-striped mt-1 w-100',
				Filter: 'form-control w-25',
				PageDropdown: 'btn btn-light dropdown-toggle'
			},
			styles: {
				Table: { "tableLayout": "fixed"}
			}
		}
		var textProperties = {
			filterPlaceholder: 'Search'
		}
		var pageProperties = {
			pageSize: 500
		}
		var val = "20%"

		const tempLink = ({ value }) => <UserListLink value={value} history={this.props.history} />
		//const tempLink = <UserLink  />


		if (this.state.data.length !== 0) {
			if (this.state.userViewing.roles.includes("MODERATOR") || this.state.userViewing.roles.includes("ADMIN")) {
				var val = "15%"
				userList = <Row className="w-85 mx-auto my-2 pb-2" id="GriddleWrapper">
					<Griddle
						data={this.state.data}
						plugins={[plugins.LocalPlugin]}
						styleConfig={styleConfig}
						components={{
							Layout: NewLayout
						}}
						textProperties={textProperties}
						events={{
							onFilter: this.FilterEvent
						}}
						pageProperties={pageProperties}
					>
						<RowDefinition>
							<ColumnDefinition id="Name" title="Name" width={val} customComponent={tempLink} />
							<ColumnDefinition id="Occupation" title="Occupation" width={val} />
							<ColumnDefinition id="Firm" title="Firm" width={val} />
							<ColumnDefinition id="City" title="City" width={val} />
							<ColumnDefinition id="Province" title="Province" width={val} />
							<ColumnDefinition id="LastActive" title="Last Active" width={val} sortMethod={this.sortByDate} />
						</RowDefinition>
					</Griddle>
				</Row>
			}
			else
				userList = <Row className="w-85 mx-auto my-2 pb-2" id="GriddleWrapper">
					<Griddle
						data={this.state.data}
						plugins={[plugins.LocalPlugin]}
						styleConfig={styleConfig}
						components={{
							Layout: NewLayout
						}}
						textProperties={textProperties}
						events={{
							onFilter: this.FilterEvent
						}}
						pageProperties={pageProperties}
					>
						<RowDefinition>
							<ColumnDefinition id="Name" title="Name" width={val} customComponent={tempLink} />
							<ColumnDefinition id="Occupation" title="Occupation" width={val} />
							<ColumnDefinition id="Firm" title="Firm" width={val} />
							<ColumnDefinition id="City" title="City" width={val} />
							<ColumnDefinition id="Province" title="Province" width={val} />
						</RowDefinition>
					</Griddle>
				</Row>
		}

		return (
			<Row className="w-100 mx-auto">
				{!this.state.mobile &&
					<Col xs="3">
						<SidebarProfile user={this.state.user} history={this.props.history} />
					</Col>
				}
				<Col className="bg-white pt-2 mb-4 pb-4" xs={this.state.mobile? "12" : "8"}>
					<Row className="BorderBottom">
						<Col>
							<h2 className="ml-4">Members<sup style={{ fontSize: "60%", marginLeft: "5px" }}>{this.state.userListLength}</sup></h2>
						</Col>
					</Row>
					{userList}
				</Col>
			</Row>
		);
	}

}