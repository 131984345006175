import React, { Component } from 'react';
import { Col, Row, Button, Navbar, Container, NavbarBrand, Input, NavLink } from 'reactstrap';
import gsi_logo from '../_images/gsi_logo_inverse.png';

import { Link } from 'react-router-dom'

export class AccountLayout extends Component {
    static displayName = AccountLayout.name;

    constructor(props) {
        super(props);

        let size = sessionStorage.getItem("size");
        this.state = {
            med: size == "med",
            mobile: size == "mobile"
        };

        window.addEventListener("resize", () => {
            setTimeout(() => {
                let size = sessionStorage.getItem("size");

                this.setState({
                    med: size == "med",
                    mobile: size == "mobile"
                });
            }, 100);
        });
    }

    updateLogOutCallback = (loggedIn, user) => {
        this.props.updateLoginCallback(loggedIn, user)
    }

    render() {
        return (
            <div style={{ minWidth: "300px" }}>
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 fixed-top bg-gsi py-0" light
                        style={{ minWidth: "300px" }}>
                        <Container fluid={true} px="5">
                            <Col xs="auto">
                                <NavbarBrand tag={Link} to="/" className="titleFont">
                                    <img src={gsi_logo} alt="" height="25" width="25" className="mt-0 mb-2 mr-2" />
									G<span style={{ fontSize: "70%" }}>SINQ</span>Connect
									<sup style={{ fontSize: "0.65rem", top: "-1.2em" }}>TM</sup>
                                </NavbarBrand>
                            </Col>
                        </Container>
                    </Navbar>
                </header>
                <div className={"container-fluid " + (this.state.mobile ? "px-2" : (this.state.med ? "px-3" : "px-5"))} style={{ paddingTop: 60 + 'px' }}>
                    {this.props.children}
                </div>
                <p className="text-secondary" style={{ position: "fixed", left: "20px", bottom: "0px" }}>
                    &#169;{new Date().getFullYear()} Granville Software Inc.
                </p>
            </div>
        );
    }
}
