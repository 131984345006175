import React, { Component } from 'react';
import { Post, SidebarProfile } from "../_components";
import {
	Col, Row, Input, Label,	FormGroup
} from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, GetUser } from "../_helpers"
import { ContentPageView } from '../ContentPage/ContentPageView';
import './NewsFeed.css';
import { CreatePost } from "./CreatePost"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

//old - current
//https://alligator.io/react/react-infinite-scroll/
//Other method considered (has pull down to refresh)
//https://www.npmjs.com/package/react-infinite-scroll-component

export class NewsFeed extends Component {
	static displayName = NewsFeed.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			error: false,
			hasMore: true,
			loading: true,
			waiting: false,
			posts: [],
			user: null,
			lastPost: null,
			filter: [],
			med: size == "med",
			mobile: size == "mobile"
		};

		this.populateTags = this.populateTags.bind(this);
		this.UpdateFilter = this.UpdateFilter.bind(this);
		this.populateNewsfeed = this.populateNewsfeed.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}


	async componentDidMount() {
		//var currentdate = new Date();
		//var datetime = "Last Sync: " + currentdate.getDate() + "-"
		//	+ (currentdate.getMonth() + 1) + "-"
		//	+ currentdate.getFullYear() + "T"
		//	+ currentdate.getHours() + ":"
		//	+ currentdate.getMinutes() + ":"
		//	+ currentdate.getSeconds();

		await this.populateNewsfeed();
		//if (this.state.posts.length > 0) {
		//	this.setState({
		//		user: this.state.posts[0].user
		//	});
		//	//console.log(this.state.user);
		//}
		this.setState({
			user: GetUser()
		});

		await this.populateTags();


		// Binds our scroll event handler
		document.getElementById('BodyContentId').addEventListener('scroll', () => {
			//Only check for more posts on newsfeed
			if (this.props.history.location.pathname != '/') {
				return
			}

			const {
				state: {
					error,
					hasMore,
				},
			} = this;

			let token = GetBearerToken();
			if (token === 'Bearer undefined' || token === null || token === '') return;

			if (error || !hasMore) return;

			var element = document.getElementById('BodyContentId')

			if (element.scrollHeight - element.scrollTop - 100 < element.clientHeight) {
				console.log("get more posts");
				this.state.loading = true;
				this.setState({ loading: true });
				this.populateNewsfeed(this.props.history);
			}
		});
	}

	//componentDidUpdate() {
	//}

	async populateNewsfeed(history, postDelete = false, goToId = null) {
		//const response = await fetch(`v2/test/10`);
		//console.log(this.state);
		var route = "";

		//console.log({ postDelete, goToId })
		if (postDelete) {
			//console.log(this);
			this.setState({
				posts: [],
				lastPost: null,
				loading: true
			});
		}

		//console.log(history);
		if (goToId != null) {
			//history.push('/post/' + goToId)
			//window.location.reload();
			//window.scrollTo(0, 0);

			this.setState({
				posts: [],
				lastPost: null,
				loading: true
			});

			//this.populateNewsfeed();
			//return;
		}

		if (this.state.waiting) {
			console.log("Already waiting");
			return
		}
		this.setState({ waiting: true });

		if (this.state === undefined || this.state.lastPost === null)
			route = `api/v1/NewsFeed`;
		else
			route = `api/v1/NewsFeed/` + this.state.lastPost;

		var body = {
			Filter: this.state.filter
		};

		const response = await fetch(route,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		const data = await response.json();

		//console.log(data.length);
		if (data.length === 0) {
			//console.log(data);
			this.setState({
				hasMore: false,
				loading: false,
				waiting: false
			});
			return;
		}

		//If the first element returned already exists in the newsfeed than cancel out since call not needed
		if (this.state.posts.filter(e => e.postId === data[0].postId).length > 0) {
			return;
		}

		var allPosts = this.state.posts.concat(data);
		//console.log(data);

		this.setState({
			hasMore: data.length !== 0,
			loading: false,
			posts: allPosts,
			lastPost: allPosts[allPosts.length - 1].postId,
			waiting: false
		});

		//console.log(allPosts);
		//console.log(this.state);
	}

	async populateTags() {
		const response = await fetch('/api/v1/Tag',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (response.ok) {
			const data = await response.json();

			this.setState({
				possibleTags: data
			});
		}
	}

	async UpdateFilter() {
		var NewFilter = [];

		for (var i = 0; i < this.state.possibleTags.length; i++) {
			var temp = null;

			//$('#filterOption_' + this.state.possibleTags[i].tag_Id).val();

			//console.log('#filterOption_' + this.state.possibleTags[i].tag_Id);
			//console.log(temp);

			if ($('#filterOption_' + this.state.possibleTags[i].tag_Id).is(':checked'))
				temp = this.state.possibleTags[i].tag_Id;
			
			if (temp !== null && temp !== undefined)
				NewFilter.push(temp);
		}

		//console.log(NewFilter);

		if (JSON.stringify(NewFilter) !== JSON.stringify(this.state.filter)) {
			
			await this.setState({
				filter: NewFilter
			});

			this.populateNewsfeed(this.props.history, true, null);
		}
	}



	render() {
		//console.log(this.state);
		/*
		 <textarea className="form-control mb-2" style={{ display: "Block", minWidth: "100%" }} placeholder="What do you have to say?"
											id="NewPostText1" onKeyPress={this.handleKeyPressPopout}></textarea>
		 */
		return (
			<div>
				<Col>
					<p>{this.state.mobile}
					{this.state.med}</p>
					<Row>
						{!this.state.mobile &&
							<Col xs="3">
								{this.state.user != null &&
									<SidebarProfile user={this.state.user} history={this.props.history} />
								}
								{this.state.med &&
									<ContentPageView page='About' />
								}
								{this.state.med &&
									<ContentPageView page='Rules' />
								}
							</Col>
						}
						<Col>
							<CreatePost populateNewsfeedCallback={this.populateNewsfeed} user={this.state.user} history={this.props.history} />
							{this.state.possibleTags != null &&
								<Row className="bg-white mb-3 py-2 px-4" onClick={this.UpdateFilter}>
									<p className="text-secondary mb-0" style={{ width: '98%' }} >Filter Newsfeed by Topic Tag(s)</p>
									{this.state.possibleTags.map((tag, key) =>
										<FormGroup check inline key={key}>
											<Label check>
												<Input type="checkbox" id={"filterOption_" + tag.tag_Id} value={tag.tag_Id} /> {tag.label}
											</Label>
										</FormGroup>
									)}
								</Row>
							}
							<Row >
								{this.state.user != null &&
									<div className="w-100">{this.state.posts.map((post, key) =>
										<Post post={post} key={key} user={this.state.user}
											updateNewsfeedCallback={this.populateNewsfeed.bind(this)} history={this.props.history} />
									)}</div>
								}
								{this.state.loading &&
									<div className="mx-auto">
										<span className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status" aria-hidden="true"></span>
									</div>
								}
								{!this.state.hasMore && this.state.posts.length > 0 &&
									<div className="mx-auto">No More Post</div>
								}
								{this.state.posts.length == 0 && !this.state.loading &&
									<div>No posts for this tag yet</div>
								}

							</Row>

						</Col>
						{!this.state.med && !this.state.mobile &&
							<Col xs="3">
								<ContentPageView page='About' />
								<ContentPageView page='Rules' />
							</Col>
						}
					</Row>
				</Col>
			</div>
		);
	}

}