import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './_components/Layout';
//import { FetchData } from './_components/FetchData';
//import { Counter } from './_components/Counter';

import './custom.css'

import { AccountLayout } from './Account/Layout';
import { LogInPage } from './Account/LogInPage';
import { Register } from './Account/Register'
import { Forgot } from './Account/Forgot';
import { Reset } from './Account/Reset';


import { Profile } from './Profile/Profile';
import { UserList } from './Profile/UserList';
import { PublicProfile } from './Profile/PublicProfile';

import { NewsFeed } from './NewsFeed/NewsFeed';
import { PostPage } from './Post/PostPage';
import { NotificationsPage } from './Notifications/NotificationsPage';

import { Events } from './Events/Events';

//import { ContentPage } from './ContentPage/ContentPage';

import { SearchPage } from './Search/SearchPage';

import { Administration } from './Administration/Administration'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            user: null
        };

        this.updateLoginCallback = this.updateLoginCallback.bind(this);
    }

    componentDidMount() {
        let size = "wide";

        if (window.innerWidth < 1000 && window.innerWidth >= 500) {
            size = "med";
        }
        else if (window.innerWidth < 500) {
            size = "mobile";
        }

        sessionStorage.setItem("size", size);

        window.addEventListener("resize", () => {
            let size = "wide";

            if (window.innerWidth < 1000 && window.innerWidth >= 520) {
                size = "med";
            }
            else if (window.innerWidth < 520) {
                size = "mobile";
            }

            //console.log("App.js change: " + size)
            sessionStorage.setItem("size", size);
        })
    }

    updateLoginCallback = (user, loggedIn) => {
        //console.log(this.state);
        //console.log({ user, loggedIn });
        //console.log(this);
        this.setState({
            loggedIn: loggedIn,
            user: user
        });
        //console.log(this.state);
    }

    render() {

        var loggedIn = document.cookie.split(';').filter(item => {
            return item.includes('currentUser=');
        }).length === 0

        console.log("Logged in: " + loggedIn);

        if (loggedIn) {
            return (
                <Route render={({ history }) =>
                    <AccountLayout updateLoginCallback={this.updateLoginCallback} history={history} >
                        <Switch> 
                            <Route path='/Register' exact component={() => <LogInPage updateLoginCallback={this.updateLoginCallback} />} />
                            <Route path='/Register/:inviteId' exact render={({ match, history }) => <Register inviteId={match.params.inviteId} updateLoginCallback={this.updateLoginCallback} history={history} />} />
                            <Route path='/Forgot' component={Forgot} />
                            <Route path='/Reset' exact component={() => <LogInPage updateLoginCallback={this.updateLoginCallback} />} />
                            <Route path='/Reset/:resetCode' exact render={({ match, history }) => <Reset resetCode={match.params.resetCode} updateLoginCallback={this.updateLoginCallback} history={history} />} />
                            <Route path='/' render={({ history }) => <LogInPage updateLoginCallback={this.updateLoginCallback} history={history} />} />
                        </Switch>
                    </AccountLayout>
                } />
            );
        }

        return (
            <Route render={({ history }) =>
                <Layout updateLoginCallback={this.updateLoginCallback} history={history} >
                    <Route exact path='/' component={NewsFeed} history={history} />
                    <Route exact path='/User' component={UserList} history={history} />
                    <Route exact path='/Post' component={NewsFeed} history={history} />
                    <Route exact path='/Search' component={SearchPage} history={history} />
                    <Route exact path='/Notifications' component={NotificationsPage} history={history} />
                    <Route exact path='/Events' component={Events} history={history} />

                    <Route path='/Profile' component={Profile} />
                    <Route path='/Members' component={UserList} history={history} />
                    <Route path='/User/:userId' component={PublicProfile} history={history} />
                    <Route path='/Post/:postId' component={PostPage} history={history} />
                    <Route path='/Search/:query' component={SearchPage} history={history} />
                    <Route path='/Administration' component={Administration} history={history} />
                    <Route path='/Reset' component={NewsFeed} history={history} />
                    <Route path='/Register' component={NewsFeed} history={history} />
                </Layout>
            } />
        );
    }
}

//<Route path='/counter' component={Counter} />
//<Route path='/fetch-data' component={FetchData} />
//<Route path='/About' component={() => <ContentPage page='About' />} />
//<Route path='/Rules' component={() => <ContentPage page='Rules' />} />