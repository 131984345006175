import React, { Component } from 'react';
import { Dropzone } from "../_components";
import {
	Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup,
	Label, Input
} from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, FullDate } from "../_helpers"
import './NewsFeed.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

export class CreatePost extends Component {

	constructor(props) {
		super(props);

		this.state = {
			error: false,
			modal: false,
			imageModal: false,
			images: [],
			fileModal: false,
			files: [],
			tempForm: null,
			possibleTags: [],
			addedTags: [],
			tempText: null,
			pollModal: false,
			pollObj: null,
			tempPollObj: {
				title: null,
				options: []
			},
			event: {
				open: false,
				obj: null,
				tempDesc: null
			}
		};


		this.handleKeyPressMain = this.handleKeyPressMain.bind(this);
		this.handleKeyPressPopout = this.handleKeyPressPopout.bind(this);
		this.handleChangePopout = this.handleChangePopout.bind(this);

		this.CreatePost = this.CreatePost.bind(this);
		this.CreatePostFromPopout = this.CreatePostFromPopout.bind(this);
		this.AddImage = this.AddImage.bind(this);
		this.AddFile = this.AddFile.bind(this);

		//this.populateNewsfeed = this.populateNewsfeed.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleImage = this.toggleImage.bind(this);
		this.toggleFile= this.toggleFile.bind(this);

		this.ImageAdded = this.ImageAdded.bind(this);
		this.FileAdded = this.FileAdded.bind(this);

		this.quillRef = React.createRef();

		this.togglePoll = this.togglePoll.bind(this);
		this.AddPoll = this.AddPoll.bind(this);
		this.RemoveOption = this.RemoveOption.bind(this);
		this.AddOption = this.AddOption.bind(this);
		this.handleKeyPressOption = this.handleKeyPressOption.bind(this);

		this.populateTags = this.populateTags.bind(this);

		this.EventQuillRef = React.createRef();
		this.toggleEvent = this.toggleEvent.bind(this);
		this.AddEvent = this.AddEvent.bind(this);
		this.handleChangeEvent = this.handleChangeEvent.bind(this);
	}


	modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
			['link'],
			['clean']
		],
	}

	formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link'
	]

	async componentDidMount() {

		await this.populateTags();

	}

	//componentDidUpdate() {
	//}

	async populateTags() {
		const response = await fetch('/api/v1/Tag/Create',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (response.ok) {
			const data = await response.json();

			this.setState({
				possibleTags: data
			});
		}
	}

	handleKeyPressMain(e) {
		if (e.key === "Enter") {
			this.CreatePost();
		}
	}

	handleKeyPressPopout(e) {
		if (e.key === "Enter") {
			this.CreatePostFromPopout();
		}
	}

	handleChangePopout(value) {
		this.setState({
			tempText: value
		});
	}

	async CreatePost() {

		if (this.props.user == null) {
			console.log("User undefined");
			return;
		}

		var content = $("#NewPostText").val();

		if (this.fromPopup)
			content = this.state.tempText;
			//content = $("#NewPostText1").val();

		if (this.state.pollObj === null && this.state.event.obj === null && (content === "" || content === '<p><br></p>' || content === null)) {

			if (this.fromPopup) {
				//this.reactQuillRef.focus();
				//editor.setSelection(0, 5);
				this.quillRef.current.focus();
				//$("#NewPostText1").select();

			}
			else
				$("#NewPostText").select();

			return;
		}

		if ((this.state.pollObj !== null || this.state.event.obj !== null) && content === null) {
			content = "";
		}
		//console.log(this.props.user);

		$("#CreatePostBtn").prop('disabled', true);

		//var newPost = {
		//	UserId: this.props.user.userId,
		//	PostedTime: dateTime,
		//	TextContent: content
		//}
		//body: JSON.stringify(newPost)

		let form = new FormData();
		form.append('UserId', this.props.user.userId);
		form.append('TextContent', content);
		console.log({ "content": content });

		if (this.state.images.length > 0) {
			for (var i = 0; i < this.state.images.length; ++i) {
				var temp = this.state.images[i];
				//console.log(temp);


				let filename = temp[0].name.split('.');
				//console.log(filename);
				//var image = {
				//	Image: temp[0],
				//	OriginalFileName: filename[0],
				//	Extension: filename[1]
				//}

				//form.append(`Images[]`, image);
				//form.append(`Images[].Image`, temp[0]);
				//form.append(`Images[].OriginalFileName`, filename[0]);
				//form.append(`Images[].Extension`, filename[1]);
				form.append(`Images[${i}].Image`, temp[0]);
				form.append(`Images[${i}].OriginalFileName`, filename[0]);
				form.append(`Images[${i}].Extension`, filename[1]);

			}
		}

		if (this.state.files.length > 0) {
			for (var i = 0; i < this.state.files.length; ++i) {
				var temp = this.state.files[i];
				//console.log(temp);


				let filename = temp[0].name.split('.');

				form.append(`Files[${i}].File`, temp[0]);
				form.append(`Files[${i}].OriginalFileName`, filename[0]);
				form.append(`Files[${i}].Extension`, filename[1]);

			}
		}

		if (this.state.pollObj !== null) {
			form.append('Poll.PollTitle', this.state.pollObj.title);

			for (var i = 0; i < this.state.pollObj.options.length; i++) {
				form.append(`Poll.OptionText[${i}]`, this.state.pollObj.options[i]);
			}
		}

		if (this.state.event.obj !== null) {
			var temp = this.state.event.obj
			console.log(temp);

			form.append('Event.Title', temp.Title);
			form.append('Event.Description', temp.Description);
			form.append('Event.Location', temp.Location);
			form.append('Event.DateTime', temp.DateTime);

			if (temp.EndDateTime !== null)
				form.append('Event.EndDateTime', temp.EndDateTime);
		}

		if (this.state.possibleTags.length > 0) {
			var j = 0;
			for (var i = 0; i < this.state.possibleTags.length; i++) {
				var id = this.state.possibleTags[i].tag_Id;
				if ($("#tagOption_" + id).is(':checked')) {
					form.append(`TagIds[${j}]`, id);
					j += 1;
				}
			}
		}


		const response = await fetch("/api/v1/Post",
			{
				method: 'post',
				body: form,
				headers: {
					'Authorization': GetBearerToken()
				}
			});

		const data = await response.json();
		if (response.ok) {
			//console.log(data);

			//var allPosts = [newPost];
			//allPosts = allPosts.concat(this.state.posts);
			//this.setState({
			//	posts: allPosts
			//});

			//console.log(allPosts);
			//console.log(newPost);
			//console.log(this.state);
			$("#NewPostText").val("");
			this.setState({
				modal: false,
				tempText: "",
				pollObj: null,
				images: [],
				files: [],
				event: {
					open: false,
					obj: null,
					tempDesc: null
				}
			});
			this.fromPopup = false;
			this.props.populateNewsfeedCallback(this.props.history, false, data.postId);
		}
		else {
			this.setState({
				modal: false
			});
			this.fromPopup = false;
			this.props.populateNewsfeedCallback(this.props.history, false, data.postId);
			
			console.log(data);
			alert(data.Message);
		}

		$("#CreatePostBtn").prop('disabled', false);
	}

	CreatePostFromPopout() {
		this.fromPopup = true;
		this.CreatePost();
	}

	toggle() {
		//console.log("Toggle");
		//console.log(this.state.modal);
		if (!this.state.modal) {
			this.setState({
				images: [],
				files: []
			});
		}

		this.setState({
			modal: !this.state.modal,
			//tempText: !this.state.modal ? $("#NewPostText").val() : this.state.tempText
		});
	}

	toggleImage() {
		this.setState({
			imageModal: !this.state.imageModal
		});
	}

	toggleFile() {
		this.setState({
			fileModal: !this.state.fileModal
		});
	}

	togglePoll() {
		this.setState({
			pollModal: !this.state.pollModal
		});
	}

	AddImage() {
		console.log('Add Image');
		console.log(this.state.tempForm);
		let images = this.state.images;
		images.push(this.state.tempForm)
		this.setState({
			images: images
		});
		this.toggleImage();
	}

	AddFile() {
		console.log('Add File');
		console.log(this.state.tempForm);
		let files = this.state.files;
		files.push(this.state.tempForm)
		this.setState({
			files: files
		});
		this.toggleFile();
	}

	async ImageAdded(form) {
		//console.log(form);
		//console.log(form.keys());
		await this.setState({ tempForm: form });

		this.AddImage();
	}

	async FileAdded(form) {
		//console.log(form);
		//console.log(form.keys());
		await this.setState({ tempForm: form });

		this.AddFile();
	}

	async AddPoll() {
		var title = $('#pollTitle').val();

		if (title !== undefined && title !== null && title !== '') {

			if (title.length >= 255) {
				alert("Title too long. Must be less that 255 characters (currently at " + title.length + ')');
				return;
			}

			for (var i = 0; i < this.state.tempPollObj.options.length; i++) {
				
				if (this.state.tempPollObj.options[i].length >= 100) {
					alert('Option "' + this.state.tempPollObj.options[i] + '" is to long. Must be less that 100 characters. (currently at ' + this.state.tempPollObj.options[i].length + ')');
					return
				}
			}

			await this.setState({
				pollModal: false,
				pollObj: {
					title: title,
					options: this.state.tempPollObj.options
				},
				tempPollObj: {
					title: null,
					options: []
				}
			});
		}

		console.log(this.state.pollObj);
	}

	AddOption() {
		var options = this.state.tempPollObj.options;

		var newOpt = $('#newOption').val();

		if (newOpt !== undefined && newOpt !== null && newOpt !== '') {

			if (newOpt.length >= 100) {
				alert('Option "' + newOpt + '" is to long. Must be less that 100 characters. (currently at ' + newOpt.length + ')');
				return
			}

			options.push(newOpt);
			var newOpt = $('#newOption').val("");
			this.setState({
				tempPollObj: {
					title: this.state.tempPollObj.title,
					options: options
				}
			});
		}
	}

	RemoveOption(option) {
		var options = this.state.tempPollObj.options;

		options.splice(option, 1);

		this.setState({
			tempPollObj: {
				title: this.state.tempPollObj,
				options: options
			}
		});
	}

	handleKeyPressOption(e) {
		if (e.key == "Enter") {
			this.AddOption();
		}
	}

	toggleEvent() {
		var temp = this.state.event;

		temp.open = !temp.open;

		this.setState({
			event: temp
		});
	}

	AddEvent() {
		var newEvent = {
			Title: null,
			Description: this.state.event.tempDesc,
			Location: null,
			DateTime: null,
			EndDateTime: null
		}

		if ($('#eventTitle').val() == "") {
			$('#eventTitle').addClass('is-invalid');
			return;
		} else {
			newEvent.Title = $('#eventTitle').val()
		}

		if (!$('#eventLocation').val() == "") {
			newEvent.Location = $('#eventLocation').val()
		}

		if ($('#eventDate').val() == "") {
			$('#eventDate').addClass('is-invalid');
			return;
		} else if ($('#eventTime').val() == "") {
			$('#eventTime').addClass('is-invalid');
			return;
		} else {
			newEvent.DateTime = $('#eventDate').val() + "T" + $('#eventTime').val();
		}

		if ($('#eventDate').val() == "") {
			$('#eventDate').addClass('is-invalid');
			return;
		} else if ($('#eventEndTime').val() !== "") {
			newEvent.EndDateTime = $('#eventDate').val() + "T" + $('#eventEndTime').val()
		}

		var temp = this.state.event;

		temp.obj = newEvent;
		temp.open = false;

		console.log(temp);

		this.setState({
			event: temp
		});
	}

	handleChangeEvent(value) {
		var temp = this.state.event;

		temp.tempDesc = value;

		this.setState({
			event: temp
		});
	}


	render() {
		return (
			<div>
				<Row className="mb-2">
					<Col className="pl-0 mt-1">
						<h4>What do you want to share with the community?</h4>
					</Col>
					<Col xs="auto" className="ml-auto mr-0 pr-0" >
						<Button color="primary" onClick={this.toggle}>Create New Post</Button>
					</Col>
				</Row>
				
				<Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
					<ModalHeader toggle={this.toggle} style={{ borderBottom: "none", paddingBottom: "8px" }}>Create New Post</ModalHeader>
					<ModalBody style={{ paddingTop: "0px", paddingBottom: "8px" }}>
						<Row>
							<ReactQuill value={this.state.tempText}
								onChange={this.handleChangePopout}
								onKeyPress={this.handleKeyPressPopout}
								modules={this.modules}
								formats={this.formats}
								placeholder="What do you want to share with the community?"
								id="NewPostText1"
								className=""
								style={{ display: "Block", minWidth: "100%", marginBottom: "50px" }}
								ref={this.quillRef}
							/>
						</Row>
						{this.state.possibleTags != null &&     
							<Row className="BorderBottom px-4 pb-1">
								{this.state.possibleTags.map((tag, key) =>
									<FormGroup check inline key={key}>
										<Label check>
											<Input type="checkbox" id={"tagOption_" + tag.tag_Id} value={tag.tag_Id} /> {tag.label}
										</Label>
									</FormGroup>
								)}
							</Row>
						}
						<Row className="pt-2">
							<Col>
								<Button outline color="secondary" className="m-1" onClick={this.toggleImage}>Add Image</Button>
								<Button outline color="secondary" className="m-1" onClick={this.toggleFile}>Add Document</Button>
								{this.props.user !== null && (this.props.user.roles.includes("MODERATOR") || this.props.user.roles.includes("ADMIN")) &&
									<div style={{ 'display': "inline" }}>
										<Button outline color="secondary" onClick={this.togglePoll} className="m-1">Add Poll</Button>
										<Button outline color="secondary" onClick={this.toggleEvent} className="m-1">Add Event</Button>
									</div>
								}
							</Col>
						</Row>
						{this.state.pollObj !== null && this.state.pollObj.title !== null &&
							<Row>
								<p className="text-secondary pl-2 mb-1">
									<span>Poll:  {this.state.pollObj.title}</span>
								</p>
							</Row>
						}
						{this.state.images.length !== 0 &&
							<Row>
								<p className="text-secondary pl-2 mb-1">
									<span>Images:  {this.state.images != null &&
										this.state.images.map((i, k) =>
											<span key={k} >{i[0].name + ' '}</span>
										)}
									</span>
								</p>
							</Row>
						}
						{this.state.files.length !== 0 &&
							<Row>
								<p className="text-secondary pl-2 mb-1">
									<span>Attatchments:  {this.state.files != null &&
										this.state.files.map((i, k) =>
											<span key={k} >{i[0].name + ' '}</span>
										)}
									</span>
								</p>
							</Row>
						}
						{this.state.event.obj !== null &&
							<Row>
							<p className="text-secondary pl-2 mb-1">
								<span>Event:  {this.state.event.obj.Title} at {FullDate(this.state.event.obj.DateTime, true)}, {this.state.event.obj.Location}</span>
								</p>
							</Row>
						}
					</ModalBody>
					<ModalFooter style={{
						paddingTop: "8px"
					}}>
						<Button color="danger" onClick={this.toggle}>Cancel</Button>{' '}
						<Button color="primary" id="CreatePostBtn" onClick={this.CreatePostFromPopout}>Post</Button>
					</ModalFooter>
				</Modal>


				<Modal isOpen={this.state.imageModal} toggle={this.toggleImage} className="modal-lg" backdrop={true}>
					<ModalHeader toggle={this.toggleImage}>Attach Image</ModalHeader>
					<ModalBody>
						<Row>
							<form className="w-100">
								<Dropzone onFilesAdded={(e) => this.ImageAdded(e)} className="mx-auto" />
							</form>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.toggleImage}>Cancel</Button>{' '}
						<Button color="primary" onClick={this.AddImage}>Add</Button>
					</ModalFooter>
				</Modal>


				<Modal isOpen={this.state.fileModal} toggle={this.toggleFile} className="modal-lg" backdrop={true}>
					<ModalHeader toggle={this.toggleFile}>Attach Document</ModalHeader>
					<ModalBody>
						<Row>
							<form className="w-100">
								<Dropzone onFilesAdded={(e) => this.FileAdded(e)} className="mx-auto" />
							</form>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.toggleFile}>Cancel</Button>{' '}
						<Button color="primary" onClick={this.AddFile}>Add</Button>
					</ModalFooter>
				</Modal>


				<Modal isOpen={this.state.pollModal} toggle={this.togglePoll} className="modal-lg" backdrop={true}>
					<ModalHeader toggle={this.togglePoll}>Create Poll</ModalHeader>
					<ModalBody>
						<Row className="mx-2 mt-1 mb-4">
							<Input type="text" placeholder="Poll Title" id="pollTitle" />
						</Row>
						{this.state.tempPollObj.options.map((option, key) =>
							<Row key={key} className="mx-4 my-1">
								<Col className="pl-0">
									{option}
								</Col>
								<Col xs="auto" className="pr-0">
									<Button color="danger" onClick={() => this.RemoveOption(key)} type="button">X</Button>
								</Col>
							</Row>
						)}
						<Row className="mx-4 my-1">
							<Col className="pl-0">
								<Input type="text" placeholder="Add Option" id="newOption" onKeyPress={this.handleKeyPressOption} />
							</Col>
							<Col xs="auto" className="pr-0">
								<Button color="success" onClick={() => this.AddOption()} type="button">Add</Button>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.togglePoll}>Cancel</Button>{' '}
						<Button color="primary" onClick={this.AddPoll}>Add</Button>
					</ModalFooter>
				</Modal>


				<Modal isOpen={this.state.event.open} toggle={this.toggleEvent} className="modal-lg" backdrop={true}>
					<ModalHeader toggle={this.toggleEvent}>Create Event</ModalHeader>
					<ModalBody>
						<Row className="mx-2 mt-1 mb-4">
							<Input type="text" placeholder="Event Title" id="eventTitle"
								onChange={() => $('#eventTitle').removeClass('is-invalid') } />
						</Row>
						<Row className="mx-2 my-1">
							<ReactQuill value={this.state.event.tempDesc}
								onChange={this.handleChangeEvent}
								modules={this.modules}
								formats={this.formats}
								placeholder="Event Description"
								id="eventDescription"
								className=""
								style={{ display: "Block", minWidth: "100%", marginBottom: "50px" }}
								ref={this.EventQuillRef}
							/>
						</Row>
						<Row className="mx-2 my-1">
							<Col className="p-0 pr-3" >
								<FormGroup>
									<Label for="eventDate">Date</Label>
									<Input
										type="date"
										name="eventDate"
										id="eventDate"
										placeholder=""
										onChange={() => $('#eventDate').removeClass('is-invalid')}
									/>
								</FormGroup>
							</Col>
							<Col className="p-0 pl-3" >
								<FormGroup>
									<Label for="eventTime">Time</Label>
									<Input
										type="time"
										name="eventTime"
										id="eventTime"
										placeholder=""
										onChange={() => $('#eventTime').removeClass('is-invalid')}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className="mx-2 my-1">
							<Col className="p-0 pl-3 ml-auto mr-0" xs="6">
								<FormGroup>
									<Label for="eventEndTime">End Time</Label>
									<Input
										type="time"
										name="eventEndTime"
										id="eventEndTime"
										placeholder=""
										onChange={() => $('#eventEndTime').removeClass('is-invalid')}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className="mx-2 mt-1 mb-4">
							<Input type="text" placeholder="Event Location" id="eventLocation"
								onChange={() => $('#eventLocation').removeClass('is-invalid')}
							/>
						</Row>

					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.toggleEvent}>Cancel</Button>{' '}
						<Button color="primary" onClick={this.AddEvent}>Add</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}

}