import React, { Component } from 'react';
//import { Badge } from './Badge';
import { Row, Col, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormateDate } from "../_helpers"
import { GetBearerToken, GetUser } from "../_helpers"
import { UserLink } from './UserLink';


export default class Poll extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: props.data,
			user: null,
			postId: props.postId,
			responseModal: false
		};

		//console.log(props.data);

		this.AddResponse = this.AddResponse.bind(this);
		this.ToggleResponseModal = this.ToggleResponseModal.bind(this);
	}

	componentDidMount() {
		this.setState({
			user: GetUser()
		});
	}

	async AddResponse(index) {

		if (index === null || this.state.user === null)
			return;

		var data = {
			PostId: this.state.postId,
			OptionId: index
		}


		const response = await fetch("/api/v1/Poll",
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			var data = await response.json();

			console.log(data);

			this.setState({
				data: data
			});
		}
		else {
			console.log(await response);
		}
	}

	ToggleResponseModal() {
		this.setState({
			responseModal: !this.state.responseModal
		});
	}

	render() {
		
		if (this.state.user == null)
			return (<div></div>);

		var seeResponse = null

		if (this.state.user.roles.includes("MODERATOR")) {
			seeResponse = <div>
				<Button outline color="primary" size="sm" className="ml-3 mb-2" onClick={this.ToggleResponseModal}>See Responses</Button>

				<Modal isOpen={this.state.responseModal} toggle={this.ToggleResponseModal} className="">
					<ModalHeader toggle={this.ToggleResponseModal}>Poll Responses</ModalHeader>
					<ModalBody className="pt-0">
						{this.props.data.pollOptions.map((opt, key) =>
							<div key={key}>
								<div className="BorderBottom mt-3">{opt.optionText} - ({opt.numOfResponses})</div>
								{opt.users.map((user, key) =>
									<UserLink history={this.props.history} id={user.userId} underline={true}
										name={user.firstName + " " + user.lastName} key={key} className="ml-2" />
								)}
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.ToggleResponseModal}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		}

		var content = null;

		if (this.props.data !== null && this.props.data.numOfResponses === -1) {
			content = <div>
				<Row>
					<h5>{this.props.data.pollTitle}</h5>{seeResponse}
				</Row>
				{this.props.data.pollOptions.map((opt, key) =>
					<Row key={key}>
						<Button type="button" className="w-100" style={{ display: 'block' }} outline color="secondary"
							onClick={() => this.AddResponse(opt.optionId)}>{opt.optionText}</Button>
					</Row>
				)}
			</div>
		}
		else if (this.props.data !== null) {
			
			content = <div>
				<Row>
					<h5>{this.props.data.pollTitle}</h5>{seeResponse}
				</Row>
				{this.props.data.pollOptions.map((opt, key) =>
					<Row key={key} className="mb-2">
						<Col>
							<div className="ml-2">{opt.optionText}</div>
						</Col>
						<Col xs="auto" className="mr-2 text-secondary">
							{opt.numOfResponses} of {this.props.data.numOfResponses}
						</Col>
						<Progress className="w-100" color="gsi" value={opt.numOfResponses} max={this.props.data.numOfResponses} />
					</Row>
				)}
			</div>
		}
		
		return (
			<Row className="mx-2 my-1 w-95" style={{ display: 'block' }} key={this.props.data.pollId}>
				{content}
			</Row>
		);
	}
};
//minWidth: "200px !important",