import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
//import $ from "jquery";
import './ContentPage.css';
import { GetBearerToken } from "../_helpers"

//import { withRouter } from 'react-router-dom'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons'
//import { FormateDate } from '../_helpers';

//library.add(fas)

// wysiwyg component
// https://github.com/zenoamaro/react-quill
// A wrapper from
// https://quilljs.com/

export class ContentPageView extends Component {
	static displayName = ContentPageView.name;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			text: '',
			AllText: '',
			ViewAll: null
		};
		//const [modal, setModal] = this.setState(false);


		this.ShowMore = this.ShowMore.bind(this)
		this.ShowLess = this.ShowLess.bind(this)
	}

	async componentDidMount() {
		const response = await fetch("/api/v1/ContentPage/" + this.props.page.toLowerCase(),
			{
				method: 'get',
				headers: {
					'Authorization': GetBearerToken()
				}
			});

		const data = await response.json();

		await this.setState({
			loading: false,
			text: data.content,
			AllText: data.content
		});

		if (this.state.text.length > 500)
			this.ShowLess()
	}

	ShowMore() {
		this.setState({
			ViewAll: true,
			text: this.state.AllText
		});
	}

	ShowLess() {
		this.setState({
			ViewAll: false,
			text: this.state.AllText.slice(0, 500)
		});
	}


	render() {
		//console.log({ Page: this.props.page, val: this.state.ViewAll })
		return (
			<div>
				<Col className="bg-white pb-4 pt-3 mb-2">
					<Row>
						<Col>
							<h3>{this.props.page}</h3>
						</Col>
					</Row>
					{this.state.ViewAll === null &&
						<div className="rawHtml" dangerouslySetInnerHTML={{ __html: this.state.text }} />
					}
					{this.state.ViewAll && 
						<div>
							<div className="rawHtml" dangerouslySetInnerHTML={{ __html: this.state.text }} />
							<Button color="link" size="sm" onClick={this.ShowLess} className="pl-0" > Show Less</Button>
						</div>
					}
					{this.state.ViewAll !== null && !this.state.ViewAll &&
						<div>
							<div className="rawHtml" dangerouslySetInnerHTML={{ __html: this.state.text + "...</p>" }} />
							<Button color="link" size="sm" onClick={this.ShowMore} className="pl-0">Show More</Button>
						</div>
					}
				</Col>
			</div>
		);
	}

}
