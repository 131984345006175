import React, { Component } from 'react';
import { Col, Row, Label, Input, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, FormateDate } from "../_helpers"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export class Tags extends Component {
	static displayName = Tags.name;

	constructor(props) {
		super(props);

		this.state = {
			message: "",
			tags: [],
			open: false
		};


		this.AddTag = this.AddTag.bind(this);
		this.EditTag = this.EditTag.bind(this);
		this.DeleteTag = this.DeleteTag.bind(this);
		this.ToggleModel = this.ToggleModel.bind(this);
		this.LaunchEditModal = this.LaunchEditModal.bind(this);

		this.handleKeyPressAdd = this.handleKeyPressAdd.bind(this);
	}

	async componentDidMount() {
		//Loaded

		//Retrive Invite data
		this.GetTags();
	}

	async GetTags() {
		const response = await fetch(`api/v1/Tag`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const data = await response.json();

			this.setState({
				tags: data
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Get Tag failed."
			})
			return;
		}
	}


	async AddTag() {
		var body = {
			Label: $('#NewTag').val(),
			AdminOnly: $('#NewTagAdminOnly').is(':checked')
		}
		$('#NewTag').val("")
		$('#NewTagAdminOnly').prop("checked", false)

		const response = await fetch(`api/v1/Tag`,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const data = await response.json();

			this.setState({
				tags: data
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Add Tag failed."
			})
			return;
		}
	}

	async EditTag() {
		var newLabel = $('#EditModelTextBox').val();
		var id = $('#EditModelId').val();
		var adminOnly = $('#EditModelAdminOnly').is(':checked');

		var body = {
			Label: newLabel,
			AdminOnly: adminOnly
		}

		const response = await fetch(`api/v1/Tag/` + id,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const data = await response.json();

			this.setState({
				tags: data
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Edit Tag failed."
			})
			return;
		}

		await this.setState({
			open: false
		});

	}

	async DeleteTag(id) {
		
		const response = await fetch(`api/v1/Tag/` + id,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const data = await response.json();

			this.setState({
				tags: data
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Delete Tag failed."
			})
			return;
		}
	}

	ToggleModel() {
		this.setState({
			open: !this.state.open
		});
	}

	async LaunchEditModal(id, oldText, adminOnly) {
		await this.setState({
			open: true
		});


		$('#EditModelTextBox').val(oldText);
		$('#EditModelId').val(id);
		$('#EditModelAdminOnly').prop("checked", adminOnly)
	}

	handleKeyPressAdd(e) {
		if (e.key == "Enter") {
			this.AddTag();
		}
	}

	render() {
		
		return (
			<Row>
				<Col>
					<Row>
						<h2>Tags</h2>
					</Row>
					<Row>
						{this.state.message &&
							<div className="alert alert-danger">{this.state.message}</div>
						}
					</Row>
					<Row className="mb-2">
						<Col xs="3" className="px-0">
							<Input type="text" id="NewTag" size="sm" className="w-100 ml-0 mr-auto"
								placeholder="Add new tag" onKeyDown={this.handleKeyPressAdd} />
						</Col>
						<Col xs="auto" className="px-0 pt-2 pl-2">
							<FormGroup check inline>
								<Label check>
									<Input type="checkbox" id="NewTagAdminOnly" /> Admin Only
								</Label>
							</FormGroup>
						</Col>
						<Col className="pl-1 ml-1">
							<Button type="button" color="primary" size="sm" className="" onClick={this.AddTag}>Add</Button>
						</Col>
					</Row>
					<Row>
						<Table striped style={{ width: "100%" }}>
							<thead>
								<tr>
									<th>Tag Label</th>
									<th style={{ width: "5%" }}>Edit</th>
									<th style={{ width: "5%" }}>Remove</th>
								</tr>
							</thead>
							<tbody>
								{this.state.tags.map((tag, key) =>
									<tr>
										<td className="py-2">
											{tag.label + " "} {tag.admin_Only && <span>(Admin Only)</span>}
										</td>
										<td className="py-2" xs="auto" style={{ textAlign: 'center' }} >
											<FontAwesomeIcon icon="pen" className="gsi-blue"
												onClick={() => this.LaunchEditModal(tag.tag_Id, tag.label, tag.admin_Only)} />
										</td>
										<td className="py-2" xs="auto" style={{ textAlign: 'center' }} >
											<FontAwesomeIcon icon="trash-alt" color="red" className="mx-auto"
												onClick={() => this.DeleteTag(tag.tag_Id)} />
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Row>
					<Modal isOpen={this.state.open} toggle={this.ToggleModel} className="">
						<ModalHeader toggle={this.ToggleModel}>Edit tag</ModalHeader>
						<ModalBody>
							<Label>
								Change the Tag label to
							</Label>
							<Input type="text" id="EditModelTextBox" />
							<FormGroup check inline className="pl-2 pt-3">
								<Label check>
									<Input type="checkbox" id="EditModelAdminOnly" /> Admin Only
								</Label>
							</FormGroup>
							<Input type="number" id="EditModelId" hidden />
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" onClick={this.ToggleModel}>Cancel</Button>{' '}
							<Button color="success" onClick={this.EditTag}>Save</Button>
						</ModalFooter>
					</Modal>
				</Col>
			</Row>
		);
	}
}
