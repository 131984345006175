import React, { Component } from 'react';
import './Dropzone.css';
import CloudUpload from '../_images/baseline-cloud_upload-24px.svg';

//https://malcoded.com/posts/react-dropzone/

export class Dropzone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hightlight: false,
            message: 'Upload File'
        }
        this.fileInputRef = React.createRef()

        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog() {
        if (this.props.disabled) return
        this.fileInputRef.current.click()


    }

    onFilesAdded(e) {
        //if (this.props.disabled) return
        ////const files = e.target.files
        //alert('added');
        //if (this.props.onFilesAdded) {
        //    //const array = this.fileListToArray(files)
        //    //this.props.onFilesAdded(array)
        //    console.log(e);
        //    let form = new FormData();
        //    for (var index = 0; index < e.file; index++) {
        //        var element = e.file[index];
        //        form.append('file', element);
        //    }
        //    this.props.onFilesAdded(form)

        //    this.setState({
        //        message: "File Added"
        //    });
        //}
        if (this.props.disabled) return
        const files = e.target.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        
    }

    onDragOver(evt) {
        evt.preventDefault()

        if (this.props.disabled) return

        this.setState({ hightlight: true })
    }

    onDragLeave() {
        this.setState({ hightlight: false })
    }

    onDrop(event) {
        event.preventDefault()

        if (this.props.disabled) return

        const files = event.dataTransfer.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        this.setState({ hightlight: false })
        this.setState({
            message: "File Added"
        });
    }

    fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }
    /*
     
     */
    render() {
        return (
            <div
                className={`Dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
            >
                <input
                    ref={this.fileInputRef}
                    className="FileInput"
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                />
                <img
                    alt="upload"
                    className="Icon"
                    src={CloudUpload}
                />
                <span>{this.state.message}</span>
            </div>
        )
    }
}

//export default Dropzone