

export function MonthName(month) {
	switch (month) {
		case 1:
			return 'Jan';
		case 2:
			return 'Feb';
		case 3:
			return 'Mar';
		case 4:
			return 'Apr';
		case 5:
			return 'May';
		case 6:
			return 'Jun';
		case 7:
			return 'Jul';
		case 8:
			return 'Aug';
		case 9:
			return 'Sep';
		case 10: ;
			return 'Oct';
		case 11:
			return 'Nov';
		case 12:
			return 'Dec';
		default:
			return 'MMM';
	}
}

export function FormateDate(dateTime, log = false) {
	//console.log(dateTime)
	let input = {
		id: "input",
		year: Number(dateTime.substring(0, 4)),
		month: Number(dateTime.substring(5, 7)),
		day: Number(dateTime.substring(8, 10)),

		hour: Number(dateTime.substring(11, 13)),
		min: Number(dateTime.substring(14, 16))
	}
	var d = new Date();
	var newTime = d.getTime() - d.getTimezoneOffset() * 1000 * 60;
	var offsetDate = new Date(newTime);
	var dateTimeCurrent = offsetDate.toISOString().substring(0, 16);
	let current = {
		id: "current",
		year: Number(dateTimeCurrent.substring(0, 4)),
		month: Number(dateTimeCurrent.substring(5, 7)),
		day: Number(dateTimeCurrent.substring(8, 10)),

		hour: Number(dateTimeCurrent.substring(11, 13)),
		min: Number(dateTimeCurrent.substring(14, 16))
	}
	var output = "";
	if (log) {
		console.log("==============")
		console.log(dateTime);
		console.log(dateTimeCurrent);
		console.log(input);
		console.log(current);

	}

	if (input.year === current.year && input.month === current.month && input.day === current.day) {
		if (input.hour === current.hour && input.min === current.min)
			output = "Now";
		else if (input.hour === current.hour && input.min + 1 === current.min)
			output = "1 minute ago";
		else if (input.hour === current.hour && input.min + 2 === current.min)
			output = "2 minutes ago";
		else if (input.hour === current.hour && input.min + 3 === current.min)
			output = "3 minutes ago";
		else if (input.hour === current.hour && input.min + 4 === current.min)
			output = "4 minutes ago";
		else if (input.hour === current.hour && input.min + 5 === current.min)
			output = "5 minutes ago";
		else if (input.hour === current.hour && input.min + 10 >= current.min)
			output = "10 minutes ago";
		else if (input.hour === current.hour && input.min + 15 >= current.min)
			output = "15 minutes ago";
		else if (input.hour === current.hour && input.min + 20 >= current.min)
			output = "20 minutes ago";
		else if (input.hour === current.hour && input.min + 25 >= current.min)
			output = "25 minutes ago";
		else if (input.hour === current.hour && input.min + 30 >= current.min)
			output = "30 minutes ago";
		else if (input.hour + 1 === current.hour || input.hour === current.hour)
			output = "1 hour ago";
		else {
			var i = 2;
			for (i = 2; i < 25; i++) {
				if ((input.hour + i) % 24 === current.hour) {
					output = i + " hours ago";
				}
			}
		}
	}
	else if (input.year === current.year) {
		if (input.month === current.month && input.day + 1 === current.day)
			output = "Yesterday";
		else if (input.month === current.month && input.day + 2 === current.day)
			output = "2 days ago";
		else if (input.month === current.month && input.day + 3 === current.day)
			output = "3 days ago";
		else if (input.month === current.month && input.day + 4 === current.day)
			output = "4 days ago";
		else if (input.month === current.month && input.day + 5 === current.day)
			output = "5 days ago";
		else
			output = MonthName(input.month) + " " + input.day;
	}
	else {
		output = MonthName(input.month) + " " + input.day + ", " + input.year;
    }
		

	//console.log(output);
	return output;
}

export function FullDate(dateTime, withWeekday = false) {
	//console.log(dateTime)
	if (dateTime == undefined)
		return "";

	let input = {
		id: "input",
		year: Number(dateTime.substring(0, 4)),
		month: Number(dateTime.substring(5, 7)),
		day: Number(dateTime.substring(8, 10)),

		hour: Number(dateTime.substring(11, 13)),
		min: dateTime.substring(14, 16)
	}
	
	var output = MonthName(input.month) + " " + input.day + " " + input.year;

	if (withWeekday) {
		var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		return weekday[(new Date(dateTime)).getDay()] + ", " + months[input.month - 1] + " " + input.day + ", " + input.year;
	}

	//console.log(output);
	return output;
}

export function FullTime(dateTime) {
	if (dateTime == undefined)
		return "";

	let input = {
		id: "input",
		year: Number(dateTime.substring(0, 4)),
		month: Number(dateTime.substring(5, 7)),
		day: Number(dateTime.substring(8, 10)),

		hour: Number(dateTime.substring(11, 13)),
		min: dateTime.substring(14, 16)
	}

	return (input.hour % 12).toString().replace(/^0+/, '') + ":"
			+ input.min + " " + (input.hour > 12 ? "PM" : "AM");
}

export function DaysSince(dateTime, log = false) {
	//console.log(dateTime)
	let input = {
		id: "input",
		year: Number(dateTime.substring(0, 4)),
		month: Number(dateTime.substring(5, 7)),
		day: Number(dateTime.substring(8, 10)),

		hour: Number(dateTime.substring(11, 13)),
		min: Number(dateTime.substring(14, 16))
	}
	var inputDate = new Date(dateTime)

	var d = new Date();
	var newTime = d.getTime() - d.getTimezoneOffset() * 1000 * 60;
	var currentDate = new Date(newTime);

	var time_diff = currentDate.getTime() - inputDate.getTime();
	var day_diff = time_diff / (1000 * 3600 * 24);

	var DaysSince = Math.abs(day_diff.toFixed(0));

	var output = "";
	if (DaysSince <= 30)
		output = DaysSince + " days";
	else
		output = (DaysSince / 30).toFixed(0) + " months";	

	//console.log(output);
	return output;
}