import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { ContentPage } from '../ContentPage/ContentPage';


export class ContentPages extends Component {
	static displayName = ContentPage.name;

	constructor(props) {
		super(props);

		this.state = {
			page: ''
		};


		//this.FunctionName = this.FunctionName.bind(this)

	}


	render() {

		return (
			<Row>
				<Col>
					<Row>
						<h2>Administration</h2>
					</Row>
					<Row>
						<Col xs='12' className="border my-2">
							<ContentPage page="About" />
						</Col>
					</Row>
					<Row>
						<Col xs='12' className="border my-2">
							<ContentPage page="Rules" />
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

}
