import React, { Component } from 'react';
import './UserList.css'

export class UserListLink extends Component {

	constructor(props) {
		super(props);

		//this.state = {
		//	name: "name here",
		//	id: 23
		//}

		this.state = {
			name: this.props.value._tail.array[0],
			id: this.props.value._tail.array[1]
		}

		this.Link = this.Link.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			name: nextProps.value._tail.array[0],
			id: nextProps.value._tail.array[1]
		});

	}

	Link() {
		this.props.history.push("/User/" + this.state.id);
	}

	render() {
		//console.log(this.props);
		//console.log(this.props.value);
		//console.log(this.props.value._tail.array[0]);
		//console.log(this.props.value._tail.array[1]);

		return <span onClick={this.Link} className="UserNameInMemberList" style={{ cursor: 'pointer', textDecoration: 'underline' }}>{this.state.name}</span>
	}
}