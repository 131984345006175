import React, { Component } from 'react';

import { Col, Row, Button, Navbar, Container, NavbarBrand, Input, NavLink } from 'reactstrap';
import $ from "jquery";
import '../_components/NavMenu.css';
import gsi_logo from '../_images/gsi_logo_inverse.png';
import sha256 from 'js-sha256'

import { Link } from 'react-router-dom'


export class LogInPage extends Component {
	static displayName = LogInPage.name;

	constructor(props, history) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			loggedIn: false,
			user: null,
			message: null,
			capsLock: false,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.LogIn = this.LogIn.bind(this);
		this.Forgot = this.Forgot.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {
		//Loaded
	}

	handleKeyPress(e) {
		if (e.key === "Enter") {
			this.LogIn();
		}

		this.setState({
			capsLock: e.getModifierState("CapsLock")
		});
	}

	async LogIn() {
		var password = sha256($('Input[name=password]').val());
		
		//console.log(password);
		var Credentials = {
			Email: $('Input[name=email]').val(),
			Password: password
		}

		//Call server to authenticate
		const response = await fetch("/api/v1/user/authenticate",
			{
				method: 'post',
				body: JSON.stringify(Credentials),
				headers: {
					'Content-Type': 'application/json'
				}
			});

		console.log(await response.status);
		if (await response.ok) {
			let user = await response.json();
			//let user = {
			//	badges: [],
			//	firstName: "Victoria",
			//	lastName: "Todd",
			//	profilePicturePath: "",
			//	userId: 2
			//}
			console.log(user);

			//Store JWT in Cookie
			document.cookie = `currentUser=${JSON.stringify(user)}; path=/;`;

			//Set user in App
			this.props.updateLoginCallback(user, true)

			this.setState({ message: null })

		}
		else {
			console.log(response);
			let message = "Login failed. Check credentials or contact Granville Software Inc."
			this.setState({ message: message })
		}
	}

	Forgot() {
		this.props.history.push('/Forgot')
	}

	render() {
		let message = "";
		if (this.state.message != null) {
			message = <div className="alert alert-danger">{this.state.message}</div>
		}

		let capsMessage = "";
		if (this.state.capsLock) {
			capsMessage = <div className="alert alert-danger">Caps lock is on</div>
		}

		//console.log(this.state);
		return (
			<div>
				<Col xs={this.state.mobile ? "12" : "8"} className="mx-auto mt-5 mb-2 py-2">
					<p style={{ textAlign: 'center', fontSize: "120%" }}>Join the conversation in our G<span className="small-caps">sinq</span> user community to meet other users and contribute to future G<span className="small-caps">sinq</span> development.</p>
				</Col>
				<Col xs={this.state.mobile ? "12" : "4"} className="mx-auto bg-white mt-2 py-2">
					<h3>Log in</h3>
					<form>
						<Input type="email" name="email" placeholder="Email" className="my-1" onKeyPress={this.handleKeyPress} autoFocus />
						<Input type="password" name="password" placeholder="Password" className="my-2" onKeyPress={this.handleKeyPress} />
						<Row xs="12" className="px-3">
							<Button onClick={this.LogIn} className="my-2 bg-success btn-sm " style={{ display: 'inline-block', width: '47%' }} >Log in</Button>
							<Button onClick={this.Forgot} className="my-2 bg-gsi btn-sm ml-auto mr-0" style={{ display: 'inline-block', width: '47%' }} >Forgot Password</Button>
						</Row>
							
						{capsMessage}
						{message}
					</form>
				</Col>
				<Col xs={this.state.mobile ? "12" : "4"} className="mx-auto mt-2 py-2" style={{ fontSize: "120%"}}>
					<p>If you have not registered for G<span className="small-caps">sinq</span>Connect, please contact Granville Software Inc.</p>
					<p>780-439-7637<span style={{ float: "right" }}>support@gsinq.com</span></p>
				</Col>
			</div>
		);
	}

}
/*
					<Col xs="6" className="mx-auto bg-white mt-5 py-4">
						<h1>Account</h1>
						<ul>
							<li>Victoria@pbconsultancy.com</li>
							<li>Jay@juris.com</li>
							<li>Jflowers@SullivanRothman.ca</li>
							<li>AmandaPeters@TriumphusLegal.com <b>Administrator</b></li>
							<li>Tiffany@granvillesoftware.com <b>Administrator</b></li>
							<li>Joleen@granvillesoftware.com <b>Administrator</b></li>
						</ul>
					</Col>
*/