import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand, NavLink, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import gsi_logo from '../_images/gsi_logo_inverse.png';
import { NotificationCenter } from './NotificationCenter.js';
import $ from "jquery";
import { GetUser } from "../_helpers"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export class NavMenu extends Component {
	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			user: null,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.LogOut = this.LogOut.bind(this);
		this.SubmitSearch = this.SubmitSearch.bind(this);
		this.KeyPress = this.KeyPress.bind(this);
		this.LogoClick = this.LogoClick.bind(this);
		//this.searchPage = this.searchPage.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	componentDidMount() {
		this.setState({
			user: GetUser()
		});
	}

	async LogOut() {
		//alert("Logged out fired");
		//Delete Cookie
		document.cookie = 'currentUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';

		//Remove user account
		let user = {}

		this.props.updateLogOutCallback(user, false);
	}

	SubmitSearch() {
		var query = ""
		if ($('#searchText').length) {
			query = $('#searchText').val();
			query = query.replace(/([^\w])/g, "_");
		}

		this.props.history.push("/search/" + query);

	}


	KeyPress(e) {
		if (e.key === "Enter") {
			this.SubmitSearch();
		}
	}

	LogoClick() {
		//console.log(this.props.history);

		if (this.props.history.location.pathname === '/') {
			window.location.reload();
			window.scrollTo(0, 0);
		}
		else {
			this.props.history.push("/");
		}
	}

	render() {
		//const [dropdownOpen, setDropdownOpen] = useState(false);

		//const toggle = () => setDropdownOpen(!dropdownOpen);


		//consider "Custom Dropdown" on https://reactstrap.github.io/components/dropdowns/#app for the notification.

		const user = this.state.user;

		var notification = null;

		//console.log(user);
		if (user !== null) {
			notification = <NotificationCenter user={this.state.user} history={this.props.history} />
		}

		return (
			<header>
				<Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 fixed-top bg-gsi py-0" light>
					<Container fluid={true} px="5">
						<Col  className="pl-0" style={{ minWidth: "280px" }} >
							<NavbarBrand onClick={this.LogoClick} style={{ cursor: 'pointer' }} className="titleFont">
								<img src={gsi_logo} alt="" height="25" width="25" className="mt-0 mb-2 mr-2" />
								G<span style={{ fontSize: "70%" }}>SINQ</span>Connect
								<sup style={{ fontSize: "0.65rem", top: "-1.2em" }}>TM</sup>
						</NavbarBrand>
						</Col>
						{!this.state.mobile && !this.state.med &&
							<Col xs="3" className="pr-1 pl-2">
								<input className="form-control form-control-sm mr-sm-2" type="search" id="searchText" placeholder="Search"
									aria-label="Search" onKeyDown={this.KeyPress} />
							</Col>
						}
						{!this.state.mobile && !this.state.med &&
							<Col className="pl-0">
								<button className="btn btn-sm btn-success my-2 my-sm-0" type="button" onClick={this.SubmitSearch}>Search</button>
							</Col>
						}
						
						{!this.state.mobile &&
							<Col xs="auto" className="ml-auto mr-0">
								{notification}
							</Col>
						}
						{this.state.med &&
							<Col xs="auto" className="mx-0">
								<FontAwesomeIcon icon="search" color="white" className="mt-0" style={{
									position: "relative",
									display: "block"
								}} onClick={this.SubmitSearch} />
							</Col>
						}
						{!this.state.mobile &&
							<Col xs="2" className="mr-0 ml-auto">
								<NavLink onClick={this.LogOut} style={{ cursor: 'pointer', fontSize: '1.2rem', width: 'min-content' }} className="text-white" >Logout</NavLink>
							</Col>
						}
						{this.state.mobile &&
							<Col xs="auto" className="mx-0">
								<FontAwesomeIcon icon="bars" color="white" className="mt-0" style={{
										position: "relative",
										display: "block"
								}} onClick={() => this.props.menuCallback(!this.props.meunOpen)} />
							</Col>
						}

					</Container>
				</Navbar>
			</header>
		);
	}
}



/*
static displayName = NavMenu.name;

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}
<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
	<ul className="navbar-nav flex-grow">
		<NavItem>
			<NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
		</NavItem>
		<NavItem>
			<NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
		</NavItem>
		<NavItem>
			<NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
		</NavItem>
	</ul>
</Collapse>
 */