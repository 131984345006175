import React, { Component } from 'react';
import {
	Col, Button, Navbar, Container, NavbarBrand, Input, Row, FormGroup,
	Label, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import $ from "jquery";
import '../_components/NavMenu.css';
import gsi_logo from '../_images/gsi_logo_inverse.png';
import sha256 from 'js-sha256'

import { Link } from 'react-router-dom'


export class Forgot extends Component {
	static displayName = Forgot.name;

	constructor(props) {
		super(props);

		this.state = {
			user: null,
			message: null,
			modal: false,
			rules: null
		};

		this.Submit = this.Submit.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	async componentDidMount() {
		//Loaded

	}


	handleKeyPress(e) {
		if (e.key === "Enter") {
			this.Submit();
		}
	}

	async Submit() {
		//alert("Register");

		$('#ResetEmailBtn').prop('disabled', true);

		var RegesterObj = {
			text: $('Input[name=Email]').val()
		}

		//Call server to Register
		const response = await fetch("/api/v1/user/reset",
			{
				method: 'post',
				body: JSON.stringify(RegesterObj),
				headers: {
					'Content-Type': 'application/json'
				}
			});

		//console.log(await response.status);
		if (await response.ok) {

			this.setState({
				messageColor: "alert-success",
				message: "Reset link sent."
			});

			//Redirect to home
			//this.props.history.push('/');

		}
		else {
			console.log(response.status);
			console.log(response.statusText);
			this.setState({
				messageColor: "alert-danger",
				message: "User not found. Please contact Granville Software Inc."
			});
		}

		await new Promise(r => setTimeout(r, 500));
		$('#ResetEmailBtn').prop('disabled', false);
	}


	render() {
		let message = "";
		if (this.state.message != null) {
			message = <div className={"alert " + this.state.messageColor}>{this.state.message}</div>
		}
		//console.log(this.state);
		return (
			<div>
				<Col xs="4" className="mx-auto bg-white mt-5 py-2 mx-2 px-2">
					<Row className="mb-3">
						<h4 className="mx-auto">
							Enter your email to reset your password
						</h4>
					</Row>
					<Row className="mb-2 pb-2 w-100">
						<Col>
							<Row className="ml-3 my-2 w-100">
								<FormGroup className="w-100">
									<Label for="Email">Email*</Label>
									<Input type="email" name="Email" id="Email" placeholder="" required={true}
										tabIndex="10" onKeyPress={this.handleKeyPress} />
								</FormGroup>
							</Row>
							<Row className="my-auto">
								<Button type="button" className="my-auto mx-auto bg-gsi w-50" style={{ display: 'block' }} tabIndex="30" id="ResetEmailBtn" onClick={this.Submit} >Send Reset Email</Button>
							</Row>
						</Col>
					</Row>

					{message}

				</Col>
			</div>
		);
	}

}
