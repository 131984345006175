import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

export class Main extends Component {
	static displayName = Main.name;

	constructor(props) {
		super(props);

		this.state = {
			page: ''
		};


		//this.FunctionName = this.FunctionName.bind(this)

	}


	render() {

		return (
			<Row>
				<Col>
					<Row>
						<h2>Main</h2>
					</Row>
					<Row>
						<p className="mt-3">
							Use the links on the left to
						</p>
					</Row>
					<Row>
						<ul>
							<li>
								Edit the <strong>Content Pages</strong> (About and Rules)
							</li>
							<li>
								<strong>Invite</strong> new users to the group
							</li>
							<li>
								Add or remove <strong>Tags</strong>
							</li>
						</ul>
					</Row>
				</Col>
			</Row>
		);
	}

}
