import React, { Component } from 'react';
import { Col, Row, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, GetUser, FormateDate, FullDate, DaysSince } from "../_helpers"
import { InviteLink } from "./InviteLink";
import "./InvitePage.css"

import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class Invite extends Component {
	static displayName = Invite.name;

	constructor(props) {
		super(props);

		//old - https://localhost:5001/register/
		//http://gsinqconnect.ca-central-1.elasticbeanstalk.com/
		this.state = {
			links: [],
			data: [],
			inviteNumber: 0,
			user: null,
			message: null,
			linkBase: 'https://gsinqconnect.com/register/',
			InviteModal: false
		};
		//GUID is 32


		this.GenerateLink = this.GenerateLink.bind(this);
		this.ToggleInvite = this.ToggleInvite.bind(this);
		this.FilterEvent = this.FilterEvent.bind(this);
		this.CancelInvite = this.CancelInvite.bind(this);
	}

	async componentDidMount() {
		//Loaded

		//Retrive Invite data
		this.RetriveInviteLinks();

		this.setState({
			user: GetUser()
		});
	}

	async ReceiveInvites(response) {
		if (await response.ok) {
			const links = await response.json();

			var data = [];

			for (var i = 0; i < links.length; i++) {
				var temp = {
					Email: links[i].email,
					Name: links[i].firstName + ' ' + links[i].lastName,
					Link: this.state.linkBase + links[i].key,
					GeneratedAt: FullDate(links[i].invitedDate),
					DaysSince: DaysSince(links[i].invitedDate),
					Status: (links[i].registeredUser === null) ? 'Sent' : 'Registered',
					Key: links[i].key
				}
				data.push(temp);
			}
			this.setState({
				links: links,
				data: data,
				inviteNumber: data.length
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Get Invitations failed."
			})
			return;
		}
	}

	async RetriveInviteLinks() {
		const response = await fetch(`api/v1/invitation`,
			{
				method: 'get',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		this.ReceiveInvites(response);
	}


	async GenerateLink() {
		
		var newUser = {
			FirstName: $("#firstName").val(),
			LastName: $("#lastName").val(),
			Email: $("#newEmail").val(),
			EmailContent: {
				Subject: $("#subjectLine").val(),
				Body: $("#bodyContent").val(),
			}
		}

		$("#SendInviteBtn").attr('disabled', 'disabled');

		const response = await fetch("/api/v1/invitation",
			{
				method: 'POST',
				body: JSON.stringify(newUser),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			const data = await response.text();

			//console.log(data);
			//console.log(await response.text());

			//Old way of pushing links
			//var link = {
			//	email: newUser.Email,
			//	link: this.state.linkBase + data
			//};

			//var temp = this.state.links;
			//temp.push(link);

			//this.setState({
			//	links: temp
			//});
			this.setState({
				InviteModal: false
			});

			$('#OpenInviteModal').blur();

			//Retrieve new links
			this.RetriveInviteLinks();
		}
		else {
			console.log(response.status);
			console.log(response.statusText);
			var data = await response.json();
			this.setState({
				message: 'Generate Invite failed: ' + data.Message,
				InviteModal: false
			});
		}
		$("#SendInviteBtn").removeAttr('disabled');
	}

	async CancelInvite(key) {
		const response = await fetch(`api/v1/invitation/` + key,
			{
				method: 'Delete',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		this.ReceiveInvites(response);
	}

	ToggleInvite() {
		this.setState({
			InviteModal: !this.state.InviteModal
		});
		$('#OpenInviteModal').blur();
	}

	FilterEvent(e) {
		setTimeout(() => {
			this.setState({
				inviteNumber: $(".griddle-table-body tr").length
			});
		}, 200);
	}

	sortByDate(data, column, sortAscending) {

		var sort = data.sort(function (a, b) {
			if (new Date(b.get('GeneratedAt')) === new Date(a.get('GeneratedAt'))) {
				return 0;
			} else if (new Date(a.get('GeneratedAt')) > new Date(b.get('GeneratedAt'))) {
				return sortAscending ? 1 : -1;
			}
			else {
				return sortAscending ? -1 : 1;
			}
		});
		return sort;
	}

	render() {
		const NewLayout = ({ Table, Pagination, Filter }) => (
			<div className="w-100">
				<Row>
					<Col>
						<Filter />
					</Col>
				</Row>
				<Row>
					<Col>
						<Table />
					</Col>
				</Row>
			</div>
		);

		const styleConfig = {
			classNames: {
				TableHeadingCell: 'col',
				Table: 'table table-striped mt-1',
				Filter: 'form-control w-25',
				PageDropdown: 'btn btn-light dropdown-toggle'
			},
			styles: {
				Table: {
					"wordWrap": "break-word",
					"tableLayout": "fixed",
					"width": "100%"
				}
			}
		}

		const link = ({ value }) => <InviteLink data={value} />
		const removeBtn = ({ value }) => <FontAwesomeIcon
			icon="trash-alt"
			color="red"
			className="mx-auto"
			onClick={() => { if (window.confirm('Are you sure you wish to delete this Invitation?')) this.CancelInvite(value) }} />

		//old - https://localhost:5001
		//http://gsinqconnect.ca-central-1.elasticbeanstalk.com

		return (
			<Row>
				<Col>
					<Row>
						<h2>Invite<sup style={{ fontSize: "60%", marginLeft: "5px" }}>{this.state.inviteNumber}</sup></h2>
					</Row>
					<Row className="w-100">
						{this.state.message &&
							<div className="alert alert-danger w-100">{this.state.message}</div>
						}
					</Row>
					
					<Row>
						<Button color="primary" id="OpenInviteModal" onClick={this.ToggleInvite}>Send Invitation</Button>

						<Modal isOpen={this.state.InviteModal} toggle={this.ToggleInvite} size="lg" >
							<ModalHeader toggle={this.ToggleInvite}>Send Invitation</ModalHeader>
							<ModalBody>
								<Col className="px-3">
									<Row className="my-3">
										<Col className="pl-0">
											<Input type="text" name="firstName" id="firstName" placeholder="First Name" />
										</Col>
										<Col className="pr-0">
											<Input type="text" name="lastName" id="lastName" placeholder="Last Name" />
										</Col>
									</Row>
									<Row className="my-3">
										<Input type="email" name="newEmail" id="newEmail" placeholder="New Email" />
									</Row>
									<Row className="my-3">
										<Label>Template Subject Line</Label>
										<Input type="text" name="subjectLine" id="subjectLine" defaultValue="Welcome to GsinqConnect!" />
									</Row>
									<Row className="my-3">
										<Label>Template Invitation Email</Label>
										<Input type="textarea" defaultValue={
										`Hello ::firstname:: ::lastname::,

Use the link below to register for GsinqConnect.

https://gsinqconnect.com/register/::key::

Sincerely,
${this.state.user != null ? this.state.user.firstName : ""} ${this.state.user != null ? this.state.user.lastName : ""}
Granville Software Inc.`} style={{ width: "100%", fontSize: "smaller" }} rows="10" name="bodyContent" id="bodyContent" />
									</Row>
								</Col>
							</ModalBody>
							<ModalFooter>
								<Button color="danger" onClick={this.ToggleInvite}>Cancel</Button>{' '}
								<Button color="primary" onClick={this.GenerateLink} id="SendInviteBtn">Send Invitation</Button>
							</ModalFooter>
						</Modal>

					</Row>
					<Row className="my-4 w-100">
						<Griddle
							data={this.state.data}
							plugins={[plugins.LocalPlugin]}
							styleConfig={styleConfig}
							components={{
								Layout: NewLayout
							}}
							events={{
								onFilter: this.FilterEvent
							}}
							pageProperties={{
								pageSize: 500
							}}
						>
							<RowDefinition>
								<ColumnDefinition id="Email" title="Email" headerCssClassName="wide" />
								<ColumnDefinition id="Name" title="Name" headerCssClassName="medium" />
								<ColumnDefinition id="Link" title="Link" customComponent={link} headerCssClassName="regular" sortable={false} />
								<ColumnDefinition id="GeneratedAt" title="Invitation Sent" headerCssClassName="regular" sortMethod={this.sortByDate} />
								<ColumnDefinition id="DaysSince" title="Days Since" headerCssClassName="regular" sortable={false} />
								<ColumnDefinition id="Key" title="Remove" customComponent={removeBtn} headerCssClassName="regular" sortable={false} />
							</RowDefinition>
						</Griddle>
					</Row>
				</Col>
			</Row>
		);
	}
}

/*
<ColumnDefinition id="Status" title="Status" headerCssClassName="regular" />

<Table size="sm">
							<thead>
								<tr>
									<th>Registerd</th>
									<th style={{ width: "25%" }}>Email</th>
									<th>Link</th>
									<th>Generated</th>
									<th>Generated by</th>
								</tr>
							</thead>
							<tbody>
								{this.state.links != null &&
									this.state.links.map((link, key) =>
										<tr key={key}>
											<td className="d-none">{key}</td>
											<td scope="row">{link.registerdUser === null ? 'No' : 'Yes'}</td>
											<th>{link.email}</th>
											<td><a>{this.state.linkBase + link.key}</a></td>
											<td>{FormateDate(link.invitedDate)}</td>
											<th>{link.invitingUser.firstName + " " + link.invitingUser.lastName}</th>
										</tr>)
								}

							</tbody>
						</Table>

 */
