import React, { Component } from 'react';
import { Post, SidebarProfile, Comment } from "../_components";
import { ContentPageView } from '../ContentPage/ContentPageView';
import { Col, Row, NavLink, FormGroup, Label, Input } from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, GetUser } from "../_helpers"
import { Link } from 'react-router-dom';
import { PublicProfileContent } from '../Profile'

export class SearchPage extends Component {
	static displayName = SearchPage.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			error: false,
			data: null,
			allData: null,
			user: null,
			showPosts: true,
			showComments: true,
			showUsers: true,
			possibleFilters: [],
			med: size == "med",
			mobile: size == "mobile"
		};

		window.onscroll = () => {

		}

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});

		this.UpdateResultType = this.UpdateResultType.bind(this);
		this.UpdateFilter = this.UpdateFilter.bind(this);

		// Search
		this.KeyPressMobile = this.KeyPressMobile.bind(this);
		this.SubmitSearchMobile = this.SubmitSearchMobile.bind(this);
	}

	async componentDidMount() {
		console.log(this.props.match);

		this.setState({
			user: GetUser()
		});

		await this.populateResults();
		await this.populateTags();
	}

	async componentDidUpdate(prevProps) {
		if (this.props.match.params.query != prevProps.match.params.query) {
			//console.log(this.props.match);
			await this.populateResults();
		}
	}

	async populateResults() {
		console.log(this.props)
		if (this.props.match.params.query == "" || this.props.match.params.query == undefined) {
			return
		}
		console.log(this.props.match.params.query)

		var query = this.props.match.params.query.replace(/(_+)/g, " ");
		console.log(this.props.match.params);
		//console.log(this.props);

		console.log("Clear");
		$('#searchText').val("");
		this.setState({
			loading: true,
			data: null,
			allData: null
		});

		const response = await fetch(`api/v1/search/'` + query + "'",
			{
				method: 'get',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});
		//const response = await fetch(`api/v1/post`);
		//console.log(GetBearerToken());
		//alert("here");

		var data = null;

		try {
			data = await response.json();
			if (this.state.mobile || this.state.med) {
				$("#searchTextMobile").val(query)
			}
			else {
				$("#searchText").val(query)
			}
		} catch (e) {
			alert(e); // error in the above string (in this case, yes)!
			return;
		}

		//console.log(data);
		this.setState({
			loading: false,
			data: data,
			allData: data
		});
		//console.log(this.state);
	}

	async populateTags() {
		const response = await fetch('/api/v1/Tag',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (response.ok) {
			const data = await response.json();

			this.setState({
				possibleFilters: data
			});
		}
	}

	UpdateResultType() {
		var posts = true;
		var users = true;
		var comments = true;

		posts = $('#ResultType_post').is(':checked');
		users = $('#ResultType_user').is(':checked');
		comments = $('#ResultType_comment').is(':checked');

		this.setState({
			showPosts: posts,
			showUsers: users,
			showComments: comments
		});
	}
	
	async UpdateFilter() {
		var NewFilter = [];

		for (var i = 0; i < this.state.possibleFilters.length; i++) {
			var temp = null;

			if ($('#filterOption_' + this.state.possibleFilters[i].tag_Id).is(':checked'))
				temp = this.state.possibleFilters[i].label;

			if (temp !== null && temp !== undefined)
				NewFilter.push(temp);
		}
		//console.log(NewFilter);

		if (NewFilter.length == 0) {
			await this.setState({
				data: this.state.allData
			});
			//console.log(this.state.allData);
			return;
		}

		var data = {
			posts: [],
			users: this.state.allData.users.slice(),
			comments: this.state.allData.comments.slice()
		}
		//data.posts = [];
		//console.log(this.state.allData);

		for (var i = 0; i < this.state.allData.posts.length; i++) {
			var temp = this.state.allData.posts[i].tags.filter(value => NewFilter.includes(value));
			//console.log(temp);

			if (temp.length > 0)
				data.posts.push(this.state.allData.posts[i]);

		}

		this.setState({
			data: data
		});

	}

	SubmitSearchMobile() {
		var query = ""
		if ($('#searchTextMobile').length) {
			query = $('#searchTextMobile').val();
			query = query.replace(/([^\w])/g, "_");
		}

		this.props.history.push("/search/" + query);
	}

	KeyPressMobile(e) {
		if (e.key === "Enter") {
			this.SubmitSearchMobile();
		}
	}

	render() {
		var results = null

		if (this.state.data !== null) {
			results = <div className="w-100" >
				
				<Row className="py-2 px-3 my-1 mx-0 bg-white" onClick={this.UpdateResultType}>
					<p className="text-secondary mb-0" style={{ width: '98%' }} >Result Types</p>
					<FormGroup check inline >
						<Label check>
							<Input type="checkbox" id="ResultType_post" value="post" defaultChecked /> Posts
						</Label>
					</FormGroup>
					<FormGroup check inline >
						<Label check>
							<Input type="checkbox" id="ResultType_user" value="user" defaultChecked /> Users
						</Label>
					</FormGroup>
					<FormGroup check inline >
						<Label check>
							<Input type="checkbox" id="ResultType_comment" value="comment" defaultChecked /> Comments
						</Label>
					</FormGroup>
				</Row>
				<Row className="py-2 px-3 my-1 mx-0 bg-white" onClick={this.UpdateFilter}>
					<p className="text-secondary mb-0" style={{ width: '98%' }} >Filter</p>
					{this.state.possibleFilters.map((tag, key) =>
						<FormGroup check inline key={key}>
							<Label check>
								<Input type="checkbox" id={"filterOption_" + tag.tag_Id} value={tag.tag_Id} /> {tag.label}
							</Label>
						</FormGroup>
					)}
				</Row>
				{this.state.showPosts && this.state.data.posts.length > 0 &&
					<div className="w-100">
						<h3>Posts</h3>
						{this.state.data.posts.map((post, key) =>
							<Post post={post} key={key} user={this.state.user}
								history={this.props.history} />
						)}
					</div>
				}
				{this.state.showUsers && this.state.data.users.length > 0 &&
					<div className="w-100">
						<h3>Users</h3>
						{this.state.data.users.map((user, key) =>
							<PublicProfileContent user={user} key={key} xs="auto" history={this.props.history} />
						)}
					</div>
				}
				{this.state.showComments && this.state.data.comments.length > 0 &&
					<div className="w-100">
						<h3>Comments</h3>
					{this.state.data.comments.map((comment, key) =>
						<div className="bg-white pl-3 py-0 pr-4 my-2" key={key}>
								<NavLink tag={Link} to={"/post/" + comment.postId}>View full post</NavLink>
								<Comment data={comment} key={key}
									userViewing={this.state.user} ReplyCallback={this.ReplyTo}
									HighlightCallback={this.Highlight} history={this.props.history} />
							</div>
						)}
					</div>
				}
				{!(this.state.showPosts && this.state.data.posts.length > 0) &&
					!(this.state.showUsers && this.state.data.users.length > 0) &&
					!(this.state.showComments && this.state.data.comments.length > 0) &&
					<h3 className="mt-4">No Results</h3>
				}
			</div>

		}

		return (
			<div>
				<Col>
					<Row>
						{!this.state.mobile &&
							<Col xs="3">
								{this.state.user != null &&
									<SidebarProfile user={this.state.user} history={this.props.history} />
								}
								{this.state.med &&
									<ContentPageView page='About' />
								}
								{this.state.med &&
								<	ContentPageView page='Rules' />
								}
							</Col>
						
						}
						<Col className="w-100">
							{(this.state.mobile || this.state.med) && 
							<Row>
								<Col>
									<input className="form-control form-control-sm mr-sm-2" type="search" id="searchTextMobile" placeholder="Search"
										aria-label="Search" onKeyDown={this.KeyPressMobile} />
								</Col>
								<Col className="pl-0" xs="auto">
									<button className="btn btn-sm btn-success my-sm-0" type="button" onClick={this.SubmitSearchMobile}>Search</button>
								</Col>	
							</Row>
							}
							{this.state.data != null &&
								<div>
									<h3>Search Results</h3>
								</div>
							}
							{this.state.loading &&
								<Row>
									<div className="mx-auto">
										<span className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status" aria-hidden="true"></span>
									</div>
								</Row>
							}
							{results}
						</Col>
						{!this.state.mobile && !this.state.med &&
							<Col xs="3" className="ml-auto mr-0">
								<ContentPageView page='About' />
								<ContentPageView page='Rules' />
							</Col>
						}
					</Row>
				</Col>
			</div>
		);
	}

}
