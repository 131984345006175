import React, { Component } from 'react';
import { Post, SidebarProfile } from "../_components";
import {
	Col, Row, Input, Label,	FormGroup
} from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, GetUser } from "../_helpers"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)


export class Events extends Component {
	static displayName = Events.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			events: [],
			user: null,
			filter: ['Upcoming'],
			tags: [],
			possibleTags: [],
			loading: false,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.populateEvents = this.populateEvents.bind(this);
		this.UpdateFilter = this.UpdateFilter.bind(this);

		this.populateTags = this.populateTags.bind(this);
		this.UpdateTags = this.UpdateTags.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}


	async componentDidMount() {

		await this.populateEvents();

		this.setState({
			user: GetUser()
		});

		await this.populateTags();
	}

	//componentDidUpdate() {
	//}

	async populateEvents(history) {

		//var body = {
		//	Filter: this.state.filter
		//};

		console.log(this.state.filter);

		await this.setState({
			loading: true
		});

		var body = {
			tags: this.state.tags,
			timeFrame: this.state.filter
		}
		
		const response = await fetch(`api/v1/Post/GetEvents`,
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		const data = await response.json();

		console.log(data);

		this.setState({
			events: data,
			loading: false
		});

	}

	async UpdateFilter() {
		var NewFilter = [];

		var temp = null;

		if ($('#filterOption_past').is(':checked'))
			NewFilter.push("Past")

		if ($('#filterOption_upcoming').is(':checked'))
			NewFilter.push("Upcoming")

		if (JSON.stringify(NewFilter) !== JSON.stringify(this.state.filter)) {

			await this.setState({
				filter: NewFilter
			});

			this.populateEvents(this.props.history);
		}
	}

	async populateTags() {
		const response = await fetch('/api/v1/Tag',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (response.ok) {
			const data = await response.json();

			this.setState({
				possibleTags: data
			});
		}
	}

	async UpdateTags() {
		var NewFilter = [];

		for (var i = 0; i < this.state.possibleTags.length; i++) {
			var temp = null;

			if ($('#filterOption_' + this.state.possibleTags[i].tag_Id).is(':checked'))
				temp = this.state.possibleTags[i].tag_Id;

			if (temp !== null && temp !== undefined)
				NewFilter.push(temp);
		}

		if (JSON.stringify(NewFilter) !== JSON.stringify(this.state.tags)) {

			await this.setState({
				tags: NewFilter
			});

			this.populateEvents(this.props.history);
		}
	}

	render() {
		return (
			<div>
				<Col>

					<Row>
						{!this.state.mobile &&
							<Col xs="3">
								{this.state.user != null &&
									<SidebarProfile user={this.state.user} history={this.props.history} />
								}
							</Col>
						}
						<Col xs={this.state.mobile ? "12" : (this.state.med ? "9" : "6")} >
							<Row className="bg-white mb-3 py-2 px-4" onClick={this.UpdateFilter}>
								<p className="text-secondary mb-0" style={{ width: '98%' }} >Filter Events by Time</p>
								<FormGroup check inline >
									<Label check>
										<Input type="checkbox" id={"filterOption_upcoming"} value="Upcoming"
											defaultChecked={this.state.filter.includes("Upcoming")} /> Upcoming
									</Label>
								</FormGroup>
								<FormGroup check inline >
									<Label check>
										<Input type="checkbox" id={"filterOption_past"} value="Past"
											defaultChecked={this.state.filter.includes("Past")} /> Past
									</Label>
								</FormGroup>
							</Row>
							{this.state.possibleTags != null &&
								<Row className="bg-white mb-3 py-2 px-4" onClick={this.UpdateTags}>
									<p className="text-secondary mb-0" style={{ width: '98%' }} >Filter Events by Topic Tag(s)</p>
									{this.state.possibleTags.map((tag, key) =>
										<FormGroup check inline key={key}>
											<Label check>
												<Input type="checkbox" id={"filterOption_" + tag.tag_Id} value={tag.tag_Id} /> {tag.label}
											</Label>
										</FormGroup>
									)}
								</Row>
							}
							{this.state.loading &&
								<Row>
									<div className="mx-auto">
										<span className="spinner-grow mx-auto" style={{ width: "3rem", height: "3rem" }} role="status" aria-hidden="true"></span>
									</div>
								</Row>
							}
							<Row >
								{this.state.user != null &&
									<div className="w-100">{this.state.events.map((post, key) =>
										<Post post={post} key={key} user={this.state.user}
											 history={this.props.history} />
									)}</div>
								}
							</Row>
						</Col>
					</Row>
				</Col>
			</div>
		);
	}
}


/*
<Col xs="3">
	<ContentPageView page='About' />
	<ContentPageView page='Rules' />
</Col>

updateNewsfeedCallback={this.populateNewsfeed.bind(this)}

 */