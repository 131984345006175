import React, { Component } from 'react';
//import { Badge } from './Badge';
import { SidebarProfile } from "../_components";
import { ContentPageView } from '../ContentPage/ContentPageView';
import { Row, Col, Button } from 'reactstrap';
import { GetBearerToken, GetUser, FormateDate } from "../_helpers";
import * as signalR from "@microsoft/signalr";
import '../_components/NotificationCenter.css'

export class NotificationsPage extends Component {
	static displayName = NotificationsPage.name;
	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			user: null,
			hubConnection: null,
			notifications: [],
			count: 0,
			open: false,
			med: size == "med",
			mobile: size == "mobile"
		};

		//console.log(props.data);

		this.MarkAsRead = this.MarkAsRead.bind(this);
		this.toggle = this.toggle.bind(this);
		this.ClearAll = this.ClearAll.bind(this);
		this.GetNotifications = this.GetNotifications.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {
		await this.setState({
			user: GetUser()
		});

		this.GetNotifications();
		/*
		const hubConnection = new signalR.HubConnectionBuilder()
			.withUrl("/notificationHub", {
				accessTokenFactory: () => GetBearerToken(false)
			})
			.withAutomaticReconnect()
			.build();

		await this.setState({
			hubConnection: hubConnection
		});
		

		this.state.hubConnection.on('ReceiveMessage', (data) => {
			//console.log("Got anything");
			//console.log(data);


			for (var i = 0; i < this.state.notifications.length; i++) {
				if (data.notification_id == this.state.notifications[i].notification_id)
					return;
			}

			var temp = [data];
			temp = temp.concat(this.state.notifications);

			var count = 0;
			for (var i = 0; i < temp.length; ++i) {
				if (!temp[i].read)
					count++;
			}

			this.setState({
				notifications: temp,
				count: count
			});
			//console.log(temp);
		});
		
		this.state.hubConnection
			.start()
			.then(() => console.log('Connection started!'))
			.catch(err => console.log('Error while establishing connection :('));
		*/
		

		//let connection = new signalR.HubConnectionBuilder()
		//	.withUrl("/notificationHub", {
		//		accessTokenFactory: () => GetBearerToken(false)
		//	})
		//	.build();


		//connection.on("receiveMessage", data => {
		//	console.log(data);
		//});

		//connection.start();
		//	.then(() => connection.invoke("send", "Hello"));
	}

	async MarkAsRead(notificationId, link = '/', redirect = true) {
		//this.state.hubConnection.invoke("MarkAsRead", notificationId)
		//	.catch(err => console.error(err));
		//console.log({ notificationId, link });

		const response = await fetch("/api/v1/Notification/" + notificationId,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			var notifications = this.state.notifications;
			var count = 0;

			for (var i = 0; i < notifications.length; i++) {

				if (notifications[i].notification_id === notificationId) {
					notifications[i].read = true;
				}

				if (!notifications[i].read)
					count++;
			}

			await this.setState({
				notifications: notifications,
				count: count,
				open: false
			});

			if (redirect)
				this.props.history.push(link);
		}

		//window.location.assign(link); 
	}

	async GetNotifications() {
		const response = await fetch("/api/v1/Notification",
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {

			var data = await response.json();
			this.setState({
				notifications: data
			})
		}
	}

	ClearAll() {
		for (var i = 0; i < this.state.notifications.length; i++) {
			this.MarkAsRead(this.state.notifications[i].notification_id, '', false);
		}
	}

	toggle() {
		this.setState({
			open: !this.state.open
		});
	}

	render() {

		var results = <Col>
			{this.state.notifications.length > 0 &&
					<Row>
						<h4 className="ml-3 mt-1 pr-3">Notification Center</h4>
						<Button type="button" onClick={this.ClearAll} outline size="sm" className="ml-auto mr-1" >
							Mark All as Read
						</Button>
					</Row>
			}
			{this.state.notifications.map((n, key) =>
					<Row onClick={() => this.MarkAsRead(n.notification_id, n.link)}
						className={"notification my-2 " + (n.read === true ? "notification-read" : "notification-unread")}
						style={{
							borderRadius: "5px",
							cursor: 'pointer'
						}} key={key}
					>
						<Col>
							{n.text}
						</Col>
						<Col xs="auto">
							<span style={{
								fontSize: "70%",
								color: "grey"
							}}>
								{FormateDate(n.timeStamp)}
							</span>
						</Col>
					</Row>
				)
			}
			{this.state.notifications.length < 1 &&
				<p>No new notifications</p>
			}
		</Col>

		return (
			<div>
				<Col>
					<Row>
						{!this.state.mobile &&
							<Col xs="3">
								{this.state.user != null &&
									<SidebarProfile user={this.state.user} history={this.props.history} />
								}
							</Col>
						}
						<Col xs={this.state.mobile ? "12" : (this.state.med ? "9" : "6")}>
							<Row className="bg-white px-2 py-4">
								{results}
							</Row>
						</Col>
					</Row>
				</Col>
			</div>
		);
	}
};
//minWidth: "200px !important",