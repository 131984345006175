import React, { Component } from 'react';
import { Button, UncontrolledTooltip  } from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard';

//import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons'


export class InviteLink extends Component {

	constructor(props) {
		super(props);

		var id = this.props.data.replace(/[\/\.]/g, '').replace(/:/g, "");

		this.state = {
			id: id
		};

		this.CopyLink = this.CopyLink.bind(this);
	}

	CopyLink() {
		copy(this.props.data);
	}


	render() {
		//console.log(this.props);
		//console.log(this.state);
		//alert("");

		return <div>
			<Button color="link" onClick={() => { this.CopyLink() }} id={this.state.id} className="p-0"
				style={{ cursor: 'copy' }}>
				Copy Link <FontAwesomeIcon icon="link" color="black" size="xs" />
			</Button>
			<UncontrolledTooltip placement="top" target={this.state.id}>
				Click to Copy {this.props.data}
			</UncontrolledTooltip>
		</div>
	}
}