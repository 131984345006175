import React, { Component } from 'react';
import { SidebarProfile } from "../_components";
//import { Dropzone } from "../_components";
import { Col, Row, Button, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from "jquery";
//import { FormateDate } from "../_helpers"
import { GetBearerToken, GetUser } from "../_helpers"
import './Profile.css'
import { PublicProfileContent } from './PublicProfileContent';


export class PublicProfile extends Component {
	static displayName = PublicProfile.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			user: null,
			userViewing: null,
			RemovalOpen: false,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.ToggleRemove = this.ToggleRemove.bind(this);
		this.RemoveUser = this.RemoveUser.bind(this);

		this.MakeAdmin = this.MakeAdmin.bind(this);
		this.RemoveAdmin = this.RemoveAdmin.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {

		await this.setState({
			userViewing: GetUser()
		});

		if (this.state.userViewing !== null)
			this.RetrieveProfile();

	}

	async RetrieveProfile() {
		const response = await fetch("/api/v1/user/" + this.props.match.params.userId,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			//console.log(response.statusText);
			const user = await response.json();
			
			this.setState({
				user: user
			});

		}
		else {
			console.log(response);
		}
	}

	ToggleRemove() {
		this.setState({
			RemovalOpen: !this.state.RemovalOpen
		});
	}

	async RemoveUser() {
		if (this.state.userViewing === null)
			return;

		var data = {
			userId: this.state.user.userId,
			bool: $("[name=RemoveName]:checked").val()
		}

		const response = await fetch("/api/v1/user/RemoveUser",
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		if (await response.ok) {
			this.setState({
				RemovalOpen: false
			});
			this.props.history.push("/Members");
		}
		else {
			console.log(response);
		}
	}

	async MakeAdmin() {
		//console.log("Make Administrator");

		if (!(this.state.userViewing.roles.includes("MODERATOR") || this.state.userViewing.roles.includes("ADMIN")))
			return;

		var body = {
			userId: this.state.user.userId,
			role: 'MODERATOR'
		}

		const response = await fetch("/api/v1/user/role",
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {

			this.RetrieveProfile();
		}
		else {
			console.log(response);
			return;
		}
	}

	async RemoveAdmin() {
		//console.log("Make Administrator");

		if (!(this.state.userViewing.roles.includes("MODERATOR") || this.state.userViewing.roles.includes("ADMIN")))
			return;

		var body = {
			userId: this.state.user.userId,
			role: 'MODERATOR'
		}

		const response = await fetch("/api/v1/user/role",
			{
				method: 'DELETE',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {

			this.RetrieveProfile();
		}
		else {
			console.log(response);
			return;
		}
	}

	render() {

		if (this.state.user == null)
			return <Row>
				<Col xs="3">
					<SidebarProfile user={this.state.userViewing} history={this.props.history} />
				</Col>
				<p>No user found</p>
			</Row>

		var role = null;
		var AdminPanel = null

		if (this.state.user.roles.includes("MODERATOR") || this.state.user.roles.includes("ADMIN")) {
			role = <Row>
				<h6 className="text-secondary ml-3"><i>Administrator</i></h6>
			</Row>
		}

		if (this.state.userViewing.roles.includes("MODERATOR") || this.state.userViewing.roles.includes("ADMIN")) {
			AdminPanel = <Col xs="auto" className="mx-0 px-0" >
				<div className="bg-white pt-3 mt-1 px-3 pb-4">
					<h5>Administrator Panel</h5>
					<Button color="danger" onClick={this.ToggleRemove}>Remove User</Button>
					{(this.state.user.roles.includes("MODERATOR") || this.state.user.roles.includes("ADMIN")) &&
						<Button className="ml-3" color="danger" onClick={this.RemoveAdmin}>Remove Administrator Privileges</Button>
					}
					{!(this.state.user.roles.includes("MODERATOR") || this.state.user.roles.includes("ADMIN")) &&
						<Button className="ml-3" color="success" onClick={this.MakeAdmin}>Grant Administrator Privileges</Button>
					}


					<Modal isOpen={this.state.RemovalOpen} toggle={this.ToggleRemove} backdrop={true}>
						<ModalHeader toggle={this.RemovalOpen}>Remove User</ModalHeader>
						<ModalBody>
							<Row className="mx-1">
								<p>This user will be deactivated in the sytem and will not be able to login.</p>
							</Row>
							<FormGroup tag="fieldset" className="mx-1">
								<p className="mb-0">Do you want their name removed from your posts?</p>
								<FormGroup check className="ml-1">
									<Label check>
										<Input type="radio" name="RemoveName" value={false} checked />{' '}
											No
										</Label>
								</FormGroup>
								<FormGroup check className="ml-1">
									<Label check>
										<Input type="radio" name="RemoveName" value={true} />{' '}
											Yes
										</Label>
								</FormGroup>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="danger" onClick={this.ToggleRemove}>Cancel</Button>{' '}
							<Button color="primary" onClick={this.RemoveUser}>Remove User</Button>
						</ModalFooter>
					</Modal>
				</div>
			</Col>
		}
		return (
			<Row>
				{!this.state.mobile &&
				<Col xs="3">
					<SidebarProfile user={this.state.userViewing} history={this.props.history} />
				</Col>
				}
				<Col xs={this.state.mobile ? "12" : "8"}>
					<PublicProfileContent user={this.state.user} xs="auto" history={this.props.history} />
					{AdminPanel}

				</Col>
			</Row>
		);
	}

}