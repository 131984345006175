import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
//import triquetra from './images/Triquetra.png';
import triquetra from '../_images/Triquetra.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import myData from './BadgeData.json'

library.add(fas)

export class Badge extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			tooltipOpen: false
		};
		//console.log(myData);
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	render() {

		const icon = this.props.icon;
		const id = this.props.id;
		//console.log("===========" + myData.length);

		if (icon === "GSI") {
			return (
				<div style={{ display: 'inline-block' }}>
					<span href="#" id={"_" + id + "-GSITooltip"} className="my-auto">
						<img src={triquetra} alt="" height="16" width="16" className="mb-2 mt-0" />
					</span>
					<Tooltip placement="top" isOpen={this.state.tooltipOpen} target={"_" + id + "-GSITooltip"} delay={0} toggle={this.toggle}>
						GSI
				</Tooltip>
				</div>);

		}

		for (var i = 0; i < myData.length; i++) {
			//console.log(myData[i].name + " === " + icon);
			if (myData[i].name === icon) {
				var data = myData[i];
				var tempId = "_" + id + "-" + data.name + "Tooltip";
				return (
					<div style={{ display: 'inline-block' }}>
						<span href="#" id={tempId}>
							<FontAwesomeIcon icon={data.icon} color={data.color} className="m-1 mt-0" />
						</span>
						<Tooltip placement="top" isOpen={this.state.tooltipOpen} target={tempId} delay={0} toggle={this.toggle}>
							{data.name}
						</Tooltip>
					</div>
				);
				//return (
				//	<div style={{ display: 'inline-block' }}>
				//		<span href="#" class="UncontrolledTooltipExample">tooltip</span>
				//		<UncontrolledTooltip placement="right" target=".UncontrolledTooltipExample">
				//			Hello world!
				//		</UncontrolledTooltip>
				//	</div>
				//);
			}
		}
		return <p>Invalid badge Name: {icon}</p>

	}
};