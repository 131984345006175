import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import Sidebar from "react-sidebar";
import { SidebarProfile } from "../_components";
import { ContentPageView } from '../ContentPage/ContentPageView';


export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        let size = sessionStorage.getItem("size");
        this.state = {
            med: size == "med",
            mobile: size == "mobile",
            sidebarOpen: false
        };

        window.addEventListener("resize", () => {
            setTimeout(() => {
                let size = sessionStorage.getItem("size");

                this.setState({
                    med: size == "med",
                    mobile: size == "mobile"
                });
            }, 100);
        });

        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    updateLogOutCallback = (loggedIn, user) => {
        this.props.updateLoginCallback(loggedIn, user)
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    render() {
        var content = <div>
            <NavMenu
                updateLogOutCallback={this.updateLogOutCallback}
                history={this.props.history}
                mobile={this.props.mobile} med={this.props.med}
                menuCallback={this.onSetSidebarOpen}
                meunOpen={this.state.sidebarOpen}
            />
                <div className={"container-fluid " + (this.state.mobile ? "px-2" : (this.state.med ? "px-3" : "px-5"))} style={{ paddingTop: 60 + 'px' }}>
                    {this.props.children}
                </div>
                <p className="text-secondary" style={{ position: "fixed", left: "20px", bottom: "0px" }}>
                    &#169;{new Date().getFullYear()} Granville Software Inc.
                    </p>
        </div>

        return (
            <Sidebar
                sidebar={
                    <div style={{ width: "300px" }}>
                        <SidebarProfile
                            updateLogOutCallback={this.updateLogOutCallback}
                            user={this.state.user}
                            history={this.props.history}
                            mobile={this.props.mobile}
                            menuCallback={this.onSetSidebarOpen}
                        />
                        <ContentPageView page='About' />
                        <ContentPageView page='Rules' />
                    </div>
                }
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                pullRight={true}
                contentId='BodyContentId'
                styles={{ sidebar: { background: "white", top: "54px" }, content: { overflowY: "scroll" } }}
            >
                {content}
            </Sidebar>
        );
    }
}
