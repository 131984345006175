import React, { Component, } from 'react';
import { toSvg } from "jdenticon"; // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom';
import { Row, Col, ListGroup, ListGroupItem, NavLink } from 'reactstrap';
//import { Badge } from './Badge';
import { GetUser } from "../_helpers"
import { NotificationCenter } from './NotificationCenter.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export class SidebarProfile extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		let size = sessionStorage.getItem("size");
		this.state = {
			tooltipOpen: false,
			user: null,
			med: size == "med",
			mobile: size == "mobile"
		};

		this.LogoClick = this.LogoClick.bind(this);
		this.SubmitSearch = this.SubmitSearch.bind(this);
		this.LogOut = this.LogOut.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}
	componentDidMount() {
		this.setState({
			user: GetUser()
		});
	}

	LogoClick() {
		//console.log(this.props.history);

		if (this.props.history.location.pathname === '/') {
			window.location.reload();
			window.scrollTo(0, 0);
		}
		else {
			this.props.history.push("/");
		}
	}

	SubmitSearch() {
		this.props.history.push("/search/");
	}

	async LogOut() {
		//alert("Logged out fired");
		//Delete Cookie
		document.cookie = 'currentUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';

		//Remove user account
		let user = {}

		this.props.updateLogOutCallback(user, false);
	}

	render() {
		//console.log(this.props);
		const user = this.state.user;

		if (user === null)
			return <p></p>
		//console.log(user);
		//const image = toSvg(user.fullName, 120);
		//console.log(image);

		var Admin = null;
		var role = null;

		if (user.roles.includes("MODERATOR") || user.roles.includes("ADMIN")) {
			Admin = <ListGroupItem tag={Link} to="/Administration" action>Administration</ListGroupItem>
			role = <Row>
						<h6 className="text-secondary"><i>Administrator</i></h6>
					</Row>
		}

		var notification = null;

		//console.log(user);
		if (user !== null && (user.roles.includes("MODERATOR") || user.roles.includes("ADMIN"))) {
			notification = <NotificationCenter user={this.state.user} history={this.props.history} mobile={true} />
		}

		//<svg data-jdenticon-value={user.fullName} width="120" height="120" className="bg-white">
		//	Fallback text or image for browsers not supporting inline svg.
		//</svg>
		// pt-2 mb-2 pb-4
		return (
			<Row className="bg-white pt-3 mx-0 mb-4" onClick={() => this.props.menuCallback?.(false)} >
				<Col>
					<Row className="mb-4">
						<Col xs="auto">
							<svg id={user.id} data-jdenticon-value={user.firstName + " " + user.lastName} width="80" height="80">
								Fallback text or image for browsers not supporting inline svg.
							</svg>
						</Col>
						<Col>
							<Row>
								<h3 className="mb-0">{user.firstName} {user.lastName}</h3>
							</Row>
							<Row>
								<h6 className="text-secondary"><i>{user.firm}</i></h6>
							</Row>
							{role}
						</Col>
					</Row>
					<Row>
						<ListGroup flush className="w-100">
							<ListGroupItem onClick={this.LogoClick} style={{ cursor: 'pointer' }} >Home</ListGroupItem>
							<ListGroupItem tag={Link} to="/Profile" action>Edit Profile</ListGroupItem>
							<ListGroupItem tag={Link} to="/Members" action>Members</ListGroupItem>
							<ListGroupItem tag={Link} to="/Events" action>Events</ListGroupItem>
							{Admin}
						</ListGroup>
					</Row>
					{this.state.mobile &&
						<Row>
							<Col xs="auto" className="ml-auto mt-2">
								{notification}
							</Col>
							<Col xs="auto" className="mt-3 ml-4">
								<FontAwesomeIcon icon="search" color="Black" className="mt-0" style={{
									position: "relative",
									display: "block",
									fontSize: "130%"
								}} onClick={this.SubmitSearch} />
							</Col>
							<Col xs="auto" className="mr-auto">
								<NavLink onClick={this.LogOut} style={{ cursor: 'pointer', fontSize: '1.2rem', width: 'min-content', marginTop: '0.125rem' }} className="" >Logout</NavLink>
							</Col>
						</Row>
					}
				</Col>
			</Row>
		);
	}
};
/*
 <NavLink className="py-0 gsi-blue" tag={Link} to="/">Home</NavLink>
				<NavLink className="py-0 gsi-blue" tag={Link} to="/Profile">Edit Profile</NavLink>
				<NavLink className="py-0 gsi-blue" tag={Link} to="/Events">Events</NavLink>
				<NavLink className="py-0 gsi-blue" tag={Link} to="/Members">Members</NavLink>
				<NavLink className="py-0 gsi-blue" tag={Link} to="/Administration">Administration</NavLink>
{user.badges === null &&
							{user.badges.map((badge, key) =>
								<Badge icon={badge} id={"Profile"} key={key} />
							)}
						}
 */

/*
<Badge icon="Corporate" />
<Badge icon="Estate" />
<Badge icon="Admin" />
<Badge icon="Legacy" />
<Badge icon="Ideas" />
<Badge icon="Tester" />
<Badge icon="Referal" />
<Badge icon="Trainer" />
<Badge icon="Consultant" />
<Badge icon="Mentor" />
 */