import React, { Component } from 'react';
import { UserLink } from "../_components";
import {
	Col, Row, Label, Input, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter,
	FormGroup, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownItem,
	DropdownMenu
} from 'reactstrap';
import $ from "jquery";
import { GetBearerToken, GetUser, FormateDate, FullDate } from "../_helpers"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export class ReportedPosts extends Component {
	static displayName = ReportedPosts.name;

	constructor(props) {
		super(props);

		this.state = {
			message: "",
			posts: [],
			allPosts: [],
			filter: "All",
			user: null
		};

		this.ReviewPost = this.ReviewPost.bind(this);
		this.FilterList = this.FilterList.bind(this);
	}

	async componentDidMount() {
		//Loaded

		this.setState({
			user: GetUser()
		})

		//Retrive Invite data
		this.GetPosts();
	}

	async GetPosts() {
		const response = await fetch(`api/v1/Report`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const data = await response.json();

			this.setState({
				allPosts: data,
				posts: data
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Get Reported Posts failed."
			})
			return;
		}
	}

	FilterList(selection) {
		var all = this.state.allPosts;
		var out = [];
		console.log(selection)

		if (selection == "All") {
			out = all;
		}
		else if (selection == "Needs Review") {
			all.forEach(post => {
				if (post.userReviewed == null)
					out.push(post);
			});
		}
		else if (selection == "Reviewed") {
			all.forEach(post => {
				if (post.userReviewed != null)
					out.push(post);
			});
		}
		console.log(out);
		this.setState({
			posts: out,
			filter: selection
		});
	}

	async ReviewPost(reportId) {
		const response = await fetch(`api/v1/Report/${reportId}`,
			{
				method: 'options',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const data = await response.json();

			this.setState({
				allPosts: data,
				posts: data
			});
		}
		else {
			console.log(response);
			this.setState({
				message: "Review Reported Post failed."
			})
			return;
		}
	}

	render() {
		
		return (
			<Row>
				<Col>
					<Row>
						<Col>
							<h2>Reported Posts</h2>
						</Col>
						<Col xs="auto">
							<span>{this.state.filter}</span>
							<UncontrolledDropdown style={{ display: "inline", marginLeft: "10px" }}>
								<DropdownToggle outline size="small">
									<FontAwesomeIcon icon="filter" color="black" />
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => this.FilterList("All")}>All</DropdownItem>
									<DropdownItem onClick={() => this.FilterList("Needs Review")}>Needs Review</DropdownItem>
									<DropdownItem onClick={() => this.FilterList("Reviewed")}>Reviewed</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Col>
					</Row>
					<Row>
						{this.state.message &&
							<div className="alert alert-danger">{this.state.message}</div>
						}
					</Row>
					<Row>
						<Table striped style={{ width: "100%" }}>
							<thead>
								<tr>
									<th style={{ width: "27%" }}>Post</th>
									<th style={{ width: "14%" }}>Author</th>
									<th style={{ width: "14%" }}>Reported By</th>
									<th style={{ width: "27%" }}>Comment</th>
									<th style={{ width: "14%" }}>Reported At</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{this.state.posts.map((post, key) =>
									<tr key={key}>
										<td>
											<a href={"/post/" + post.postId}>{post.postContent.substring(0, 50) + "..."}</a>
										</td>
										<td>
											<UserLink
												id={post.postAuthor.userId}
												name={post.postAuthor.firstName + " " + post.postAuthor.lastName}
												underline
												history={this.props.history} />
										</td>
										<td>
											<UserLink
												id={post.userReported.userId}
												name={post.userReported.firstName + " " + post.userReported.lastName}
												underline
												history={this.props.history} />
										</td>
										<td>{post.comment}</td>
										<td>{FullDate(post.dateTime)}</td>
										<td>
											{post.userReviewed == null &&
												<FontAwesomeIcon icon="flag" color="red" className="ml-2"
												onClick={() => this.ReviewPost(post.reportedId)}
												/>}
											{post.userReviewed != null &&
												<div>
												<FontAwesomeIcon icon="flag" color="lightgray" className="ml-2" id={"Review__" + post.reportedId} />
													<UncontrolledTooltip placement="top" target={"Review__" + post.reportedId}>
														Reviewed by {post.userReviewed.firstName} {post.userReviewed.lastName}
													</UncontrolledTooltip>
												</div>}
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Row>
				</Col>
			</Row>
		);
	}
}

/*
 *  &&
												
 * 
{this.state.tags.map((tag, key) =>
									<tr>
										<td className="py-2">
											{tag.label + " "} {tag.admin_Only && <span>(Admin Only)</span>}
										</td>
										<td className="py-2" xs="auto" style={{ textAlign: 'center' }} >
											<FontAwesomeIcon icon="pen" className="gsi-blue"
												onClick={() => this.LaunchEditModal(tag.tag_Id, tag.label, tag.admin_Only)} />
										</td>
										<td className="py-2" xs="auto" style={{ textAlign: 'center' }} >
											<FontAwesomeIcon icon="trash-alt" color="red" className="mx-auto"
												onClick={() => this.DeleteTag(tag.tag_Id)} />
										</td>
									</tr>
								)}
 */