import React, { Component } from 'react';
//import { Badge } from './Badge';
import {
	Row, Col, DropdownMenu, DropdownToggle, DropdownItem,
	UncontrolledDropdown, Modal, ModalHeader, ModalBody,
	ModalFooter, Button
} from 'reactstrap';
import { GetBearerToken, FormateDate } from "../_helpers"
import { UserLink } from './UserLink';
import Reactions from './Reactions';
import $ from 'jquery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

export class Comment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editCommentOpen: false,
			data: this.props.data
		};

		this.ReplyTo = this.ReplyTo.bind(this);
		this.highlight = this.highlight.bind(this);

		this.EditComment = this.EditComment.bind(this);
		this.SaveComment = this.SaveComment.bind(this);
		this.RemoveComment = this.RemoveComment.bind(this);
	}

	componentDidMount() {
	}

	ReplyTo() {
		const user = this.state.data.user;
		this.props.ReplyCallback(this.state.data.commentNumber, user.firstName + " " + user.lastName);
	}

	highlight() {
		this.props.HighlightCallback(this.state.data.parentCommentNumber);
	}

	EditComment() {
		this.setState({
			editCommentOpen: !this.state.editCommentOpen
		});
	}

	async SaveComment() {
		console.log(this.state.data);
		var content = $("#EditComment__" + this.state.data.postId + "__" + this.state.data.commentNumber).val();

		if (content == "") {
			alert("Comment must contain content.")
			return;
		}

		const response = await fetch(`/api/v1/Comment/${this.state.data.postId}/${this.state.data.commentNumber}`,
			{
				method: 'PUT',
				body: JSON.stringify(content),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			var temp = this.state.data;

			temp.textContent = content;
			temp.edited = true;

			this.setState({
				data: temp
			});

		} else {
			console.log(response);

		}

		this.setState({
			editCommentOpen: false
		});
	}

	async RemoveComment() {

		const response = await fetch(`/api/v1/Comment/${this.state.data.postId}/${this.state.data.commentNumber}`,
			{
				method: 'delete',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (!(await response.ok)) {
			console.log(response);
			return;
		}

		this.setState({
			data: null
		});
	}

	render() {
		//console.log(this.props);

		if (this.state.data === null) {
			return <></>
		}
		const dateTime = this.state.data.postedTime;
		const user = this.state.data.user;
		var body = this.state.data.textContent;
		var reply = null;
		var badges = null;

		//console.log(this.state.data);
		//const image = toSvg(user.fullName, 70).toString();
		//var tempId = user.id + "_" + dateTime;
		//tempId = tempId.replace(/([-:@/\\\\])/g, "_");

		var menuItems = <DropdownMenu>
			<DropdownItem onClick={() => { this.ReplyTo() }}>
				Reply
			</DropdownItem>
		</DropdownMenu>

		if (this.props.userViewing !== null && this.props.userViewing.userId === user.userId) {
			menuItems = <DropdownMenu>
				<DropdownItem onClick={() => { this.ReplyTo() }}>
					Reply
				</DropdownItem>
				<DropdownItem divider />
				<DropdownItem onClick={() => { this.EditComment() }}>
					Edit
				</DropdownItem>
				<DropdownItem onClick={() => {
					if (window.confirm('Are you sure you wish to delete this Comment?')) this.RemoveComment()
				}}>
					Delete
				</DropdownItem>
			</DropdownMenu>
		}

		if (this.state.data.parentCommentNumber !== null)
			reply = "Reply to " + this.state.data.parentCommentName + " : ";
		//<span style={{ textAlign: "right", fontSize: "80%" }} className="text-secondary"><i>#{this.state.data.commentNumber}</i>  {' | '}</span>
		return (
			<Row className={'comment' + this.state.data.commentNumber}>
				<Col>
					<Row className="">
						<Col xs="auto" className="pr-2">
							<svg data-jdenticon-value={user.firstName + " " + user.lastName} width="40" height="40">
								Fallback text or image for browsers not supporting inline svg.
							</svg>
						</Col>
						<Col className="commentText">
							<Row>
								<Col xs="auto" className="pl-4">
									<h5 className="mb-0 pr-0"><UserLink history={this.props.history} id={user.userId} name={user.firstName + " " + user.lastName} /></h5>
								</Col>
								<Col className="ml-0 mt-auto pl-1">
									{badges}
								</Col>
								<Col xs="auto" className="ml-auto mr-0 pl-0">
									<span className="text-secondary pl-0">{FormateDate(dateTime)}</span>
								</Col>
								<Col xs="auto" className="mr-2 pl-0">
									<UncontrolledDropdown className="my-auto p-0">
										<DropdownToggle color="white" className="p-0">
											<FontAwesomeIcon icon="ellipsis-v" color="black" className="mt-auto mb-0 text-secondary" />
										</DropdownToggle>
										{menuItems}
									</UncontrolledDropdown>
								</Col>
							</Row>
							<Row className="pl-4 pr-3" style={{ display: "inline-block" }}>
								<span style={{ fontSize: "80%", marginTop: "3px" }} className="text-secondary replyLink" onClick={this.highlight} >{reply}</span>
								<span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{body}</span>
								{this.state.data.edited &&
									<span className="text-secondary ml-2" style={{
										'fontSize': "90%"
									}}>(Edited)</span>
								}
							</Row>
						</Col>
						
					</Row>
					<Row className="pb-3">
						<Col xs="auto" className="ml-5 mr-auto">
							<Reactions
								data={this.state.data.commentReactions.reactions}
								postId={this.state.data.postId}
								commentNumber={this.state.data.commentNumber}
								use="comment"
								history={this.props.history} />
						</Col>
					</Row>
				</Col>
				<Modal isOpen={this.state.editCommentOpen} toggle={this.EditComment} className="modal-lg" backdrop={true}>
					<ModalHeader toggle={this.EditComment}>Edit Comment</ModalHeader>
					<ModalBody className="pt-0 pb-0">
						<Row className="">
							<textarea className="form-control" size="sm" style={{ display: "Block", minWidth: "100%", resize: "none" }}
								placeholder="Write a comment…"
								id={"EditComment__" + this.state.data.postId + "__" + this.state.data.commentNumber}
								defaultValue={this.state.data.textContent}
								onInput={function (e) {
									//console.log(e.target);
									var temp = $(e.target);
									temp.height("auto");
									temp.height(temp.prop('scrollHeight') + "px");
								}}
							/>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.EditComment}>Cancel</Button>{' '}
						<Button color="primary" onClick={this.SaveComment}>Save</Button>
					</ModalFooter>
				</Modal>
			</Row>
		);
	}
};


/*
							<Row>
								<Col xs="auto" className="ml-auto mr-2">
									<i style={{ textAlign: "right", fontSize: "80%" }} className="text-secondary">Comment: {this.state.data.commentNumber}</i>
								</Col>
							</Row>
{user.badges.map((badge, key) =>
										<Badge icon={badge} id={user.id + "_" + dateTime} key={key} />
									)}
 */