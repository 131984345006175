import React, { Component } from 'react';
import { SidebarProfile } from "../_components";
import { Col, Row, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Route } from 'react-router';
import { GetUser } from "../_helpers"


import { Main } from './Main'
import { Invite } from './Invite'
import { ContentPages } from './ContentPages'
import { Tags } from './Tags'
import { Roles } from './Roles';
import { Requests } from './Requests';
import { ReportedPosts } from './ReportedPosts'

export class Administration extends Component {
	static displayName = Administration.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			page: '',
			user: null,
			med: size == "med",
			mobile: size == "mobile"
		};

		window.onscroll = () => {};

		//this.FunctionName = this.FunctionName.bind(this)

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {
		this.setState({
			user: GetUser()
		});
	}

	render() {

		var body = <Row>
						<h1>You do not have Administrator access.</h1>
					</Row>

		if (this.state.user === null)
			return body;

		var linkClasses = ""
		if (this.state.mobile) {
			linkClasses = "pl-1";
		}

		if (this.state.user.roles.includes("MODERATOR") || this.state.user.roles.includes("ADMIN")) {
			body = <Row className="w-100 mx-auto">
				{!this.state.mobile &&
					<Col xs="3">
						<SidebarProfile user={this.state.user} history={this.props.history} />
					</Col>
				}
				<Col className="bg-white pt-2 mb-4 pb-4" xs={this.state.mobile ? "12" : (this.state.med ? "9" : "8")}>
					<Row>
						<Col xs="2" className="p-0">
							<ListGroup flush>
								<ListGroupItem tag={Link} to="/Administration/" className={linkClasses} > Administration</ListGroupItem>
								<ListGroupItem tag={Link} to="/Administration/invite" className={linkClasses}>Invite</ListGroupItem>
								<ListGroupItem tag={Link} to="/Administration/tags" className={linkClasses}>Tags</ListGroupItem>
								<ListGroupItem tag={Link} to="/Administration/Requests" className={linkClasses}>Account Removal</ListGroupItem>
								<ListGroupItem tag={Link} to="/Administration/Reported" className={linkClasses}>Reported Posts</ListGroupItem>
							</ListGroup>
						</Col>
						<Col className="mx-3">
							<Route exact path='/Administration' component={ContentPages} />
							<Route path='/Administration/invite' component={Invite} />
							<Route path='/Administration/tags' component={Tags} />
							<Route path='/Administration/requests' component={Requests} />
							<Route path='/Administration/reported' component={ReportedPosts} history={this.props.history} />
						</Col>
					</Row>
				</Col>
			</Row>
		}

		return (
			<div>
				{body}
			</div>
		);
	}

}
