import React, { Component } from 'react';



export class UserLink extends Component {

	constructor(props) {
		super(props);

		this.Link = this.Link.bind(this);
	}

	Link() {
		this.props.history.push("/user/" + this.props.id);
	}

	render() {
		//console.log(this.props);

		return <span
			onClick={this.Link}
			className={(this.props.underline ? "underline " : " ") + this.props.className}
			style={{ cursor: 'pointer' }} >{this.props.name}</span>
	}
}