import React, { Component } from 'react';
import { Col, Row, Input, Button } from 'reactstrap';
import { GetBearerToken } from "../_helpers"
import $ from "jquery";


export class Roles extends Component {
	static displayName = Roles.name;

	constructor(props) {
		super(props);

		this.state = {
			users: []
		};


		this.MakeAdmin = this.MakeAdmin.bind(this)

	}

	async componentDidMount() {
		//Loaded

		this.RetriveUserList();
	}

	async RetriveUserList() {
		const response = await fetch("/api/v1/user",
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {
			const users = await response.json();

			var data = [];

			for (var i = 0; i < users.length; i++) {
				if (!users[i].roles.includes("MODERATOR"))
					data.push(users[i]);
			}

			this.setState({
				users: data
			});
		}
		else {
			console.log(response);
			return;
		}
	}

	async MakeAdmin() {
		//console.log("Make Administrator");

		var body = {
			userId: $('#userSelect').val(),
			role: 'MODERATOR'
		}

		const response = await fetch("/api/v1/user/role",
			{
				method: 'POST',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});


		if (await response.ok) {

			this.RetriveUserList();
		}
		else {
			console.log(response);
			return;
		}
	}

	render() {

		return (
			<Row>
				<Col>
					<Row>
						<h2>Roles</h2>
					</Row>
					<Row>
						<Col xs='12' className="my-2">
							<p className="my-2">Select a user to promote them to an Administrator.</p>
							<Input type="select" name="select" id="userSelect" className="my-2">
								{this.state.users.map((user, key) =>
									<option key={key} value={user.userId}>{user.firstName + " " + user.lastName}</option>
									)}
							</Input>

							<Button color="primary" className="my-2"
								onClick={() => { if (window.confirm('Are you sure you wish to make this user an Administrator?')) this.MakeAdmin() }}>
								Make Administrator
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

}
