import React, { Component } from 'react';
import { Post, SidebarProfile } from "../_components";
import { Col, Row } from 'reactstrap';
//import $ from "jquery";
import { GetBearerToken } from "../_helpers"

//import { withRouter } from 'react-router-dom'

export class PostPage extends Component {
	static displayName = PostPage.name;

	constructor(props) {
		super(props);

		let size = sessionStorage.getItem("size");
		this.state = {
			error: false,
			post: null,
			user: null,
			med: size == "med",
			mobile: size == "mobile"
		};

		window.onscroll = () => {

		}

		this.populatePost = this.populatePost.bind(this);

		window.addEventListener("resize", () => {
			setTimeout(() => {
				let size = sessionStorage.getItem("size");

				this.setState({
					med: size == "med",
					mobile: size == "mobile"
				});
			}, 100);
		});
	}

	async componentDidMount() {
		//console.log(this.props.match);
		await this.populatePost();
		if (this.state.post !== null) {
			this.setState({
				user: this.state.post.user
			});
		}
	}

	async componentDidUpdate(prevProps) {
		if (this.props.match.params.postId != prevProps.match.params.postId) {
			//console.log(this.props.match);
			await this.populatePost();
			if (this.state.post !== null) {
				this.setState({
					user: this.state.post.user
				});
			}
		}
	} 

	async populatePost(postDelete = false) {
		if (postDelete) {
			this.props.history.push('/')
			return;
		}
		//console.log(this.props);
		const response = await fetch(`api/v1/post/` + this.props.match.params.postId,
			{
				method: 'get',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});
		//const response = await fetch(`api/v1/post`);
		//console.log(GetBearerToken());
		//alert("here");

		var data = null;
		if (await response.ok) {
			data = await response.json();
		}
		else {
			this.props.history.push('/')
			return;
		}

		//console.log(data);
		this.setState({
			post: data
		});
		//console.log(this.state);
	}

	render() {

		return (
			<div>
				<Col>
					<Row>
						{!this.state.mobile &&
						<Col xs="3">
							{this.state.user != null &&
								<SidebarProfile user={this.state.user} history={this.props.history} />
							}
						</Col>
						}
						<Col className="w-100" xs={this.state.mobile ? "12" : (this.state.med ? "9" : "6")} >
							<Post post={this.state.post} user={this.state.user} updateNewsfeedCallback={this.populatePost} history={this.props.history} allComments={true} />
						</Col>
					</Row>
				</Col>
			</div>
		);
	}

}
