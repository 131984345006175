import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
//import $ from "jquery";
import './ContentPage.css';
import { GetBearerToken } from "../_helpers"

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//import { withRouter } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FormateDate } from '../_helpers';

library.add(fas)

// wysiwyg component
// https://github.com/zenoamaro/react-quill
// A wrapper from
// https://quilljs.com/

export class ContentPage extends Component {
	static displayName = ContentPage.name;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			text: '',
			tempText: '',
			lastEdited: '',
			editMode: false
		};
		//const [modal, setModal] = this.setState(false);


		this.RetrivePage = this.RetrivePage.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.edit = this.edit.bind(this);
		this.save = this.save.bind(this);

	}

	async componentDidMount() {
		const response = await fetch("/api/v1/ContentPage/" + this.props.page.toLowerCase(),
			{
				method: 'get',
				headers: {
					'Authorization': GetBearerToken()
				}
			});

		const data = await response.json();
		this.RetrivePage(data.content);

	}

	modules = {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
			['link'],
			['clean']
		],
	}

	formats = [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link'
	]

	RetrivePage(text) {
		//var page = this.props.page;

		//Retrive page from the database

		this.setState({
			loading: false,
			text: text,
			lastEdited: '2020-02-27T10:15',
			editMode: false
		});

		console.log(this.state);
	}

	edit() {
		this.setState({
			editMode: true,
			tempText: this.state.text
		});
	}

	async save() {
		this.setState({
			editMode: false
		});

		// Write data to the database

		var body = {
			PageType: this.props.page.toUpperCase(),
			Content: this.state.tempText
		}

		const response = await fetch("/api/v1/ContentPage",
			{
				method: 'put',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': GetBearerToken()
				}
			});

		const data = await response.json();
		this.RetrivePage(data.content);

		this.RetrivePage(this.state.tempText);
	}

	handleChange(value) {
		this.setState({
			tempText: value
		});
	}



	render() {
		var body = <div className="rawHtml" dangerouslySetInnerHTML={{ __html: this.state.text }} />
		var modeToggle = <FontAwesomeIcon icon="pen" className="ml-2 text-muted" onClick={this.edit} />

		if (this.state.editMode) {
			body = <ReactQuill value={this.state.tempText}
				onChange={this.handleChange}
				modules={this.modules}
				formats={this.formats} />
			modeToggle = <FontAwesomeIcon icon="save" className="ml-2 text-muted" onClick={this.save} />
		}
		//<span className="text-muted">Last Edited {FormateDate(this.state.lastEdited)}</span>
		return (
			<div>
				<Col className="bg-white py-4 mb-2">
					<Row>
						<Col>
							<h3>{this.props.page}</h3>
						</Col>
						<Col xs="auto" className="mr-0 mb-0 ">
							
							{modeToggle}
						</Col>
					</Row>
					<hr className="mt-0" />
					{body}
				</Col>
			</div>
		);
	}

}
